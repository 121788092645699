import { Component, Output, EventEmitter, NgZone, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { getFederatedApps } from "app/reducers";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { CommonUtil } from "app/utils/common.util";

@Component({
  selector: "vp-apps-dialog",
  templateUrl: "./apps-dialog.html"
})
export class AppsDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  apps = [];
  constructor(
    public dialogRef: MatDialogRef<AppsDialogComponent>,
    private store: Store<MCBRootState>
  ) {
    this.store.select(getFederatedApps).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(apps => {
      console.log("[getFederatedApps]", apps);
      this.apps = [];
      for (const app of apps) {
        this.apps.push({
          title: app.name.replace("vnc", "VNC"),
          url: app.url,
          icon: CommonUtil.getIcon(app.name)
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  openApp(app) {
    window.open(app.url, "_blank");
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}

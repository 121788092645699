import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { Store } from "@ngrx/store";
import { SetAvailableApps, UpdateNetworkInformation } from "./actions/app";
import { getOrganizationId, RootState, getUserProfile } from "./reducers";
import { filter, takeUntil, merge, mapTo } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { MCBRepository } from "./repositories/mcb.repository";
import { XmppService } from "./shared/services/xmpp.service";
import { fromEvent } from "rxjs";
import { ConfigService } from "./config.service";

@Component({
  selector: "vp-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  title = "vnc-mcb";
  currentUser: any;
  currentUrl: string;
  hideHeader: boolean;
  constructor(
    private router: Router,
    private store: Store<RootState>,
    private mcbRepo: MCBRepository,
    private xmppService: XmppService,
    private matIconRegistry: MatIconRegistry,
    private configService: ConfigService) {
    this.matIconRegistry.registerFontClassAlias("mdi");
  }

  ngOnInit(): void {
    this.store.select(getUserProfile).pipe(filter(v => !!v)).subscribe(profile => {
      this.currentUser = profile.user;
      console.log("getUserProfile", profile);
      this.mcbRepo.initData();
      this.xmppService.init();
    });
    this.configService.getConfig()
      .subscribe((data:any) => {
        this.configService.set("vncDirectoryPublicUrl", data.vncDirectoryPublicUrl);
      });
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
      this.currentUrl = this.router.routerState.snapshot.url;
      this.hideHeader = this.currentUrl.startsWith("/detail/");
    });
    if (localStorage.getItem("federatedApps") !== null) {
      const federatedApps = JSON.parse(localStorage.getItem("federatedApps"));
      this.store.dispatch(new SetAvailableApps(federatedApps));
    }
    this.store.select(getOrganizationId).filter(v => !!v).subscribe(orgId => {
      this.mcbRepo.initMCBList();
    });
  }
}

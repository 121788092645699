import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { map, tap, filter } from "rxjs/operators";
import { User } from "app/meta-conference-board/models/user.model";
import { Observable, combineLatest } from "rxjs";
import { UserConfigService } from "./userConfig.service";
import { MetaConferenceBoard } from "app/meta-conference-board/models/mcb.model";
import { RootState, getOrganizationId } from "app/reducers";
import { Store } from "@ngrx/store";
import { ConfigService } from "app/config.service";

const DEFAULT_MCB_NAME = "Uncategorised";
@Injectable()
export class MCBService {
  organizationId: any;
  constructor(
    private store: Store<RootState>,
    private middlewareService: MiddlewareService,
    private configService: ConfigService,) {
    console.log("[MCBService] constructor");
    this.store.select(getOrganizationId).filter(v => !!v).subscribe(orgId => {
      this.organizationId = orgId;
    });
  }

  getMCBList() {
    return this.middlewareService.get(`/api/org/${this.organizationId}/mcbs?limit=100`, true).pipe(map((res: any) => {
      if (!!res) {
        return (res.meta_conference_boards as MetaConferenceBoard[]).map(v => {
          v.totalLive = 0;
          v.totalScheduled = v.conferences.filter(c => c.status === "planned").length;
          v.totalEnded = v.conferences.filter(c => c.status === "closed").length;
          v.totalArchived = v.conferences.filter(c => c.status === "archived").length;
          return v;
        });
      }
      return [];
    }));
  }

  searchMCBs(keyword) {
    const nameOrDesc = this.middlewareService.get(`/api/org/${this.organizationId}/mcbs?f[]=name_or_desc&op[name_or_desc]=~&v[name_or_desc][]=${keyword}&f[]=&limit=100`, true);
    return nameOrDesc.pipe(map((res: any) => {
      if (!!res) {
        const mcbs = (res.meta_conference_boards as MetaConferenceBoard[]).map(v => {
          v.totalLive = 0;
          v.totalScheduled = v.conferences.filter(c => c.status === "planned").length;
          v.totalEnded = v.conferences.filter(c => c.status === "closed").length;
          v.totalArchived = v.conferences.filter(c => c.status === "archived").length;
          return v;
        });
        return mcbs;
      }
      return [];
    }));
  }

  createMCB(data) {
    const body = {
      "meta_conference_board": {
        "name": data.name || DEFAULT_MCB_NAME,
        "description": data.description
      }
    };
    return this.middlewareService.post(`/api/org/${this.organizationId}/mcbs`, true, body);
  }


  deleteMCB(id: any) {
    return this.middlewareService.delete(`/api/org/${this.organizationId}/mcbs/${id}`, true);
  }

  getMCB(id: any) {
    return this.middlewareService.get(`/api/org/${this.organizationId}/mcbs/${id}`, true).pipe(map((res: any) => {
      return res && res.meta_conference_board ? res.meta_conference_board : null;
    }));
  }

  updateMCB(data) {
    const body = {
      "meta_conference_board": {
        "name": data.name,
        "description": data.description
      }
    };
    return this.middlewareService.put(`/api/org/${this.organizationId}/mcbs/${data.id}`, true, body);
  }

  addGroupChats(mcbId, jids) {
    const body = {
      jids
    };
    return this.middlewareService.post(`/api/org/${this.organizationId}/mcbs/${mcbId}/add_group_chats`, true, body)
    .pipe(map((v: any) => v.meta_conference_board));
  }

  removeGroupChats(mcbId, jids) {
    const body = {
      jids
    };
    return this.middlewareService.post(`/api/org/${this.organizationId}/mcbs/${mcbId}/remove_group_chats`, true, body)
    .pipe(map((v: any) => v.meta_conference_board));
  }

  searchGroups(searchString: string, memberName?: string, members?: string[]): Observable<any> {
    const params: any = {
      searchstring: searchString
    };

    if (memberName) {
      params["membername"] = memberName;
    }

    if (members) {
      params["members"] = members;
    }

    return this.middlewareService.post<any>("/api/searchgroup", true, params);
  }

  deleteJitsiRoom(confKey: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    return this.middlewareService.delete("/api/confMap/" + encodeURIComponent(confKey), true);
  }

  getJitsiRoom(confKey: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    return this.middlewareService.get("/api/confMap/" + encodeURIComponent(confKey), true);
  }

  getTags(params?: any): Observable<any> {
    const url = "/api/tags";
    return this.middlewareService.get(url, true, params);
  }

  getMyProducts(): Observable<any> {
    const url = "/api/my-products";
    return this.middlewareService.get(url, true);
  }

  getTagsByJid(jid): Observable<any> {
    let url = "/api/taggings";
    const params = {
      product: "vnctalk",
      object_type: "meeting",
      object_ids: jid
    };
    const query = Object.keys(params).map(key => {
      return `${key}=${params[key]}`;
    });
    url += "?" + query.join("&");
    console.log("[getTagsByJid]", params);
    return this.middlewareService.get(url, true);
  }

  createOrUpdateTag(jid, tags) {
    const objects = {"0": {id: jid, tags_list: tags.join(",")}};
    const body = {
      taggings: {
        product: "vnctalk",
        object_type: "meeting",
        objects: objects
      }
    };
    return this.middlewareService.post(`/api/taggings`, true, body);
  }

  scheduleMeeting(attributes, attendees) {
    const body = {"meeting": {attributes, attendees }};
    return this.middlewareService.post(`/api/meetings/schedule_meeting`, true, body);
  }

  public sendEmail(body: any) {
    return this.middlewareService.post("/api/sendmail", true, body);
  }

  getActiveConferences(): Observable<any> {
    return this.middlewareService.get(`/api/getActiveConferences`, true);
  }

  getRunningConferences(): Observable<any> {
    return this.middlewareService.get(`/api/conference-signal`, true).pipe(map((v: any) => {
      return v.state;
  }));
  }

  getConferencesByRoomIds(roomids) {
    const body = {roomids};
    return this.middlewareService.post(`/api/get-conferences-by-roomids`, true, body);
  }

  getGroups(offset = 0, limit = 100): Observable<any> {
    let updated_at = 0;
    if (!!localStorage.getItem("lastUpdated")) {
      updated_at = Math.ceil
      (parseInt(localStorage.getItem("lastUpdated")) / 1000);
    }
    return this.middlewareService.get(`/api/getGroupChats?offset=${offset}&limit=${limit}&updated_at=${updated_at}`, true);
  }

  getSingleChats(offset = 0, limit = 100): Observable<any> {
    let updated_at = 0;
    if (!!localStorage.getItem("lastSingleUpdated")) {
      updated_at = Math.ceil
      (parseInt(localStorage.getItem("lastSingleUpdated")) / 1000);
    }
    return this.middlewareService.get(`/api/getSingleChats?offset=${offset}&limit=${limit}&updated_at=${updated_at}`, true);
  }

  createJitsiRoom(confKey: string, jitsiRoom: string, jitsiURLparam?: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    let jitsiURL: string = jitsiURLparam || "";
    const lastIndex = jitsiURL.lastIndexOf("/");
    if (lastIndex > 0) {
      jitsiURL = jitsiURL.slice(0, lastIndex + 1);
    }
    const data = {
      value: jitsiRoom,
      jitsiurl: jitsiURL + jitsiRoom
    };
    return this.middlewareService.post("/api/confMap/" + encodeURIComponent(confKey), true, data);
  }

  checkActiveConference(roomId: string): Observable<any> {
    return this.middlewareService.get("/api/confactive/" + encodeURIComponent(roomId), false);
  }

  public getSavedSearch(params: any): Observable<any> {
    return this.middlewareService.get("/api/queries", true);
  }

  public searchAutoComplete(body: any): Observable<any> {
    if (this.configService.get("useOldSearch")) {
      return this.middlewareService.post("/api/v2/autocomplete-talk", true, body);
    } else {
      return this.middlewareService.post("/api/v4/autocomplete", true, body);
    }
  }

  public searchContacts(body: any): Observable<any> {
    return this.middlewareService.post("/api/v2/search-contacts", true, body);
  }

  createSearchQuery(body: any) {
    return this.middlewareService.post(`/api/queries`, true, body);
  }
}

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { map, tap } from "rxjs/operators";
import { User } from "app/meta-conference-board/models/user.model";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { SetOrganizationId } from "app/actions/app";

@Injectable()
export class UserConfigService {
  user: User;
  constructor(private middlewareService: MiddlewareService, private store: Store<MCBRootState>) {
    console.log("[UserConfigService]");
  }

  getUserConfig(): Observable<User> {
    return this.middlewareService.get(`/api/user/config`, true)
    .pipe(map((res: any) => {
      if (!res) {
        return null;
      }
      const user: any = res.user;
      const booleanMMapping = ["can_invite_users", "can_manage_mcbs",
      "can_upgrade_to_org", "has_invitation_rights", "rfc_enabled", "tfa_enabled", "video_bridge"];
      for (const key of booleanMMapping) {
        user[key] = user[key] === "true";
      }
      const config = user as User;
      console.log("[SetOrganizationId]", config.organization.id);
      this.store.dispatch(new SetOrganizationId(config.organization.id));
      return config;
    }), tap(v => this.user = v));
  }

  sendInvitationUsers(email: string, departmentId: number, organizationId: number) {
    const data = { "user_invitation": { "email": email, "department_id": departmentId } };
    return this.middlewareService.post(`/api/invitations/send/` + organizationId, true, data);
  }

  getInvitationConfig() {
    return this.middlewareService.get(`/api/invitations/config`, true);
  }
}

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import videojs from "video.js";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { timer } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "vp-vjs-player",
    template: `
    <video #target [style.visibility]="showPlayer && !showError && !livestreamEnded ? 'visible' : 'hidden'"
    class="video-js" autoplay="true" controls playsinline preload="true">
    </video>
    <div class="video-error" *ngIf="showError && !livestreamEnded">
    {{ 'THE_MEDIA_COULD_NOT_BE_LOADED' | translate }}
  </div>
  `,
    encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
    @ViewChild("target", { static: true }) target: ElementRef;
    @Input() options: any;
    @Input() src: string;
    player: videojs.Player;
    showError: boolean;
    livestreamEnded: boolean;
    showPlayer: boolean;
    retries = 0;
    playlist = "";
    playListTimer: any;
    constructor(
        private elementRef: ElementRef,
        private http: HttpClient
    ) { }

    ngOnInit() {
        if (this.src) {
            // instantiate Video.js
            this.checkPlaylist();
        }
    }

    checkPlaylist() {
        this.playListTimer = timer(0, 2000).pipe(switchMap(v => this.http.get(this.src + `?v=` + new Date().getTime(),
        {responseType: "text"})))
        .subscribe(res => {
            console.log("VjsPlayerComponent[checkPlaylist]", this.src);
            const beforeUpdate = this.playlist;
            if (this.playlist !== res) {
                this.playlist = res;
                if (beforeUpdate !== "") {
                    this.showPlayer = true;
                    this.livestreamEnded = false;
                    this.playListTimer.unsubscribe();
                    setTimeout(() => {
                        this.startPlayer();
                    }, 1000);
                }
            }
        });
    }

    ngOnDestroy() {
        // destroy player
        if (this.player) {
            this.player.dispose();
        }

        if (this.playListTimer) {
            this.playListTimer.unsubscribe();
        }
    }

    startPlayer() {
        videojs.log.level("off");
        this.player = videojs(this.target.nativeElement, this.options, () => {
            console.log("[VjsPlayerComponent] onPlayerReady", this.player.error());
            this.player.play();
            this.player.on("ended", () => {
                console.log("[VjsPlayerComponent] ended");
                this.livestreamEnded = true;
                this.showError = false;
                this.showPlayer = false;
                this.checkPlaylist();
            });
            this.player.on("error", () => {
                const error = this.player.error();
                console.log("[VjsPlayerComponent] error", error);
                this.showError = true;
                if (error.code === 4) {
                    setTimeout(() => {
                        this.player.src({
                            src: this.src
                        });
                        this.showError = false;
                        this.player.load();
                        console.log("[VjsPlayerComponent] load after 10s", this.retries);
                        this.retries++;
                    }, 10000); // reload after 10s
                }
            });
            this.player.on("retryplaylist", (event) => {
                console.log("[VjsPlayerComponent] retryplaylist", event);
            });
        });
    }
}
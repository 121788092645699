import { Component, OnInit, Inject, ChangeDetectorRef, HostListener, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { RootState } from "app/reducers";
import { MCBRepository } from "app/repositories/mcb.repository";
import { Subject } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { takeUntil, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Recipient } from "app/meta-conference-board/models/conversation.model";
import { ContactRepository } from "app/repositories/contact.repository";
import * as _ from "lodash";
import { getRecentParticipants } from "app/meta-conference-board/reducers";
import { CommonUtil } from "app/utils/common.util";

@Component({
  selector: "vp-select-participants",
  templateUrl: "./select-participants.component.html"
})
export class SelectParticipantsComponent implements OnInit, OnDestroy {
  selectedRole = "participant";
  searchControl = new UntypedFormControl("", []);
  private isAlive$ = new Subject<boolean>();
  searchedUsers = [];
  filteredRecipients: any;
  conversations: any = [];
  selectedParticipants = [];
  constructor(
    private matDialogRef: MatDialogRef<SelectParticipantsComponent>,
    private store: Store<RootState>,
    private contactRepo: ContactRepository,
    private mcbRepo: MCBRepository,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectionRef: ChangeDetectorRef) {
    this.selectedParticipants = this.data.selectedParticipants;
  }

  ngOnInit(): void {
    if (!!document.getElementById("searchControl")) {
      document.getElementById("searchControl").focus();
    }
    this.store.select(getRecentParticipants).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.conversations = res;
      this.getFilteredRecipients();
    });
    this.searchControl.valueChanges
      .pipe(takeUntil(this.isAlive$)
        , debounceTime(150)
        , distinctUntilChanged())
      .subscribe((value) => {
        console.log("[contact-list.component] valueChanges", value);
        if (value.length > 2 && this.data.action === "select_participants") {
          this.contactRepo.searchUsers(value).filter(u => !!u).subscribe(users => {
            console.log("[contact-list.component] searchUsers", users);
            this.searchedUsers = users.map(u => {
              const recipient: Recipient = {
                target: u.email, title: u.name, type: "user",
                ldapData: u.ldapData, isExternalUser: this.contactRepo.isExternalUser(u.email)
              };
              if (u.ldapData && !!u.ldapData.company) {
                recipient.companyName = u.ldapData.company[0];
              }
              return recipient;
            });
            this.getFilteredRecipients();
          });
        } else {
          this.searchedUsers = [];
          this.getFilteredRecipients();
        }
      });
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.matDialogRef.close();
  }

  selectRole(role) {
    this.selectedRole = role;
  }

  getTargetTitle(target: string): string {
    return this.contactRepo.getFullName(target);
  }

  addEmail(): void {
    if (this.isValidMail()) {
      const r: Recipient = {
        target: this.searchControl.value,
        title: this.getTargetTitle(this.searchControl.value),
        type: "user",
        body: "",
        isExternalUser: this.contactRepo.isExternalUser(this.searchControl.value)
      };
      if (!_.find(this.selectedParticipants, { target: this.searchControl.value })) {
        this.selectedParticipants.push(r);
        if (r.isExternalUser) {
          CommonUtil.addInvitedEmail(r.target);
        }
      }

      if (!!document.querySelector(".all-participants")) {
        document.querySelector(".all-participants").scrollLeft = 100000;
      }
      this.searchControl.setValue("");
      this.changeDetectionRef.markForCheck();
    }
  }

  isValidMail() {
    return this.searchControl.value && CommonUtil.validateEmail(this.searchControl.value);
  }

  isInvited(target) {
    return CommonUtil.isInvitedEmail(target);
  }

  addParticipant(participant) {
    if (!this.selectedParticipants.find(v => v.target === participant.target)) {
      this.selectedParticipants.push({ ...participant, ...{ role: this.selectedRole } });
      this.getFilteredRecipients();
      this.searchControl.patchValue("");
    }
  }

  removeParticipant(participant) {
    if (this.selectedParticipants.find(v => v.target === participant.target)) {
      this.selectedParticipants = this.selectedParticipants.filter(v => v.target !== participant.target);
      this.getFilteredRecipients();
    }
  }

  select() {
    this.matDialogRef.close(this.selectedParticipants);
  }

  getFilteredRecipients(): void {
    let recipients: Recipient[] = [];
    const conversations: Recipient[] = this.conversations.map(c => {
      const r: Recipient = {
        target: c,
        title: this.getTargetTitle(c),
        type: "user"
      };
      return r;
    });

    if (this.searchControl.value.length > 0) {
      let filteredGroupchat: Recipient[] = [];
      if (this.searchControl.value.trim().length > 2) {
        filteredGroupchat = conversations.filter(r => r.target.toLowerCase().indexOf(this.searchControl.value.trim().toLowerCase()) !== -1
          || r.title.toLowerCase().indexOf(this.searchControl.value.trim().toLowerCase()) !== -1);
        recipients = filteredGroupchat.concat(this.searchedUsers);
      }

    } else {
      recipients = conversations;
    }
    const targets = this.selectedParticipants.map(v => v.target);
    this.filteredRecipients = _.uniqBy(recipients.filter(r => !r.target.startsWith("broadcast-") && !targets.includes(r.target)), "target");
    this.changeDetectionRef.markForCheck();
  }
}

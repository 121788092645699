import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs/Subject";
import { getUserProfile, RootState } from "../../../reducers";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtil } from "app/utils/common.util";

@Component({
  selector: "vp-help-dialog",
  templateUrl: "./help-dialog.html"
})
export class HelpDialogComponent implements OnDestroy, OnInit {
  manualLink;
  FAQLink;
  helpLink;
  private isAlive$ = new Subject<boolean>();
  content = "";

  constructor(
    private dialog: MatDialogRef<HelpDialogComponent>,
    private translate: TranslateService,
    private store: Store<RootState>
  ) {

  }

  ngOnInit() {
    // const defaultLang = CommonUtil.getDefaultLang();
    //   if (defaultLang === "de") {
    //     this.manualLink = "https://www.vnctalk.com/manual.pdf";
    //     this.FAQLink = "https://www.vnctalk.com/faq";
    //     this.helpLink = "https://www.vnctalk.com/help";
    //   }
    //   else {
    //     this.manualLink = "https://www.vnctalk.com/manual.pdf";
    //     this.FAQLink = "https://www.vnctalk.com/faq";
    //     this.helpLink = "https://www.vnctalk.com/help";
    //   }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();

    if (document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
  }

}

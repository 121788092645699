import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { MCBRootState, getCMCBs, getActiveBoard } from "../reducers";
import { Store } from "@ngrx/store";
import { MetaConferenceBoard, Conference } from "../models/mcb.model";
import { range, Subject, timer } from "rxjs";
import { MultipleMCBAdd, SetActiveBoard, MCBDelete } from "../actions/mcb";
import { getUserProfile, getActiveConferences } from "app/reducers";
import { take, filter, takeUntil, switchMap } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MCBDialogComponent } from "app/shared/components/mcb-form-dialog/mcb-form-dialog.component";
import { DeleteMCBDialogComponent } from "app/shared/components/delete-mcb-dialog/delete-mcb-dialog.component";
import { MCBRepository } from "app/repositories/mcb.repository";
import { Broadcaster } from "app/shared/services/broadcaster.service";

const POLLING_INTERVAL = 60000;
@Component({
  selector: "vp-meta-conference-board",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./meta-conference-board.component.html"
})
export class MetaConferenceBoardComponent implements OnInit, OnDestroy {
  domain = "";
  cols = 3;
  private isAlive$ = new Subject<boolean>();
  activeBoard: MetaConferenceBoard;
  conferences: Conference[] = [];
  allConferences: Conference[] = [];
  totalPage = 1;
  currentPage = 1;
  mcbList: MetaConferenceBoard[] = [];
  isLoading: boolean;
  keyword: string;
  constructor(
    private router: Router,
    private store: Store<MCBRootState>,
    private matDialog: MatDialog,
    private mcbRepo: MCBRepository,
    private activatedRoute: ActivatedRoute,
    private broadCaster: Broadcaster,
    private changeDetectionRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.isAlive$)).subscribe(data => {
      console.log("[MetaConferenceBoardComponent]", data);
      if (data.get("keyword")) {
        this.broadCaster.broadcast("keyword", data.get("keyword"));
        const keyword = data.get("keyword").toLowerCase();
        this.keyword = keyword;
        this.mcbList = [];
        this.isLoading = true;
        this.mcbRepo.searchMCBs(keyword).subscribe(v => {
          this.isLoading = false;
          console.log("[MetaConferenceBoardComponent] searchMCBs", v);
          this.mcbList = v.filter(mcb => mcb.name.toLowerCase().includes(keyword) || mcb.description.toLowerCase().includes(keyword))
          .map(mcb => this.conferenceMapping(mcb));
          this.changeDetectionRef.markForCheck();
        }, (err) => { this.isLoading = false; });
        this.changeDetectionRef.markForCheck();
        console.log("[MetaConferenceBoardComponent] search", keyword);
      } else {
        this.keyword = "";
        this.store.select(getCMCBs).pipe(takeUntil(this.isAlive$)).subscribe(res => {
          console.log("[getCMCBss]", res);
          this.mcbList = res.map(mcb => this.conferenceMapping(mcb));
          this.changeDetectionRef.markForCheck();
        });
        this.store.select(getActiveConferences).pipe(takeUntil(this.isAlive$)).subscribe(conferences => {
          this.conferences = conferences;
          this.mcbList = this.mcbList.map(mcb => this.conferenceMapping(mcb));
          this.changeDetectionRef.markForCheck();
        });
        this.startMCBPolling();
      }
    });
  }

  conferenceMapping(mcb) {
    const jids = mcb.conferences ? mcb.conferences.map(c => c.jid) : [];
    return {...mcb, ...{totalLive: this.conferences.filter(c => jids.includes(c.jid)).length}};
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  startMCBPolling() {
    console.log("[startMCBPolling]");
    timer(0, POLLING_INTERVAL).pipe(takeUntil(this.isAlive$), switchMap(v => this.mcbRepo.getMCBList())).subscribe(mcbs => {
      console.log("[getMCBList]", mcbs, this.mcbList);
      const newIds = mcbs.map(v => v.id);
      const idsToDelete = this.mcbList.map(v => v.id).filter(v => !newIds.includes(v));
      idsToDelete.forEach(id => {
        this.store.dispatch(new MCBDelete(id));
      });
    });
  }

  openMCB(mcb) {
    this.store.dispatch(new SetActiveBoard(mcb));
    this.router.navigateByUrl("/detail/" + mcb.id);
  }

  newMCB() {
    const options: any = {
      width: "580px",
      height: "600px",
    };
    this.matDialog.open(MCBDialogComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "new_mcb"
      },
      autoFocus: true
    }, options));
  }

  editMCB(mcb) {
    const options: any = {
      width: "580px",
      height: "600px",
    };
    this.matDialog.open(MCBDialogComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "edit_mcb",
        mcb: mcb
      },
      autoFocus: true
    }, options));
  }

  deleteMCB(mcb) {
    const options: any = {
      width: "400px",
      height: "200px",
    };
    this.matDialog.open(DeleteMCBDialogComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        mcb: mcb
      },
      autoFocus: true
    }, options));
  }

  hideSwitchApp() {
    this.broadCaster.broadcast("hideSwitchApp");
  }
}

import { ActionReducerMap, createSelector } from "@ngrx/store";
import {
  AppState, appReducer, _getSessionId, _getIsConnectedXMPP, _getIsDatabaseReady,
  _getJID, _getBare, _getStatus, _getNotifyOption, _getUserProfile, _getIsLoggedInUserLoading,
  _getIsLoggedInUserLoaded, _getIsLoggedIn, _getLandingPageUrl, _getBaseApiUrl,
  _getFirebaseToken,
  _getIsAppBootstrapped, _getIsAppOnline, _getIsWindowFocused, _getAppSettings,
  _getNetworkInformation, _getUserConfig, _getInvitationConfig, _getFederatedApps,
  _getOrganizationId, _getJitsiURL, _getActiveConferences, _getRunningConferences, _getIsSidebarExpanded
} from "./app";
import { AppActionTypes } from "app/actions/app";
import {
  contactAdapter, _getIsLoading, _getIsLoaded, _getFailedPhotos,
  _getPhotoForBareId, _getVCardForBareId, _getVCards, _getContactBaresByGroupName,
  _getFailedPhotoForBareId, _getLastPhotoUpdate, _getStatusForBareId, _getOnlineContactsBareId, ContactState, contactReducer
} from "./contact";
import { groupAdapter, GroupState, groupReducer } from "./group";
import { ConversationState, conversationReducer, conversationAdapter } from "./conversation";

export interface RootState {
  app: AppState;
  contact: ContactState;
  group: GroupState;
  conversation: ConversationState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  contact: contactReducer,
  group: groupReducer,
  conversation: conversationReducer
};

export function clearState(reducer) {
  return function(state, action) {
    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
/**
 * Contact Related Reducers
 */
export const getContactState = state => state.contact;
export const {
  selectIds: getContactIds,
  selectEntities: getContactEntities,
  selectAll: getContacts,
  selectTotal: getTotalContact, // returns total count of contact
} = contactAdapter.getSelectors(getContactState);

export const getTotalContacts = getTotalContact;
export const getIsContactLoading = createSelector(getContactState, _getIsLoading);
export const getIsContactLoaded = createSelector(getContactState, _getIsLoaded);

export const getContactFailedPhotos = createSelector(getContactState, _getFailedPhotos);

export const getContactById = (state: RootState, bareId: string) => {
  return getContactEntities(state)[bareId];
};

export const getContactsByIds = (state: RootState, ids: string[]) => {
  return ids.map(id => getContactEntities(state)[id]).filter(v => !!v);
};

export const getContactPhotoById = (state: RootState, bareId: string) => {
  return _getPhotoForBareId(getContactState(state), bareId);
};

export const getContactVCardById = (state: RootState, bareId: string) => {
  return _getVCardForBareId(getContactState(state), bareId);
};

export const getVCards = createSelector(getContactState, _getVCards);

// export const getGroups = createSelector(getContactState, _getGroupList);

export const getMemberBaresByGroupName = createSelector(getContactState, _getContactBaresByGroupName);

export const getContactFailedPhotoById = (state: RootState, bareId: string) => {
  return _getFailedPhotoForBareId(getContactState(state), bareId);
};

export const getLastPhotoUpdate = (state: RootState, bareId: string) => {
  return _getLastPhotoUpdate(getContactState(state), bareId);
};

export const getContactStatusById = (state: RootState, bareId: string) => {
  return _getStatusForBareId(getContactState(state), bareId);
};

export const getOnlineContactsBareId = createSelector(getContactState, _getOnlineContactsBareId);

export const getMembersByGroupId = (state: RootState, groupId: number) => {
  const contacts = getContacts(state);

  return contacts.filter(c => {
    return c.groups && c.groups.filter(g => g.id === groupId).length > 0;
  });
};

/**
 * Group Related Reducers
 */
export const getGroupState = state => state.group;
export const {
  selectIds: getGroupIds,
  selectEntities: getGroupEntities,
  selectAll: getGroups,
  selectTotal: getTotalGroup, // returns total count of group
} = groupAdapter.getSelectors(getGroupState);

export const getIsGroupLoading = createSelector(getGroupState, _getIsLoading);
export const getIsGroupLoaded = createSelector(getGroupState, _getIsLoaded);

export const getGroupById = (state: RootState, bareId: string) => {
  return getGroupEntities(state)[bareId];
};

export const getGroupsByIds = (state: RootState, ids: string[]) => {
  return ids.map(id => getGroupEntities(state)[id]).filter(v => !!v);
};

export const getGroupsByName = (state: RootState, name: string) => {
  const groups = getGroups(state);
  return groups.filter(g => g.name.toLowerCase() === name.toLowerCase());
};

export const getGroupNameExists = (state: RootState, name: string) => {
  const groups = getGroups(state);
  return groups.filter(g => g.name.toLowerCase() === name.toLowerCase()).length > 0;
};


export const getConversationState = state => state.conversation;

export const {
  selectIds: getConversationIds,
  selectEntities: getConversationEntities,
  selectAll: getAllConversation,
  selectTotal: getTotalConversation
} = conversationAdapter.getSelectors(getConversationState);

export const getAllConversations = getAllConversation;

/**
 *  App Related Reducers
 */
export const getAppState = state => state.app;

export const getSessionId = createSelector(getAppState, _getSessionId);
export const getIsConnectedXMPP = createSelector(getAppState, _getIsConnectedXMPP);
export const IsDatabaseReady = createSelector(getAppState, _getIsDatabaseReady);
export const getAppSettings = createSelector(getAppState, _getAppSettings);

export const getUserJID = createSelector(getAppState, _getJID);
export const getBare = createSelector(getAppState, _getBare);
export const getUserStatus = createSelector(getAppState, _getStatus);
export const getUserNotifyOption = createSelector(getAppState, _getNotifyOption);

export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getIsLoggedInUserLoaded = createSelector(getAppState, _getIsLoggedInUserLoaded);
export const getIsLoggedIn = createSelector(getAppState, _getIsLoggedIn);

export const getLandingPageUrl = createSelector(getAppState, _getLandingPageUrl);
export const getBaseApiUrl = createSelector(getAppState, _getBaseApiUrl);
export const getFirebaseToken = createSelector(getAppState, _getFirebaseToken);
export const getIsAppBootstrapped = createSelector(getAppState, _getIsAppBootstrapped);
export const getIsAppOnline = createSelector(getAppState, _getIsAppOnline);
export const getIsWindowFocused = createSelector(getAppState, _getIsWindowFocused);
export const getUserConfig = createSelector(getAppState, _getUserConfig);
export const getInvitationConfig = createSelector(getAppState, _getInvitationConfig);
export const getNetworkInformation = createSelector(getAppState, _getNetworkInformation);
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);
export const getOrganizationId = createSelector(getAppState, _getOrganizationId);
export const getJitsiURL = createSelector(getAppState, _getJitsiURL);
export const getActiveConferences = createSelector(getAppState, _getActiveConferences);
export const getRunningConferences = createSelector(getAppState, _getRunningConferences);
export const getIsSidebarExpanded = createSelector(getAppState, _getIsSidebarExpanded);


import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ContactActionTypes } from "../actions/contact";
import { Action } from "../actions";
import { UserStatus } from "../shared/models";
import { AppActionTypes } from "../actions/app";
import * as _ from "lodash";
import { Contact } from "app/shared/models/contact.model";

export interface ContactState extends EntityState<Contact> {
  isLoading: boolean;
  isLoaded: boolean;

  photos: { [bareId: string]: any };
  groups: { [title: string]: string[] };
  groupsList: string[];

  photoLastUpdate: { [bareId: string]: number };
  status: { [bareId: string]: UserStatus };
  failedPhotos: string[];
  vCards: { [bareId: string]: any };
}

export const contactAdapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact.bare,
  sortComparer: null
});

export const initialState: ContactState = contactAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  photos: {},
  groups: {},
  groupsList: [],
  photoLastUpdate: {},
  status: {},
  failedPhotos: [],
  vCards: {}
});

export function contactReducer(state: ContactState = initialState, action: Action): ContactState {
  switch (action.type) {

    case ContactActionTypes.CONTACT_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ContactActionTypes.CONTACT_LOAD_SUCCESS: {
      const contacts = action.payload.map(c => {
        const contact = state.entities[c.bare];
        if (contact) {
          c = { ...c, ...contact };
        }
        return c;
      });
      return contactAdapter.addAll(contacts, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactActionTypes.CONTACT_ADD: {
      const newState = contactAdapter.addOne(action.payload, state);

      return contactAdapter.updateOne({ id: action.payload.bare, changes: action.payload }, newState);
    }


    case ContactActionTypes.CONTACT_STATUS_UPDATE: {
      let localState = { ...state };
      localState = {
        ...localState,
        status: {
          ...localState.status,
          [action.payload.jid]: action.payload.status
        }
      };

      return localState;
    }

    case ContactActionTypes.CONTACT_STATUS_UPDATE_BULK: {
      const data = action.payload;

      let localState = { ...state };

      data.forEach(contact => {
        localState = {
          ...localState,
          status: {
            ...localState.status,
            [contact.jid]: contact.status
          }
        };
      });

      return localState;
    }


    case ContactActionTypes.CONTACT_AVATAR_UPDATE_BULK: {
      const changes = action.payload.map(contact => {
        return { id: contact.bare, changes: contact };
      });

      return contactAdapter.updateMany(changes, state);

    }

    case ContactActionTypes.CONTACT_NICKNAME_UPDATE_BULK: {

      const changes = action.payload.map(contact => {
        return { id: contact.jid.bare, changes: { name: contact.nickname } };
      });

      return contactAdapter.updateMany(changes, state);
    }

    case ContactActionTypes.CONTACT_UPDATE: {
      return contactAdapter.updateOne({ id: action.payload.id, changes: action.payload.changes }, state);
    }

    case ContactActionTypes.CONTACT_BULK_ADD: {
      const newState = contactAdapter.addMany(action.payload, state);

      const changes = action.payload.map(contact => {
        return { id: contact.bare, changes: contact };
      });

      return contactAdapter.updateMany(changes, newState);
    }

    case ContactActionTypes.CONTACT_UPDATE_BULK: {
      const changes = action.payload.map(contact => {
        return { id: contact.bare, changes: contact };
      });
      console.log("[CONTACT_UPDATE_BULK]", changes);
      return contactAdapter.updateMany(changes, state);
    }

    case ContactActionTypes.CONTACT_UPDATE_PHOTO_LAST_UPDATE: {
      const vCards = state.vCards;
      const vCard = state.vCards[action.payload.bare] || {};

      return {
        ...state,
        vCards: { ...vCards, [action.payload.bare]: vCard },
        photoLastUpdate: {
          ...state.photoLastUpdate,
          [action.payload.bare]: action.payload.photoLastUpdate
        }
      };
    }

    case ContactActionTypes.CONTACT_BULK_UPDATE_PHOTO_LAST_UPDATE: {
      const vCards = state.vCards;

      let localState = { ...state };

      Object.keys(action.payload).forEach(bare => {
        const vCard = state.vCards[bare] || {};
        localState = {
          ...localState,
          vCards: { ...vCards, [bare]: vCard },
          photoLastUpdate: {
            ...localState.photoLastUpdate,
            [bare]: action.payload[bare]
          }
        };

      });
      return localState;
    }

    case ContactActionTypes.CONTACT_ADD_VCARD: {
      let vCards = state.vCards[action.payload.jid.bare] || {};
      vCards = { ...vCards, ...action.payload.vCard };
      return {
        ...state,
        vCards: {
          ...state.vCards,
          [action.payload.jid.bare]: vCards
        },
      };
    }

    case ContactActionTypes.CONTACT_ADD_BULK_VCARD: {

      let localState = { ...state };
      const data = action.payload;

      data.forEach(item => {

        localState = {
          ...localState,
          vCards: {
            ...localState.vCards,
            [item.bare]: item.vCard
          },

        };

      });
      return localState;
    }

    case ContactActionTypes.UPDATE_LISTS: {
      return {
        ...state,
        groupsList: action.payload
      };
    }

    case ContactActionTypes.UPDATE_ALL_MEMBERS: {
      const data = action.payload;
      let groups = state.groups;

      data.forEach(item => {
        const members = item.members || [];
        const listName = item.listName;
        const bares = members.map(mem => mem.bare);
        groups = {
          ...groups,
          [listName]: bares
        };
      });

      return {
        ...state,
        groups: { ...groups }
      };
    }

    case ContactActionTypes.CONTACT_ADD_TO_LIST: {

      const members = action.payload.members as Contact[];
      const listName = action.payload.listName;
      const memberBares = members.map(mem => mem.bare);
      const groupList = state.groupsList;

      if (!groupList.includes(listName)) {
        groupList.push(listName);
      }

      const existingBares = state.groups[listName] || [];
      const allBares = _.uniq([...existingBares, ...memberBares]);
      console.log("[CONTACT_ADD_TO_LIST]", allBares);
      return {
        ...state,
        groupsList: [...groupList],
        groups: {
          ...state.groups,
          [listName]: allBares
        }
      };
    }

    case ContactActionTypes.CONTACT_REMOVED_FROM_LIST: {

      const members = action.payload.members as Contact[];
      const listName = action.payload.listName;
      const memberBares = members.map(mem => mem.bare);

      const existingBares = state.groups[listName] || [];
      const newBares = existingBares.filter(bare => !memberBares.includes(bare));
      const groups = state.groups;
      let groupList = state.groupsList;

      if (newBares.length === 0) {
        delete groups[listName];
        groupList = groupList.filter(grp => grp !== listName);
      } else {
        groups[listName] = newBares;
      }
      return {
        ...state,
        groupsList: [...groupList],
        groups: { ...groups }
      };
    }

    case ContactActionTypes.CONTACT_REMOVED_GROUP: {

      const groupId = action.payload.groupId;
      const contact = action.payload.contact;
      contact.groups = contact.groups.filter(g => g.id !== groupId);
      return contactAdapter.updateOne({ id: contact.bare, changes: { groups: contact.groups } }, state);
    }

    case ContactActionTypes.CONTACT_REMOVE_GROUP_FROM_CONTACTS: {

      const groupId = action.payload.groupId;
      const contacts = action.payload.contacts;
      const changes = contacts.map(c => {
        return {
          id: c.bare,
          changes: c.groups.filter(g => g.id !== groupId)
        };
      });
      return contactAdapter.updateMany(changes, state);
    }

    case ContactActionTypes.CONTACT_ADD_GROUP_TO_CONTACTS: {
      const group = action.payload.group;
      const contacts = action.payload.contacts;
      const changes = contacts.map(c => {
        return {
          id: c.bare,
          changes: c.groups.push(group)
        };
      });
      return contactAdapter.updateMany(changes, state);
    }


    case ContactActionTypes.RENAME_LIST: {
      const groups = state.groups;
      const groupList = state.groupsList;
      const oldName = action.payload.oldName;
      const newName = action.payload.newName;
      const memberBares = groups[oldName];
      const index = groupList.findIndex(grp => grp === oldName);
      groupList[index] = newName;
      delete groups[oldName];
      groups[newName] = memberBares;
      return {
        ...state,
        groupsList: [...groupList],
        groups: { ...groups }
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.contactState;
      return savedState ? { ...state, ...savedState } : state;
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoading = (state: ContactState) => state.isLoading;
export const _getIsLoaded = (state: ContactState) => state.isLoaded;

export const _getFailedPhotos = (state: ContactState) => state.failedPhotos;
export const _getPhotoForBareId = (state: ContactState, bareId: string) => {
  if (state.vCards[bareId]) {
    return state.vCards[bareId].photo;
  }
  return null;
};
export const _getVCards = (state: ContactState) => state.vCards;
export const _getVCardForBareId = (state: ContactState, bareId: string) => state.vCards[bareId];
export const _getLastPhotoUpdate = (state: ContactState, bareId: string) => state.photoLastUpdate[bareId];
export const _getFailedPhotoForBareId = (state: ContactState, bareId: string) => state.failedPhotos.indexOf(bareId) !== -1;

export const _getStatusForBareId = (state: ContactState, bareId: string) => {
  return state.status[bareId];
};

export const _getOnlineContactsBareId = (state: ContactState) => {
  const bareIds = [];
  for (const key of Object.keys(state.status)) {
    const userStatus = _getStatusForBareId(state, key);
    if (userStatus === UserStatus.ONLINE) {
      const contact = state.entities[key];
      // we consider only those users online who are in at least one of our group.
      if (contact && contact.groups && contact.groups.length > 0) {
        bareIds.push(key);
      }
    }
  }
  return bareIds;
};

export const _getContactBaresByGroupName = (state: ContactState) => state.groups;
export const _getGroupList = (state: ContactState) => state.groupsList || [];


import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { App, Remote, Shell, BrowserWindow, IpcRenderer, DesktopCapturer } from "electron";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { environment } from "environments/environment";

@Injectable()
export class ElectronService {
  app: App;
  remote: Remote;
  shell: Shell;
  win: BrowserWindow;
  ipcRenderer: IpcRenderer;
  desktopCapturer: DesktopCapturer;
  blueimpMd5: any;
  constructor(private store: Store<MCBRootState>) {
    if (this.isElectron) {
      console.log("[ElectronService] constructor");
      this.remote = window.require("electron").remote;
      this.ipcRenderer = window.require("electron").ipcRenderer;
      this.desktopCapturer = window.require("electron").desktopCapturer;
      this.shell = window.require("electron").shell;
      this.blueimpMd5 = window.require("blueimp-md5");
      this.app = this.remote.app;
      this.win = this.remote.getCurrentWindow();
    }
  }

  get isElectron(): boolean {
    return environment.isElectron;
  }

  get isWindows(): boolean {
    return window && window.process && window.process.platform === "win32";
  }

  get isMacOS(): boolean {
    return window && window.process && window.process.platform === "darwin";
  }

  get appVersion(): string {
    return this.app.getVersion();
  }

  setBadge(count: number): void {
    if (this.isWindows) {
      const badgeNumber = count ? count : null;
      this.ipcRenderer.sendSync("update-badge", badgeNumber);
    } else {
      this.app.setBadgeCount(count);
    }

    if (this.isMacOS && count) {
      this.app.dock.bounce();
    }
  }

  openExternalUrl(url: string): void {
    this.shell.openExternal(url);
  }

  showApp(): void {
    this.win.show();
  }

  md5(str: string): string {
    return this.blueimpMd5(str);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { isNullOrUndefined } from "util";
import { Store } from "@ngrx/store";
import { getBaseApiUrl, getIsLoggedIn } from "../../reducers";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { ConfigService } from "../../config.service";
import { environment } from "environments/environment";
import { Broadcaster, Events } from "./broadcaster.service";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { CommonUtil } from "app/utils/common.util";
import { of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class MiddlewareService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private isCordova = environment.isCordova;
  private isElectron =  environment.isElectron;
  private _baseUrl = "";
  private handleError: () => ErrorObservable<any>;
  private _isLoggedIn: boolean;

  constructor(private http: HttpClient,
              private configService: ConfigService,
              private store: Store<MCBRootState>,
              private broadcaster: Broadcaster) {
    this.store.select(getBaseApiUrl).subscribe(base => {
      if (!!base && base !== "null") {
        this._baseUrl = base;
      }
      console.log("[MiddlewareService] baseApiUrl", this._baseUrl, base);
    });
    this.store.select(getIsLoggedIn).subscribe(isLoggedIn => {
      this._isLoggedIn = isLoggedIn;
    });

    this.handleError = this._handleError.bind(this);
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("token") !== null;
  }

  get<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders,
    useBaseUrl: boolean = true, responseType?: string): Observable<T> {
      const options = {
      params: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };
    if (responseType) {
      options["responseType"] = responseType;
    }


    let baseUrl = this._baseUrl + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }
    return this.http.get<T>(baseUrl, options).pipe(catchError(this.handleError));
  }

  getWithError<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders,
    useBaseUrl: boolean = true, responseType?: string): Observable<T> {
    const options = {
      params: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };
    if (responseType) {
      options["responseType"] = responseType;
    }

    // console.log("[MiddlewareService] GET", url, options);

    let baseUrl = this._baseUrl + url;
    if (!useBaseUrl) {
      baseUrl = url;
    }
    return this.http.get<T>(baseUrl, options);
  }

  post<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders): Observable<T> {
    console.log("[MiddlewareService][post]", url, data, this._baseUrl);
    return this.http.post<T>(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers),
      withCredentials: true
    }).pipe(catchError(this.handleError));
  }

  postWithHeadersInterception(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders): Observable<any> {
    console.log("[MiddlewareService][postWithHeadersInterception]", url, data, this._baseUrl);

    const localTime = new Date().getTime();
    return this.http.post(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers),
      observe: "response",
      withCredentials: true
    }).map(response => {
        // console.log("[MiddlewareService][postWithHeadersInterception] response", response, response.headers, response.body);
      const serverDate = response.headers.get("Date");
      const jsonResponse: any = response.body;
      if (serverDate) {
        const diff = localTime - new Date(serverDate).getTime();
        jsonResponse.serverTimeDiff = diff;
      }
      return jsonResponse;
    });
  }

  put<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders): Observable<T> {
    console.log("[MiddlewareService] PUT", url);
    return this.http.put<T>(this._baseUrl + url, data, {
      headers: this.buildHeaders(useAuthHeaders, headers)
    }).pipe(catchError(this.handleError));
  }

  delete<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders): Observable<T> {
    console.log("[MiddlewareService] DELETE", url);
    const options = {
      params: this.buildParams(data),
      headers: this.buildHeaders(useAuthHeaders, headers)
    };

    return this.http.delete<T>(this._baseUrl + url, options).pipe(catchError(this.handleError));
  }

  private buildHeaders(useAuthHeaders: boolean, headers?: HttpHeaders): HttpHeaders {
    // console.log("[MiddlewareService] buildHeaders", useAuthHeaders, headers, localStorage.getItem("token"));

    if (!useAuthHeaders) {
      return headers;
    }

    let mutatedHeaders: HttpHeaders;

    if (!isNullOrUndefined(headers)) {
      mutatedHeaders = headers;
    } else {
      mutatedHeaders = new HttpHeaders();
    }

    const token = localStorage.getItem("token");
    if (token && this.isCordovaOrElectron) {
      if (environment.theme === "hin" && this.isCordova) {
        return this.getHinHeaders(mutatedHeaders, token);
      }
      if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
          return mutatedHeaders.set("Authorization", token).set("Content-Type", "text/plain").set("Accept", "text/plain");
      }
      return mutatedHeaders.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
    } else {
      if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
        return mutatedHeaders.set("Content-Type", "text/plain").set("Accept", "text/plain");
      }
      return mutatedHeaders.set("Content-Type", "application/json").set("Accept", "application/json");
    }
  }

  private getHinHeaders(mutatedHeaders: HttpHeaders, token: string) {
    const deviceLabel = CommonUtil.getMobileDeviceLabel();
    if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
      return mutatedHeaders.set("Authorization", token).set("Content-Type", "text/plain").set("hintalkdevice", deviceLabel).set("Accept", "text/plain");
    }
    return mutatedHeaders.set("Authorization", token).set("Content-Type", "application/json")
    .set("hintalkdevice", deviceLabel).set("Accept", "application/json");
  }

  private buildParams(data: any): HttpParams {
    let params = new HttpParams();
    if (data) {
      for (const key of data) {
        params = params.append(key, data[key]);
      }
    }
    return params;
  }

  private _handleError(response: Response): ErrorObservable<any> | Observable<any> {
    const error = response["error"] || {};
    const keys = Object.keys(error);
    const key = keys[0];
    let message = error[key];

    console.error("[MiddlewareService] _handleError", response);
    if (response.status === 401 || response.status === 403) {
      console.error("[MiddlewareService] [_handleError] redirectToLoginScreen");
      this.configService.clearStorage();
      this.configService.redirectToLoginScreen();
      return of(null);
    }
    if (error[key] instanceof Array) {
      message = error[key][0];
    }
    if (key === "isTrusted") {
      message = "Please check your internet connection !";
    } else {
      message = key + " : " + message;
    }

    return throwError({messages: message, error: error});
  }
}

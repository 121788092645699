import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Subject, throwError } from "rxjs";
import { environment } from "environments/environment";
import { ProfileResponse } from "../models/profile.response";
import { MiddlewareService } from "./middleware.service";
import { take, map } from "rxjs/operators";
import { ConfigService } from "app/config.service";
import { AppState } from "app/reducers/app";
import { Store } from "@ngrx/store";
import { Logout, FetchMeSuccess, SetAvailableApps } from "app/actions/app";
import { ConstantsUtil } from "app/utils/constants.util";

@Injectable()
export class AuthService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  constructor(private http: HttpClient, private middlewareService: MiddlewareService,
    private configService: ConfigService, private store: Store<AppState>) {
  }

  public getProfile(): Observable<ProfileResponse> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/profile", { headers: headers }).pipe(map(res => {
      if (!!res) {
        const profile = res as ProfileResponse;
        this.configService.conferenceDomain = `conference.${profile.domain}`;
        this.configService.avatarServiceUrl = profile.avatarServiceUrl;
        if (!profile.streamServer) {
          profile.streamServer = ConstantsUtil.DEFAULT_STREAM_SERVER;
        }
        this.configService.streamServer = profile.streamServer;
        this.store.dispatch(new FetchMeSuccess(profile));
        return profile;
      }
      return null;
    }));
  }

  changeServerUrl(serverURL: string): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (serverURL.endsWith("/")) {
      serverURL = serverURL.substring(0, serverURL.lastIndexOf("/")).trim();
    }
    return this.middlewareService.get<any>(serverURL + "/api/config", false, null, headers, false);
  }

  logout() {
    const callLogout = environment.isElectron ? "./api/call-logout" : "/api/call-logout";
    return this.http.get(this.configService.API_URL + callLogout, {});
  }

  handleErrorObservable(error: Response | any) {
    return throwError(error);
  }

  logoutWebAndApp() {
    localStorage.removeItem("pendingMessages");
    this.store.dispatch(new Logout());
    window.location.href = "/api/call-logout";
  }

  getPrivateDocuments() {
  }
}

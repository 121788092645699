import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MCBRootState } from "../reducers";
import { getIsSidebarExpanded } from "app/reducers";
import { SetSidebarIsExpanded } from "app/actions/app";

@Component({
  selector: "vp-sidebar",
  templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() conferences = [];
  @Output() startConference = new EventEmitter();
  isExpanded: any;
  private isAlive$ = new Subject<boolean>();
  constructor(private store: Store<MCBRootState>) { }
  ngOnInit(): void {
    this.store.select(getIsSidebarExpanded).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.isExpanded = v;
    });
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  toggleSidebar() {
    this.store.dispatch(new SetSidebarIsExpanded(!this.isExpanded));
  }

  startConferenceAction() {
    this.startConference.emit();
  }
}

import { EnvironmentTheme } from "./environment.interface";

export const theme: EnvironmentTheme = {
  title: "Meta Conference Board",
  appLogo: "assets/images/logo.png",
  textLogo: "<span class=\"vnc\">VNC</span><span class=\"mcb\">mcb</span>",
  theme: "vnctalk",
  aboutUsLogo: "assets/images/app-icon.png",
  aboutText: "<span class=\"disable-select\">VNC</span><span class=\"product_name disable-select\">mcb</span>",
  mobileTextLogo: "VNC",
  profileBackgroundImage: "/assets/image/profile-background.jpg",
};

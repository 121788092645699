export enum UserStatus {
  ONLINE = 1,
  OFFLINE,
}

export function getAllUserStatusTypes(): UserStatus[] {
  return [
    UserStatus.ONLINE,
    UserStatus.OFFLINE
  ];
}

export function getUserStatusType(type: UserStatus): any {
  let slug = "";
  let title = "";
  let key = "";
  switch (type) {
    case UserStatus.ONLINE: {
      slug = "online";
      title = "Online";
      key = "HEADER_ONLINE";
      break;
    }

    case UserStatus.OFFLINE:
    default : {
      slug = "offline";
      title = "Invisible";
      key = "HEADER_OFFLINE";
      break;
    }
  }

  return {
    slug: slug,
    title: title,
    translation_key: key
  };
}

export enum AppType {
  VNCTALK = 1,
  VNCMAIL,
  VNCCAST,
  VNCCOMMANDER,
  VNCCONTACTS,
  VNCTASK,
}

export function getAllAppTypes(): AppType[] {
  return [
    AppType.VNCTALK,
    AppType.VNCMAIL,
    AppType.VNCCAST,
    AppType.VNCCOMMANDER,
    AppType.VNCCONTACTS,
    AppType.VNCTASK,
  ];
}

export function appTypeFromString(str: string): AppType {
  switch (str) {
    case "vnctalk":
      return AppType.VNCTALK;
    case "vncmail":
      return AppType.VNCMAIL;
    case "vnccast":
      return AppType.VNCCAST;
    case "vnccommander":
      return AppType.VNCCOMMANDER;
    case "vnccontacts":
      return AppType.VNCCONTACTS;
    default:
      return AppType.VNCTASK;
  }
}

export enum TimeRangeType {
  LAST_HOUR = 1,
  LAST_24_HOURS,
  LAST_WEEK,
  LAST_MONTH,
  CUSTOM,
}


/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CommonUtil } from "app/utils/common.util";
import { AppState } from "../app/reducers/app";
import { Store } from "@ngrx/store";
import { getFederatedApps } from "app/reducers";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ElectronService } from "app/shared/providers/electron.service";
import { ConfigService } from "app/config.service";

@Component({
  selector: "vp-app-switcher",
  template: `
    <div class="switch-holder" *ngIf="switchApp">
      <ng-template ngFor let-route [ngForOf]="this.routes">
        <div class="switch-box app_switch--{{route.title}}" [class.active-switch]="route.active"
             (click)="openApp(route)">
          <div class="switch-icon"><img [src]="route.icon"/></div>
          <div class="switch-title">{{route.title}}</div>
        </div>
      </ng-template>
    </div>
  `
})
export class AppSwitcherComponent implements OnChanges, OnDestroy {
  @Input() switchApp: boolean;
  @Input() url: string;
  @Output() closeSwitch = new EventEmitter();
  subscriber: any = null;
  routes: AppRoutes[] = [];
  destroy$ = new Subject();
  constructor(private router: Router,
              private configService: ConfigService,
              private electronService: ElectronService,
              private store: Store<AppState>) {
    this.store.select(getFederatedApps).pipe(takeUntil(this.destroy$)).subscribe(apps => {
      let routes = [];
      apps.forEach(product => {
        routes.push({
          title: product?.options?.title,
          icon: this.configService.get("vncDirectoryPublicUrl") + product?.options?.icon,
          image: this.configService.get("vncDirectoryPublicUrl") + product?.options?.image,
          nativeLink: `${product.name.toLowerCase()}://main`,
          path: product?.options?.url,
          active: true,
          position: product?.options?.position,
          enabled: true
        });
      });
      routes.sort((a, b) => a.position - b.position);
      this.routes = routes;
    });
  }

  openApp(item): void {
    if (!CommonUtil.isOnMobileDevice()) {
      // console.log("APPSWITCH openApp: ", item);
      if (item.title === "VNCtask") {
        // no need to re-open current app!
        this.closeSwitch.emit(false);
        return;
      }

      if ((["VNCproject", "VNCdirectory"].indexOf(item.title) > -1) && (item.path.indexOf("login") === -1)) {
        const newPath = item.path.endsWith("/") ? item.path + "login" : item.path + "/login";
        item.path = newPath;
      }
      if ((["VNCtalk", "VNCmail", "VNCcalendar", "VNCtask"].indexOf(item.title) > -1) && (item.path.indexOf("login") === -1)) {
        const nPath = item.path.endsWith("/") ? item.path + "api/login" : item.path + "/api/login";
        item.path = nPath;
      }
      if (this.electronService.isElectron) {
        try {
          let nativeLink = item.nativeLink;
          let nativeHandler = this.electronService.app.getApplicationNameForProtocol(item.nativeLink);
          if ((item.nativeLink === "vnccalendar://main") && (!nativeHandler)) {
            nativeHandler = this.electronService.app.getApplicationNameForProtocol("vncmail://");
            if (nativeHandler) {
              nativeLink = "vncmail://main/calendar";
            }
          }
          if (nativeHandler && nativeHandler !== "") {
            this.electronService.openExternalUrl(nativeLink);
          } else {
            this.electronService.openExternalUrl(item.path);
          }
        } catch (e) {
        }
      } else {
        window.open(item.path, "_blank");
      }
    } else {
      window.open(item.nativeLink, "_system");
    }
    this.closeSwitch.emit(false);
  }

  ngOnChanges(): void {
    (this.routes || []).forEach(route => {
      if (route.path === this.url && route.enabled) {
        route.active = true;
      } else {
        route.active = false;
      }
    });
  }

  navigate(path: any) {
    this.router.navigate(path);
    this.closeSwitch.emit(false);
  }

  ngOnDestroy(): void {
    this.closeSwitch.unsubscribe();
    this.destroy$.next(false);
    this.destroy$.complete();
  }

}

export interface AppRoutes {
  title: string;
  icon: string;
  path: string;
  active: boolean;
  enabled: boolean;
}

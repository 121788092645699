import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { MCBService } from "app/shared/services/mcb.service";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { getOrganizationId, getActiveConferences, getAllConversations, getIsAppOnline } from "app/reducers";
import { MultipleMCBAdd, MCBAdd, MCBUpdate, MCBDelete } from "app/meta-conference-board/actions/mcb";
import { Observable, BehaviorSubject, Subject, timer, of } from "rxjs";
import { concatMap, tap, map, reduce, take, filter, switchMap } from "rxjs/operators";
import { ConversationLoadSuccess } from "app/actions/conversation";
import { MetaConferenceBoard } from "app/meta-conference-board/models/mcb.model";
import { SetActiveConferences, SetRunningConferences, SetAvailableApps } from "app/actions/app";
import { GroupChatsService } from "app/shared/services/groupchat.service";
import * as _ from "lodash";
import { UpdateRecentParticipants } from "app/meta-conference-board/actions/conference";

const DEFAULT_MCB_DESC = "This is description for all uncategorised  Meta Conference Boards. It shows the conference boards that are not assigned to any list";
const POLLING_INTERVAL = 20000;
@Injectable()
export class MCBRepository {
    activeConferences = [];
    constructor(
        private mcbService: MCBService,
        private groupChatsService: GroupChatsService,
        private store: Store<MCBRootState>) {
        console.log("[MCBRepository]");
        this.startSignalPolling();
    }

    initData() {
        // this.getGroups().subscribe(conversations => {
        //     console.log("[MCBRepository]getGroups", conversations);
        //     this.store.dispatch(new ConversationLoadSuccess(conversations));
        // });
        this.getSingleChats().subscribe(conversations => {
            console.log("[MCBRepository]getSingleChats", conversations);
            this.store.dispatch(new UpdateRecentParticipants(conversations
                .filter(v => v.target.indexOf("@external") === -1 &&  v.target.indexOf("broadcast-") === -1).map(v => v.target)));
        });
        this.getMyProducts().subscribe(data => {
            if (data && data.products) {
                localStorage.setItem("federatedApps", JSON.stringify(data.products));
                this.store.dispatch(new SetAvailableApps(data.products));
            }
        });
        if (!!localStorage.getItem("allConferences")) {
            this.store.dispatch(new ConversationLoadSuccess(JSON.parse(localStorage.getItem("allConferences"))));
        }
        // this.store.select(getAllConversations).subscribe(res => {
        //     const allConferences = res.filter(v => v.jid);
        //     localStorage.setItem("allConferences", JSON.stringify(allConferences));
        // });
    }

    getMyProducts(): Observable<any> {
        return this.mcbService.getMyProducts();
    }

    startSignalPolling() {
        console.log("[startMCBPolling]");
        timer(0, POLLING_INTERVAL).pipe(switchMap(v => this.getRunningConferences())).pipe(switchMap(res => {
          const conferences = {};
          if (res[0]) {
            res.forEach(v => {
                const room = v[0][0];
                if (room) {
                    room.participant = room.participant.filter((v: any) => v.display_name !== "");
                    room.totalParticipants = room.participant.length;
                }
                conferences[room.roomname] = room;
            });
          }
          this.store.dispatch(new SetRunningConferences(conferences));
          return this.getConferencesByRoomIds(Object.keys(conferences)).pipe(map((c: any) => {
              return c.map(conf => {
                console.log("[getRunningConferences] conferences", conferences, conf.roomId, conferences[conf.roomId]);
                return {...conferences[conf.roomId], ...conf};
              });
          }));
        })).subscribe(activeConferences => {
            console.log("[getRunningConferences] activeConferences", activeConferences);
            this.store.dispatch(new SetActiveConferences(activeConferences));
        });
    }

    getJitsiRoom(target) {
        return this.mcbService.getJitsiRoom(target);
    }

    getGroups(offset = 0, limit = 100) {
        const trigger = new BehaviorSubject<number>(offset);
        return trigger.pipe(
            concatMap(o => this.mcbService.getGroups(o * 100, limit)
                .pipe(
                    tap(res => {
                        if (res.length === 100) {
                            trigger.next(o + 1);
                        } else {
                            trigger.complete();
                            localStorage.setItem("lastUpdated", new Date().getTime().toString());
                        }
                    }),
                    reduce((accumulator, conversations) => [...accumulator, ...conversations]))
            ));
    }

    getSingleChats(offset = 0, limit = 100) {
        const trigger = new BehaviorSubject<number>(offset);
        return trigger.pipe(
            concatMap(o => this.mcbService.getSingleChats(o * 100, limit)
                .pipe(
                    tap(res => {
                        if (res.length === 100) {
                            trigger.next(o + 1);
                        } else {
                            trigger.complete();
                            localStorage.setItem("lastUpdated", new Date().getTime().toString());
                        }
                    }),
                    reduce((accumulator, conversations) => [...accumulator, ...conversations]))
            ));
    }

    createJitsiRoom(confKey: string, jitsiRoomId: string, jitsiURL?: string): Observable<any> {
        return this.mcbService.createJitsiRoom(confKey, jitsiRoomId, jitsiURL);
    }

    initMCBList() {
        this.store.select(getIsAppOnline).pipe(filter(v => !!v)).subscribe(v => {
            this.mcbService.getMCBList().subscribe(mcbs => {
                console.log("[getMCBList]", mcbs);
                if (mcbs.length === 0) { // create default list
                    this.createMCB({ description: DEFAULT_MCB_DESC });
                } else {
                    this.store.dispatch(new MultipleMCBAdd(mcbs));
                }
            });
        });
    }

    getMCBList() {
        return this.mcbService.getMCBList().pipe(tap(mcbs => {
            this.store.dispatch(new MultipleMCBAdd(mcbs));
        }));
    }

    searchMCBs(keyword) {
        return this.mcbService.searchMCBs(keyword);
    }

    getTags(params?: any) {
        return this.mcbService.getTags(params).pipe(map(res => {
            return res.tags || [];
        }));
    }

    getRunningConferences() {
        return this.mcbService.getRunningConferences();
    }

    getConferencesByRoomIds(roomIds) {
        if (roomIds && roomIds.length > 0) {
            return this.mcbService.getConferencesByRoomIds(roomIds);
        }
        return of([]);
    }

    getTagsByJid(jid) {
        return this.mcbService.getTagsByJid(jid).pipe(map(res => {
            return res.objects || [];
        }));
    }

    createOrUpdateTag(jid, tags) {
        return this.mcbService.createOrUpdateTag(jid, tags).pipe(map((res: any) => {
            return res.objects || [];
        }));
    }
    updateGroupAvatar(jid, data) {
        return this.groupChatsService.updateGroupAvatar(jid, data);
    }

    createMCB(body?: any) {
        body = body || {};
        const subject = new Subject();
        this.mcbService.createMCB(body).subscribe((v: any) => {
            console.log("[createMCB]", v, body);
            if (v && v.meta_conference_board) {
                subject.next(v.meta_conference_board);
                if (body.conferences) {
                    this.mcbService.addGroupChats(v.meta_conference_board.id, body.conferences).subscribe();
                }
                this.store.dispatch(new MCBAdd(v.meta_conference_board as MetaConferenceBoard));
            } else {
                subject.next(v.error[0]);
            }
        });
        return subject.asObservable().pipe(take(1));
    }

    updateMCB(body) {
        body = body || {};
        const subject = new Subject();
        this.mcbService.updateMCB(body).subscribe((v: any) => {
            console.log("[updateMCB]", v, body);
            if (v && v.meta_conference_board) {
                subject.next(v.meta_conference_board);
                this.store.dispatch(new MCBUpdate({ id: body.id, changes: body }));
                if (body.addConferences.length > 0) {
                    if (body.deleteConferences.length === 0) {
                        this.mcbService.addGroupChats(body.id, body.addConferences).subscribe((res: any) => {
                            this.store.dispatch(new MCBUpdate({ id: body.id, changes: {conferences: res.conferences} }));
                        });
                    } else {
                        this.mcbService.removeGroupChats(body.id, body.deleteConferences).subscribe((res: any) => {
                            this.store.dispatch(new MCBUpdate({ id: body.id, changes: {conferences: res.conferences} }));
                            this.mcbService.addGroupChats(body.id, body.addConferences).subscribe((res2: any) => {
                                this.store.dispatch(new MCBUpdate({ id: body.id, changes: {conferences: res2.conferences} }));
                            });
                        });
                    }
                } else if (body.deleteConferences.length > 0) {
                    this.mcbService.removeGroupChats(body.id, body.deleteConferences).subscribe((res: any) => {
                        this.store.dispatch(new MCBUpdate({ id: body.id, changes: {conferences: res.conferences} }));
                    });
                }
            } else {
                subject.next(v.error[0]);
            }
        });
        return subject.asObservable().pipe(take(1));
    }

    deleteMCB(id) {
        this.mcbService.deleteMCB(id).subscribe((v: any) => {
            this.store.dispatch(new MCBDelete(id));
        });
    }

    getMCB(id) {
        return this.mcbService.getMCB(id).pipe(tap({
            next: (val: any) => {
                if (!!val) {
                    this.store.dispatch(new MCBAdd(val));
                }
            }
          }));
    }

    getGroupInfo(id) {
        return this.groupChatsService.getGroupInfo(id);
    }

    getGroupMembers(id) {
        return this.groupChatsService.getRoomMembers(id);
    }

    updateGroupInfo(id, params) {
        return this.groupChatsService.updateGroupInfo(id, params);
    }

    scheduleMeeting(attributes, attendees) {
        return this.mcbService.scheduleMeeting(attributes, attendees);
    }

    changeGroupMCBs(target, mcbs) {
        return this.groupChatsService.changeGroupMCBs(target, mcbs);
    }

    searchGroups(searchString: string, memberName?: string, members?: string[]): Observable<any> {
        if (!searchString) {
            return of([]);
        }
        return this.mcbService.searchGroups(searchString, memberName, members);
    }

    sendEmail(body: any) {
        return this.mcbService.sendEmail(body);
    }
}

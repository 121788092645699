import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, NgZone } from "@angular/core";
import {
  getActiveConferences,
  getAllConversation,
  getContacts,
  getFederatedApps,
  getIsAppBootstrapped,
  getOnlineContactsBareId,
  getUserProfile
} from "../reducers";
import { combineLatest, of, Subject, timer } from "rxjs";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ConfigService } from "../config.service";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/app";
import { takeUntil, debounceTime, filter, take, distinctUntilChanged, switchMap, map } from "rxjs/operators";
import { isArray } from "util";
import { environment } from "environments/environment";
import { AppsDialogComponent } from "app/shared/components/apps-dialog/apps-dialog.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatMenuTrigger } from "@angular/material/menu";
import { CommonUtil } from "app/utils/common.util";
import { AuthService } from "app/shared/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { VersionDialogComponent } from "app/shared/components/version-dialog/version-dialog.component";
import { HelpDialogComponent } from "app/shared/components/help-dialog/help-dialog.component";
import { ServiceDeskComponent } from "app/shared/components/service-desk-dialog/service-desk-dialog.component";
import { GlobalSearchComponent, TopBarService } from "vnc-library";
import { AvatarRepository } from "app/repositories/avatar.repository";
import { ConstantsUtil } from "app/utils/constants.util";
import { MCBService } from "app/shared/services/mcb.service";
import { ContactRepository } from "app/repositories/contact.repository";
import { MCBRepository } from "app/repositories/mcb.repository";
import { getCMCBs } from "app/meta-conference-board/reducers";
import { Conference, MetaConferenceBoard } from "app/meta-conference-board/models/mcb.model";
import { MCBDelete } from "app/meta-conference-board/actions/mcb";
import { SetActiveTab } from "app/actions/app";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: any;
  searchKeyword: string;
  searchMode = false;
  showUserMenu = false;
  profileBackground = "url(" + CommonUtil.getFullUrl(environment.profileBackgroundImage) + ")";
  isSearchQueryExist = false;
  searchText = "";
  switchApp = false;
  currentUrl = "";
  isCordovaOrElectron: boolean;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  penIcon = CommonUtil.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtil.getFullUrl("/assets/img/filter.svg");
  isMobileScreen = false;
  appLogo = "";
  appLogoDesktop = CommonUtil.getFullUrl(environment.appLogo);
  textLogo = environment.textLogo;
  brandName = environment.mobileTextLogo;
  avatarURL: string;
  searchFor = localStorage.getItem("getSearchFor") || "mail";
  includeSharedItems = (localStorage.getItem("includeSharedItems") || "true") === "true";
  isHandset: boolean;
  searchControl: UntypedFormControl = new UntypedFormControl("");
  private searchKeyDown = new Subject();
  private isAlive$ = new Subject<boolean>();
  termsOfUse: any;
  faqURL: any;
  serviceDeskURL: any;
  dataPrivacy: any;
  userManual: any;
  @ViewChild(TopBarService) topBarService: TopBarService;

  isHinTheme = localStorage.getItem("theme") === "hin";
  isAirbusTheme = localStorage.getItem("theme") === "airbus";
  isHinEnv = environment.theme === "hin";
  isEkboTheme = localStorage.getItem("theme") === "ekbo";
  isEkboEnv = environment.theme === "ekbo";

  currentTheme = environment.theme;
  themeColor: string = "#317bbc";
  color: string = "#FFFFFF";

  headerBranding: string = '';
  isOnIpad = CommonUtil.isOnIpad();
  logoUrlBackend: any;
  userImageUrl = "";

  apps = [];
  
  defaultAvatar = environment.theme === "hin" ? CommonUtil.getFullUrl("/assets/hintalk-avatar-transparent.png") : "";
  contactsCount: number = 0;
  bootstrapped: boolean = false;
  conferences: Conference[] = [];
  mcbList: MetaConferenceBoard[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private titleService: Title,
    private broadCaster: Broadcaster,
    private avatarRepo: AvatarRepository,
    private mcbService: MCBService,
    private contactRepo: ContactRepository,
    private mcbRepo: MCBRepository
  ) {
    console.log("[HeaderComponent] constructor", new Date());
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeDetectorRef.markForCheck();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.isHandset = this.breakpointObserver.isMatched([Breakpoints.Handset]);
    this.broadCaster.on<any>("keyword").pipe(takeUntil(this.isAlive$)).subscribe(data => {
      console.log("[HeaderComponent]", data);
      this.searchControl.patchValue(data);
    });
    this.config.currentLanguage.asObservable().pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.translate.get("MULTI_CONFERENCE_BOARD").pipe(take(1)).subscribe(text => {
        this.titleService.setTitle(text);
      });
    });
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
        this.changeDetectorRef.markForCheck();
      });

      if (environment.theme === "hin" && !CommonUtil.isMobileSize()) {
        this.headerBranding = "";
      }

      this.broadCaster.on("userAvatarUpdated").pipe(takeUntil(this.isAlive$)).subscribe(
        () => {
          const time = new Date().getTime();
          const version = `?ver=${Math.abs(time)}`;
          this.userImageUrl = this.avatarRepo.buildAvatarUrl(this.currentUser?.email) + version;
          // this.logger.info("[HeaderComponent][userAvatarUpdated] userImageUrl", this.userImageUrl);
          this.changeDetectorRef.markForCheck();
        }
      );

      this.broadCaster.on("hideSwitchApp").pipe(takeUntil(this.isAlive$)).subscribe(
        () => {
          this.switchApp = false;
        }
      );
  }

  ngOnInit() {
    console.log("[HeaderComponent] ngOnInit", new Date());
    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(profile => {
      this.currentUser = profile.user;
      this.changeDetectorRef.markForCheck();
    });

    this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
      this.currentUrl = this.router.routerState.snapshot.url;
      this.changeDetectorRef.markForCheck();
    });

    this.broadCaster.on<any>("closeSearch").pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.clearSearchText();
    });

    this.broadCaster.on<any>(ConstantsUtil.HIDE_AUTOCOMPLETE)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        // this.logger.info("HIDE_AUTOCOMPLETE");
        this.topBarService.closeAutocomplete();
        this.topBarService.search$
      });

    this.setUrls(CommonUtil.getDefaultLang());
  }

  ngAfterViewInit() {
    this.store.select(getIsAppBootstrapped).pipe(distinctUntilChanged()).subscribe(v => {
      if (!this.bootstrapped) {
        if (!!v) {
          this.bootstrapped = true;
          setTimeout(() => {
            this.mcbService.getSavedSearch({}).subscribe(res => {
              let results = [];
              if (!!res && res.queries) {
                results = res.queries;
              }
              this.topBarService.setSavedSearchItems(results);
            });
          }, 3000);
        }
      }
    });

    this.topBarService.onSelectItem$.pipe().subscribe(item => {
      // this.logger.info("[onSelectItem]", item);
      if (!!document.querySelector(".vnc-search-bar__input")) {
        (<HTMLElement> document.querySelector(".vnc-search-bar__input")).blur();
      }

      if (!!item && item.filters) {
        let keyword = item.filters && item.filters.q ? item.filters.q.values[0] : this.topBarService.searchControl.value;
        this.openAdvancedSearch(keyword, item);
      }
    });
    // this.responsiveService.screen$
    //   .pipe(takeUntil(this.isAlive$)
    //   , startWith(this.screen)
    //   , delay(500)
    //   , switchMap((screen) => screen !== "mobile" ? this.topBarService?.search$ : empty()))
    //   .subscribe((search) => {
    //     if (search.query.trim() !== "") {
    //       this.openAdvancedSearch(search.query);
    //     }
    //   });

    // this.responsiveService.screen$
    //   .pipe(takeUntil(this.isAlive$)
    //   , startWith(this.screen)
    //   , delay(500)
    //   , switchMap((screen) => screen !== "mobile" ? this.topBarService?.searchFilterTrigger$ : empty()))
    //   .subscribe(() => {
    //     this.openAdvancedSearch(this.topBarService.searchControl.value);
    //   });
      this.topBarService.isSearchBarActive$.pipe().subscribe(v => {
        if (!!v) {
          this.mcbService.getSavedSearch({}).subscribe(res => {
            let results = [];
            if (!!res && res.queries) {
              results = res.queries;
            }
            this.topBarService.setSavedSearchItems(results);
          });
        }
      });
      this.topBarService.searchControl.valueChanges
      .pipe(debounceTime(500)
      , switchMap((v) => {
        // this.logger.info("[searchControl] valueChanges", v);
        if (v) {
          this.broadCaster.broadcast("keyword", v);
          let keyword = v;
          this.mcbList = [];
          this.mcbRepo.searchMCBs(v).subscribe(v => {
            this.mcbList = v.filter(mcb => mcb.name.toLowerCase().includes(keyword) || mcb.description.toLowerCase().includes(keyword))
            .map(mcb => this.conferenceMapping(mcb));
            this.changeDetectorRef.markForCheck();
          }, (err) => {});
          this.changeDetectorRef.markForCheck();
          console.log("[MetaConferenceBoardComponent] search", keyword);
        } else {
          this.store.select(getCMCBs).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            console.log("[getCMCBss]", res);
            this.mcbList = res.map(mcb => this.conferenceMapping(mcb));
            this.changeDetectorRef.markForCheck();
          });
          this.store.select(getActiveConferences).pipe(takeUntil(this.isAlive$)).subscribe(conferences => {
            this.conferences = conferences;
            this.mcbList = this.mcbList.map(mcb => this.conferenceMapping(mcb));
            this.changeDetectorRef.markForCheck();
          });
          this.startMCBPolling();
          return this.mcbList;
        }
        
        return of([]);
      }))
      .subscribe((v) => {
        // this.logger.info("[searchControl]", v);
        // this.topBarService.setSearchItems(v);
        
        this.topBarService.setSearchItems(v);
        this.mcbService.createSearchQuery({
          f: ["q"],
          query: {
            name: this.topBarService.searchControl.value
          },
          v: {
            q: [this.topBarService.searchControl.value]
          }
        }
      ).subscribe(v => {
          // this.logger.info("[createSearchQuery]", v);
        });
      });
  }


  conferenceMapping(mcb) {
    const jids = mcb.conferences ? mcb.conferences.map(c => c.jid) : [];
    return {...mcb, ...{totalLive: this.conferences.filter(c => jids.includes(c.jid)).length}};
  }



  startMCBPolling() {
    console.log("[startMCBPolling]");
    timer(0, 60000).pipe(takeUntil(this.isAlive$), switchMap(v => this.mcbRepo.getMCBList())).subscribe(mcbs => {
      const newIds = mcbs.map(v => v.id);
      const idsToDelete = this.mcbList.map(v => v.id).filter(v => !newIds.includes(v));
      idsToDelete.forEach(id => {
        this.store.dispatch(new MCBDelete(id));
      });
    });
  }

  getSingleEmail(emails) {
    if (isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }

  openMenu(): void {
    this.trigger.openMenu();
  }

  toggleMobileSearch(): void {
    this.searchMode = !this.searchMode;
    this.clearSearchText();
  }

  searchOnKeyUp() {
    this.searchKeyDown.next(true);
  }

  clearSearchText(): void {
    this.searchKeyword = "";
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  switchAppMenuOption() {
    if (this.isMobileScreen) {
      this.showUserMenu = true;
    } else {
      this.switchApp = this.switchApp ? false : true;
    }
    this.changeDetectorRef.markForCheck();
  }

  closeAppSwitcher() {
    this.switchApp = false;
    this.changeDetectorRef.markForCheck();
  }

  selectedOption(event: MatAutocompleteSelectedEvent): void {
    this.searchKeyword = event.option.value as string;
  }

  logout(): void {
    this.showUserMenu = false;
    this.auth.logoutWebAndApp();
    this.changeDetectorRef.markForCheck();
  }

  navigatorTo(url: string): void {
    this.currentUrl = this.router.routerState.snapshot.url;
    this.router.navigate([url]);
    this.searchControl.patchValue("");
  }

  openAppSwitcherDialog(): void {
    this.matDialog.open(AppsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  search() {
    this.router.navigateByUrl("/search/" + this.searchControl.value);
  }

  versionDialog(): void {
    this.matDialog.open(VersionDialogComponent, {
      maxWidth: "100%",
      width: "400px",
      height: "536px",
      autoFocus: false,
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel"
    });
  }

  manualDialog(): void {
    this.matDialog.open(HelpDialogComponent, {
      maxWidth: "100%",
      width: "520px",
      height: "360px",
      autoFocus: false,
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel"
    });
  }

  deskDialog(): void {
    this.matDialog.open(ServiceDeskComponent, {
      maxWidth: "100%",
      width: "520px",
      height: "340px",
      autoFocus: false,
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel"
    });
  }

  setUrls(browserLang) {
    if (this.config.URLS.serviceDesk) {
      this.serviceDeskURL = this.config.URLS.serviceDesk;
    } else {
      this.serviceDeskURL = "https://redmine.vnc.biz/helpdesk/incidents/new";
    }
    if (browserLang === "en") {
      if (this.config.URLS.faq) {
        this.faqURL = this.config.URLS.faq;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vnccommander-faq";
      }

      if (this.config.URLS.LegalNoticeTermOfUse) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }
      if (this.config.URLS.userManual) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vnccommander/usermanual/";
      }

    } else {
      if (this.config.URLS.faq_de) {
        this.faqURL = this.config.URLS.faq_de;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vnccommander-faq-de";
      }
      if (this.config.URLS.LegalNoticeTermOfUse_de) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse_de;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/Nutzungsbedingungen/";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy_de) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy_de;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }

      if (this.config.URLS.userManual_de) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://de.docs.vnc.biz/vnccommander/usermanual/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  onCloseAutocomplete() {
    this.topBarService.clearSearchText();
  }

  switchApplication(event): void {
    if (event && event.switch) {
      this.switchApp = !this.switchApp;
    } else {
      this.switchApp = false;
    }
  }

  switchApps($event) {
    if (window.outerWidth > 767) {
      $event.switch = true;
    } else {
      this.showUserMenu = true;
    }
  }

  getThemeColor(): void {
    const theme = localStorage.getItem("theme");
    if (theme !== null && theme === "hin") {
      this.themeColor = "#f39900";
      if (this.currentTheme === "hin") {
        this.themeColor = "#FFFFFF";
        this.color = "#8f8f8f";
      }
    } else if (theme !== null && theme === "ekbo") {
      this.themeColor = "#6633cc";
    } else if (theme !== null && theme === "airbus") {
      this.themeColor = "#00205b";
    } else {
      this.themeColor = "#317bbc";
    }
    this.changeDetectorRef.markForCheck();
  }

  openAdvancedSearch(keyword?: string, filters?: any) {
    this.newAdvanceSearch(keyword, filters);
  }

  newAdvanceSearch(keyword?: string, filters?: any) {
    let options: any = {
      width: "100vw",
      minWidth: "100vw",
      height: "100vh",
    };
    const contacts$ = this.store.select(getContacts).pipe(take(1));
    const onlineContacts$ = this.store.select(getOnlineContactsBareId).pipe(take(1));
    let allContacts = [];
    let onlineJids = [];
    combineLatest([contacts$ , onlineContacts$])
    .pipe(take(1))
    .subscribe(([contacts , onlineContactsBareIds ]) => {
      allContacts = contacts.filter(c => c.bare !== this.currentUser?.email
        && !c.bare.startsWith("broadcast") && c.bare.indexOf("#") === -1);
      onlineJids = onlineContactsBareIds.filter(c => c.bare !== this.currentUser?.email);
    });
    let conversations = [];
    this.store.select(getAllConversation).pipe(take(1)).subscribe(v => conversations = v);
    const dialogAction = new Subject<any>();
    dialogAction.pipe(takeUntil(this.isAlive$)).subscribe(v => {
      // this.logger.info("[dialogAction]", v);
        switch (v?.action) {
          // case "share": {
          //     this.emailTo(document);
          //   break;
          // }
          // case "forward": {
          //     this.sendTo(document);
          //   break;
          // }
          // case "download": {
          //     this.downloadMessage(document.doc);
          //   break;
          // }
          default: break;
        }
      });
    let languages = {};
    this.translate.getTranslation(localStorage.getItem("portalLanguage")).pipe(take(1)).subscribe(v => {
      languages = v;
      // this.logger.info("[getTranslation]", languages);
      const dialog = this.matDialog.open(GlobalSearchComponent, Object.assign({
          backdropClass: "vnctalk-advanced-search-backdrop",
          panelClass: "vnctalk-advanced-search-panel",
          disableClose: true,
          data: {
            useOldSearch: this.config.get("useOldSearch"),
            tags: this.mcbRepo.getTags(),
            keyword: keyword,
            allContacts: allContacts,
            conversations: conversations,
            filters: filters,
            dialogAction: dialogAction,
            translations: languages,
            userJid: this.currentUser?.email,
            hideChannelTab: this.isHinEnv || this.isEkboEnv,
            hideBroadcastTab: this.isHinEnv || this.isEkboEnv,
            hideBroadcast: this.isHinEnv || this.isEkboEnv,
            avatarServiceUrl: this.config.avatarServiceUrl,
            onlineJids: onlineJids
          },
          autoFocus: true
         }, options));

         dialog.afterClosed().pipe(take(1)).subscribe(v => {
            this.topBarService.closeAutocomplete();
            setTimeout(() => {
              if (!!document.querySelector(".vnc-search-bar__input")) {
                (<HTMLElement> document.querySelector(".vnc-search-bar__input")).blur();
              }
            }, 100);
            this.topBarService.clearSearchText();
           if (v && v.chat) {
            let jid = v.chat.talk_jid_s;
            const filters = v.chat.filters;
            if (v.chat.talk_type_s !== "groupchat") {
              jid = v.chat.from_s;
              if (this.currentUser?.email === jid) {
                jid = v.chat.talk_jid_s.replace(`-${this.currentUser?.email}`, "").replace(`${this.currentUser?.email}-`, "");
              }
            }
           } else if (v && v.broadcast) {
            const jid = v.broadcast.talk_jid_s.replace(v.broadcast.owner_s, "").replace("-broadcast", "broadcast");
            this.store.dispatch(new SetActiveTab("broadcast"));
           } else if (v && v.contact) {
             let jid = "";
             if (v.contact.jid) {
               jid = v.contact.jid;
             } else if (v.contact.emails && v.contact.emails.length > 0) {
               jid = v.contact.emails[0].email;
             }
             
           }
         });
      });

  }
}

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { Store } from "@ngrx/store";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { ConfigService } from "app/config.service";

@Injectable()
export class GroupChatsService {

  constructor(
    private middlewareService: MiddlewareService,
    private configService: ConfigService,
    private store: Store<MCBRootState>) {
  }

  updateGroupAvatar(target, base64Data: string) {
    const data = { "avatar_base64": "data:image/png;base64," + base64Data };
    return this.middlewareService.post(`/api/group_chats/avatar/` + target, true, data);
  }

  removeGroupAvatar(target) {
    const data = { "delete": "true" };
    return this.middlewareService.post(`/api/group_chats/avatar/` + target, true, data);
  }

  setSubject(target, newTitle) {
    const data = { "group_chat": { "subject": newTitle } };
    return this.middlewareService.post(`/api/group_chats/subject/` + target, true, data);
  }

  changeGroupMCBs(target, ids) {
    const data = { "group_chat": { "meta_conference_board_ids": ids } };
    return this.middlewareService.post(`/api/group_chats/` + target, true, data);
  }

  updateGroupInfo(target, params) {
    const data = { "group_chat": params };
    return this.middlewareService.post(`/api/group_chats/` + target, true, data);
  }

  getGroupInfo(target) {
    return this.middlewareService.get(`/api/group_chats/${target}`, true).pipe(map((v: any) => {
      return v ? v.group_chat : null;
    }));
  }

  getRoomMembers(target: string): Observable<any> {
    return this.middlewareService.get<any>("/api/roomMembers/" + encodeURIComponent(target), true, {});
  }

  getAllRoomsMembers(updatedAfter?: number): Observable<any> {
    const params = {};
    if (updatedAfter) {
      params["updated_after"] = Math.floor(updatedAfter / 1000);
    }
    return this.middlewareService.get<any>("/api/roomMembers/all", true, params);
  }

  createJitsiRoom(confKey: string, jitsiRoom: string, jitsiURLparam?: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    let jitsiURL: string = jitsiURLparam || this.configService.get("jitsiURL") || "";
    const lastIndex = jitsiURL.lastIndexOf("/");
    if (lastIndex > 0) {
      jitsiURL = jitsiURL.slice(0, lastIndex + 1);
    }
    const data = {
      value: jitsiRoom,
      jitsiurl: jitsiURL + jitsiRoom
    };

    console.log("[ConversationService][createJitsiRoom]", data, this.configService.get("jitsiURL"));

    return this.middlewareService.post("/api/confMap/" + encodeURIComponent(confKey), true, data);
  }

  deleteJitsiRoom(confKey: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    return this.middlewareService.delete("/api/confMap/" + encodeURIComponent(confKey), true);
  }

  getJitsiRoom(confKey: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    return this.middlewareService.get("/api/confMap/" + encodeURIComponent(confKey), true);
  }

  setRoomAffiliation(target, userJid: string[], role: string) {
    if (role === null || role === "") {
      role = "member";
    }
    const affiliationArr = {};
    for (let i = 0; i < userJid.length; i++) {
      affiliationArr[i.toString()] = { "user_email": userJid[i], "affiliation": role };
    }
    const data = { "affiliation_attributes": affiliationArr };
    return this.middlewareService.post(`/api/group_chats/affiliation/` + target, true, data);
  }
}

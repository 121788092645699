import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState, getNetworkInformation } from "app/reducers";
import { ContactUpdatePhotoLastUpdate } from "app/actions/contact";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { distinctUntilChanged } from "rxjs/operators";


@Injectable()
export class AvatarRepository {

  private isNetOnline = false;

  constructor(private store: Store<RootState>,
    private electronService: ElectronService,
    private configService: ConfigService) {

    this.store.select(getNetworkInformation).pipe(distinctUntilChanged())
      .subscribe(information => {
        if (information) {
          this.isNetOnline = information.onlineState;
        }
      });
  }

  buildAvatarUrl(bare: string) {
    let avatarName = this.buildTargetHash(bare);
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg" + `?ver=${Math.abs(new Date().getTime())}`;
    return avUrl;
  }

  buildTargetHash(target) {
    if (this.electronService.isElectron) {
      return this.electronService.md5(target);
    }
    return md5(target);
  }

  isNetworkOnline() {
    return this.isNetOnline;
  }

  removeAvatar(bareTarget: string) {
    console.log("[AvatarRepository][removeAvatar] bareTarget", bareTarget);

    this.store.dispatch(new ContactUpdatePhotoLastUpdate({
      bare: bareTarget,
      photoLastUpdate: -1
    }));
  }

  upgradeAvatar(bareTarget: string) {
    console.log("[AvatarRepository][upgradeAvatar] bareTarget", bareTarget);

    this.store.dispatch(new ContactUpdatePhotoLastUpdate({
      bare: bareTarget,
      photoLastUpdate: new Date().getTime()
    }));
  }
}

import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatGridListModule } from "@angular/material/grid-list";
import { CdkScrollableModule } from "@angular/cdk/scrolling";
import { AvatarComponent } from "./components/avatar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatNativeDateModule } from "@angular/material/core";
import { MCBDialogComponent } from "./components/mcb-form-dialog/mcb-form-dialog.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DeleteMCBDialogComponent } from "./components/delete-mcb-dialog/delete-mcb-dialog.component";
import { ChangeMCBListDialogComponent } from "./components/change-mcb-list/change-mcb-list.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CountdownModule } from "ngx-countdown";
import { EditConferenceDialogComponent } from "./components/edit-conference-dialog/edit-conference-dialog.component";
import { VjsPlayerComponent } from "./components/vjs-player.component";
import { StartConferenceComponent } from "./components/start-conference/start-conference.component";
import { SelectParticipantsComponent } from "./components/select-participants/select-participants.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from "@angular-material-components/datetime-picker";
import { UpdateAvatarComponent } from "./components/update-avatar/update-avatar.component";
import { ImageCropperModule } from "ngx-img-cropper";
import { AppsDialogComponent } from "./components/apps-dialog/apps-dialog.component";
import { OpenParticipantsComponent } from "./components/open-participants/open-participants.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { HelpDialogComponent } from "./components/help-dialog/help-dialog.component";
import { ServiceDeskComponent } from "./components/service-desk-dialog/service-desk-dialog.component";
import { VersionDialogComponent } from "./components/version-dialog/version-dialog.component";

const ANGULAR_MATERIALS = [
  MatSnackBarModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatSelectModule,
  MatButtonModule,
  ScrollingModule,
  MatGridListModule,
  MatProgressSpinnerModule,
  NgxMatTimepickerModule,
  NgxMatDatetimePickerModule
];

const DIRECTIVES = [
];

const DIALOGUES = [
  MCBDialogComponent,
  DeleteMCBDialogComponent,
  ChangeMCBListDialogComponent,
  EditConferenceDialogComponent,
  StartConferenceComponent,
  SelectParticipantsComponent,
  OpenParticipantsComponent,
  ConfirmationDialogComponent,
  HelpDialogComponent,
  ServiceDeskComponent,
  VersionDialogComponent,
  AppsDialogComponent
];

const PIPES = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        CdkScrollableModule,
        MatFormFieldModule,
        CountdownModule,
        ImageCropperModule,
        ...ANGULAR_MATERIALS,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        AvatarComponent,
        MCBDialogComponent,
        VjsPlayerComponent,
        CountdownModule,
        ImageCropperModule,
        ...ANGULAR_MATERIALS,
        // Directives
        ...DIRECTIVES,
        // Pipes
        ...PIPES,
        TranslateModule,
        CdkScrollableModule
    ],
    declarations: [
        AvatarComponent,
        MCBDialogComponent,
        VjsPlayerComponent,
        // Directives
        ...DIRECTIVES,
        // Dialogues
        ...DIALOGUES,
        // Pipes
        ...PIPES,
        EditConferenceDialogComponent,
        StartConferenceComponent,
        SelectParticipantsComponent,
        UpdateAvatarComponent,
        OpenParticipantsComponent,
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
      ]
    };
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import * as _ from "lodash";
import { GroupActionTypes } from "app/actions/group";
import { Group } from "app/shared/models/group.model";

export interface GroupState extends EntityState<Group> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const groupAdapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (group: Group) => group.id,
  sortComparer: null
});

export const initialState: GroupState = groupAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function groupReducer(state: GroupState = initialState, action: Action): GroupState {
  switch (action.type) {

    case GroupActionTypes.GROUP_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case GroupActionTypes.GROUP_LOAD_SUCCESS: {
      const contacts = action.payload.map(c => {
        const contact = state.entities[c.bare];
        if (contact) {
          c = {...c, ...contact};
        }
        return c;
      });
      return groupAdapter.addAll(contacts, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case GroupActionTypes.GROUP_ADD: {
      const newState = groupAdapter.addOne(action.payload, state);
      return groupAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }

    case GroupActionTypes.GROUP_UPDATE: {
      const updatedState = groupAdapter.updateOne({
        id: action.payload.id,
        changes: action.payload
      }, state);
      return updatedState;
    }

    case GroupActionTypes.GROUP_BULK_ADD: {
      const newState = groupAdapter.addMany(action.payload, state);

      const changes = action.payload.map(group => {
        return { id: group.id, changes: group };
      });

      return groupAdapter.updateMany(changes, newState);
    }

    case GroupActionTypes.GROUP_DELETE: {
      return groupAdapter.removeOne(action.payload, state);
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.groupState;
      return savedState ? { ...state, ...savedState } : state;
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoading = (state: GroupState) => state.isLoading;
export const _getIsLoaded = (state: GroupState) => state.isLoaded;

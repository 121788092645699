import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    Inject
} from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppService } from "app/shared/services/app.service";
import { getUserJID, getIsAppOnline, getUserConfig, RootState, getAllConversations } from "app/reducers";
import * as _ from "lodash";
import { ToastService } from "app/shared/services/toast.service";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";
import { ConfigService } from "app/config.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { takeUntil, debounceTime, switchMap } from "rxjs/operators";
import { Conversation } from "app/meta-conference-board/models/conversation.model";
import { MCBRepository } from "app/repositories/mcb.repository";

@Component({
    selector: "vp-delete-mcb-dialog",
    templateUrl: "delete-mcb-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteMCBDialogComponent implements OnDestroy, OnInit {
    searchControl: UntypedFormControl;
    description: UntypedFormControl;
    listName: UntypedFormControl;
    userJID: any;
    private isAlive$ = new Subject<boolean>();
    allConversations: Conversation[] = [];
    filteredConferences: Conversation[] = [];
    selectedConferences: Conversation[] = [];
    constructor(
        private toastService: ToastService,
        private matDialogRef: MatDialogRef<DeleteMCBDialogComponent>,
        private store: Store<RootState>,
        private mcbRepo: MCBRepository,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectionRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        window.scrollTo(0, 0);
    }

    @HostListener("document:keydown.esc")
    cancel(): void {
        this.matDialogRef.close();
    }

    delete() {
        this.mcbRepo.deleteMCB(this.data.mcb.id);
        this.matDialogRef.close();
    }
}

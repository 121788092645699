import {
  Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef,
  OnDestroy, ViewChild, AfterViewInit, ElementRef, OnChanges, SimpleChanges
} from "@angular/core";
import { Conference, MetaConferenceBoard } from "../models/mcb.model";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { MCBRootState, getActiveBoard } from "../reducers";
import { getFederatedApps, getBare, getJitsiURL, getActiveConferences, getUserProfile } from "app/reducers";
import { takeUntil, filter, take } from "rxjs/operators";
import { CommonUtil } from "app/utils/common.util";
import { MCBRepository } from "app/repositories/mcb.repository";
import * as moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { ChangeMCBListDialogComponent } from "app/shared/components/change-mcb-list/change-mcb-list.component";
import { ToastService } from "app/shared/services/toast.service";
import { CountdownComponent } from "ngx-countdown";
import { EditConferenceDialogComponent } from "app/shared/components/edit-conference-dialog/edit-conference-dialog.component";
import { ConfigService } from "app/config.service";
import { HttpClient } from "@angular/common/http";
import { ConstantsUtil } from "app/utils/constants.util";
import { StartConferenceComponent } from "app/shared/components/start-conference/start-conference.component";
import { OpenParticipantsComponent } from "app/shared/components/open-participants/open-participants.component";

@Component({
  selector: "vp-conference-tile",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./conference-tile.component.html"
})
export class ConferenceTileComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() conference: Conference;
  @Input() hideHeader: boolean;
  federatedApps = {};
  talkUrl = "";
  invitedParticipants = "";
  jid: string;
  jitsiURL: any;
  activeBoard: MetaConferenceBoard;
  leftTime = 0;
  private isAlive$ = new Subject<boolean>();
  conferenceLink: string;
  @ViewChild("countdown", { static: false }) public countdown: CountdownComponent;
  @ViewChild("participantsList", { static: false }) public participantsList: ElementRef;
  @ViewChild("remoteVideo", { static: false }) public remoteVideo: ElementRef;
  localStream: any;
  remoteStream: any;
  dayTime: number;
  dayLeft: number;
  admins: string[] = [];
  owner = "";
  options = {};
  conferenceData: any;
  showParticipants: boolean;
  position = {
    top: "0",
    left: "0"
  };
  startedPlayer: any;
  hasVideo: boolean;
  loadedMetadata: boolean;
  retryPlayStream: NodeJS.Timeout;
  peerConnection: any;
  members: any = [];
  isScheduledConference: boolean;
  constructor(
    private store: Store<MCBRootState>,
    private mcbRepository: MCBRepository,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private configService: ConfigService,
    private toaster: ToastService,
    private http: HttpClient,
    private changeDetectionRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.isScheduledConference = this.checkIsScheduledConference(this.conference);
    this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.federatedApps = apps;
      const talkApp = apps.find(app => app.name === "vnctalk");
      if (talkApp) {
        this.talkUrl = talkApp.url.endsWith("/") ? talkApp.url : talkApp.url + "/";
      }
      console.log(apps);
    });
    this.store.select(getBare).pipe(takeUntil(this.isAlive$)).subscribe(jid => {
      this.jid = jid;
    });
    this.store.select(getJitsiURL).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.jitsiURL = v;
    });
    this.store.select(getActiveBoard).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.activeBoard = res;
    });
    this.store.select(getActiveConferences).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.conferenceData = res.find(v => v.jid === this.conference.jid);
      if (!this.conferenceData && !!this.remoteVideo) {
        this.remoteVideo.nativeElement.src = "";
        this.loadedMetadata = false;
        this.conferenceData = {};
      }
      this.changeDetectionRef.markForCheck();
      console.log("[ConferenceTileComponent][getActiveConferences]", res, this.conferenceData);
      if (this.conferenceData && this.conferenceData.totalParticipants && this.conferenceData.totalParticipants > 0 ) {
        console.log("[getActiveConferences] before play stream", this.loadedMetadata,  this.conference);
        if (!this.loadedMetadata) {
          console.log("[ConferenceTileComponent] metadata is not loaded. Start loading meta data for running conference", this.conferenceData);
          this.startedPlayer = true;
          const streamServer = this.configService.streamServer;
          console.log("[ConferenceTileComponent][streamServer]", streamServer, this.conference, this.getTargetHash());
          this.playStream();
        }
      }
    });

    this.getMembers();

    this.getRoomId().subscribe(roomId => {
      if (!!roomId) {
        this.conferenceLink = this.generateLink(roomId, true);
        this.changeDetectionRef.markForCheck();
      }
    });
    if (this.conference.attendees) {
      this.invitedParticipants = this.conference.attendees.map(v => v.name || v.jid.split("@")[0]).join(", ");
      this.changeDetectionRef.markForCheck();
    }
    if (this.conference.start_time && this.conference.status === "planned") {
      const momentDate = moment(new Date(this.conference.start_time));
      const now = moment();
      const diff = momentDate.diff(now, "seconds");
      this.leftTime = diff > 0 ? diff : 0;
      this.dayLeft = momentDate.diff(now, "days");
      this.changeDetectionRef.markForCheck();
    }
  }

  getMembers() {
    this.mcbRepository.getGroupMembers(this.conference.jid).subscribe((v: any) => {
      const result = v[0];
      if (result) {
        this.owner = result.owner;
        this.admins = result.admins;
        this.members = result.members;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("[ngOnChanges]", changes);
  }

  ngAfterViewInit(): void {
    if (this.conference.start_time && this.conference.status === "planned" && this.countdown) {
      console.log("countdown begin", this.conference.start_time);
      this.countdown.begin();
      this.changeDetectionRef.markForCheck();
    }
  }

  copyURL() {
    CommonUtil.copyToClipboard([this.conferenceLink]);
    this.toaster.showSnackbar("COPIED");
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (this.retryPlayStream) {
      clearTimeout(this.retryPlayStream);
    }
    this.peerConnection = null;
  }

  openConference() {
    this.getRoomId().subscribe(roomId => {
      this.openVC(roomId);
    });
  }

  getRoomId() {
    const subject = new Subject();
    this.mcbRepository.getJitsiRoom(this.conference.jid).subscribe(res => {
      const roomId = res.value;
      subject.next(roomId);
    }, (err) => {
      const jitsiRoomId = CommonUtil.randomId(10);
      const jitsiUrl = this.jitsiURL;
      this.mcbRepository.createJitsiRoom(this.conference.jid, jitsiRoomId, jitsiUrl).subscribe(res => {
        subject.next(jitsiRoomId);
      });
    });
    return subject.asObservable();
  }

  openVC(roomId) {
    const url = this.generateLink(roomId);
    console.log("[ConferenceTileComponent][openVC]", url);
    window.open(url, "_blank");
  }

  generateLink(roomId, isShared?: boolean) {
    let username = this.jid;
    if (isShared) {
      username = "Anonymous";
    }
    const jwt = CommonUtil.signJWT(username, roomId, this.conference.jid, this.activeBoard.id);
    let url = `${this.talkUrl}conference/mcb/${jwt}`;
    if (isShared) {
      url = `${this.talkUrl}vncmeet/join/${jwt}`;
    }
    return url;
  }

  changeMCBList() {
    this.mcbRepository.getGroupInfo(this.conference.jid).subscribe(v => {
      if (!!v) {
        const options: any = {
          width: "420px",
          minHeight: "310px",
        };
        this.matDialog.open(ChangeMCBListDialogComponent, Object.assign({
          backdropClass: "mcb-form-backdrop",
          panelClass: "mcb-form-panel",
          disableClose: true,
          data: {
            action: "edit_conference",
            conference: v
          },
          autoFocus: true
        }, options));
      }
    });
  }

  openParticipants() {
    const options: any = {
      width: "420px",
      height: "580px",
    };
    this.matDialog.open(OpenParticipantsComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "open_participants",
        members: this.members || [],
        admins: this.admins || [],
        conference: this.conference
      },
      autoFocus: true
    }, options)).afterClosed().subscribe(data => {
      if (!!data && data.start) {
        this.openConference();
      }
    });
  }

  conferenceInfo(isEdit?: boolean) {
    const options: any = {
      width: "540px",
      height: "680px",
    };
    this.matDialog.open(StartConferenceComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: isEdit ? "edit_conference" : "preview_conference",
        members: this.members || [],
        admins: this.admins || [],
        conference: this.conference
      },
      autoFocus: true
    }, options)).afterClosed().subscribe(data => {
      if (!!data) {
        this.conference = data;
        this.changeDetectionRef.markForCheck();
      }
      this.getMembers();
    });
  }

  editConferenceInfo() {
    this.conferenceInfo(true);
  }

  conferenceStats() {
    this.toaster.show("UNDER_DEVELOPMENT");
  }

  handleEvent(event) {
    console.log("[handleEvent]", event);
  }

  handleMouseOver(event) {
    this.showParticipants = true;
    let top = event.clientY;
    if (this.participantsList.nativeElement.clientHeight + event.clientY > document.body.clientHeight) {
      top = document.body.clientHeight - this.participantsList.nativeElement.clientHeight;
    }
    this.position = {
      top: top - 10 + "px",
      left: event.clientX + 20 + "px"
    };
    this.changeDetectionRef.markForCheck();
  }

  handleMouseOut(event) {
    this.showParticipants = false;
    this.changeDetectionRef.markForCheck();
  }

  playStream() {
    this.store.select(getUserProfile).pipe(filter(v => !!v && !!v.streamServer), take(1)).subscribe(profile => {
      console.log("[ConferenceTileComponent][playStream] start play streaming after loading user profile", this.conferenceData, profile);
      if (this.conferenceData && this.conferenceData.totalParticipants && this.conferenceData.totalParticipants > 0 ) {
        console.log(`[ConferenceTileComponent][playStream] total joined participants: ${this.conferenceData.totalParticipants}`);
        this.peerConnection = new RTCPeerConnection({
          bundlePolicy: "max-bundle",
          rtcpMuxPolicy: "require"
        });
        this.peerConnection.addEventListener("track", (e) => {
          console.info("[ConferenceTileComponent][playStream] on track event", e);
          if (this.remoteVideo && this.remoteVideo.nativeElement.srcObject !== e.streams[0]) {
            console.info("[ConferenceTileComponent][playStream] pc received remote stream");
            this.addRemoteVideo(e.streams[0]);
          } else {
            console.warn(`[ConferenceTileComponent][playStream] cannot receive remote stream`);
          }
        });

        this.peerConnection.createOffer({
          offerToReceiveVideo: true,
          offerToReceiveAudio: true
        })
          .then((offer) => {
            this.peerConnection.setLocalDescription(offer);
            const url = `${this.configService.streamServer}/watch/${this.getTargetHash()}`;
            console.log("[ConferenceTileComponent][playStream] createOffer sucess", offer);
            console.log(`[ConferenceTileComponent][playStream] request stream server: ${url}`);
            this.http.post(url, { offer: offer.sdp }, { headers: { "Content-Type": "application/json" } }).subscribe((response: any) => {
              console.log(`[ConferenceTileComponent][playStream] response from stream server`, response);
              const answerStr = response.answer;
              const answer = new RTCSessionDescription({
                type: "answer",
                sdp: answerStr
              });

              this.peerConnection.setRemoteDescription(answer).then(() => {
                console.log("[ConferenceTileComponent][playStream] setRemoteDescription success");
              }, (error) => {
                console.error("[ConferenceTileComponent][playStream] setRemoteDescription err", error);
              });
            });
          })
          .catch((error) => {
            console.error("[ConferenceTileComponent][playStream] create offer err", error);
          });
      }
    });
  }


  addRemoteVideo(stream) {
    if (stream.getVideoTracks().length) {
      this.hasVideo = true;
    }
    this.remoteVideo.nativeElement.srcObject = stream;
    console.log("[ConferenceTileComponent][addRemoteVideo] check if the stream has video tracks", this.hasVideo);
    this.remoteVideo.nativeElement.addEventListener("loadedmetadata", (data) => {
      console.log("[ConferenceTileComponent][addRemoteVideo] loaded metadata", data);
      this.loadedMetadata = true;
      stream.addEventListener("onremovetrack", (e => {
        console.log("[ConferenceTileComponent][addRemoteVideo] onremovetrack", e);
      }));
    });
    if (this.retryPlayStream) {
      clearTimeout(this.retryPlayStream);
    }
    this.retryPlayStream = setTimeout(() => {
      if (!this.loadedMetadata) {
        this.playStream();
      }
    }, ConstantsUtil.RETRY_PLAY_STREAM);
  }

  removeRemoteVideo() {
  }

  getTargetHash() {
    return md5(this.conference.jid);
  }

  checkIsScheduledConference(conference) {
    console.log("[checkIsScheduledConference]");
    return conference.start_time && (new Date(conference.start_time)).getTime() > new Date().getTime() && !(this.conferenceData && this.conferenceData.totalParticipants > 0);
  }
}

import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { MetaConferenceBoardModule } from "./meta-conference-board/meta-conference-board.module";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { StoreModule } from "@ngrx/store";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { reducers, clearState } from "./reducers";
import { CommonUtil } from "./utils/common.util";
import { Broadcaster } from "./shared/services/broadcaster.service";
import { ConfigService } from "./config.service";
import { MiddlewareService } from "./shared/services/middleware.service";
import { AuthService } from "./shared/services/auth.service";
import { UserConfigService } from "./shared/services/userConfig.service";
import { AuthGuard } from "./shared/providers";
import { httpInterceptorProviders } from "./http-interceptors";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MCBService } from "./shared/services/mcb.service";
import { MCBRepository } from "./repositories/mcb.repository";
import { ImageCropperModule } from "ngx-img-cropper";
import { HeaderComponent } from "./header/header.component";
import { ElectronService } from "./shared/providers/electron.service";
import { ContactRepository } from "./repositories/contact.repository";
import { ContactsService } from "./shared/services/contacts.service";
import { AppService } from "./shared/services/app.service";
import { GroupChatsService } from "./shared/services/groupchat.service";
import { AvatarRepository } from "./repositories/avatar.repository";
import { MatIconModule } from "@angular/material/icon";
import { ToastService } from "./shared/services/toast.service";
import { XmppService } from "./shared/services/xmpp.service";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { VncLibraryModule } from "vnc-library";
import { AppSwitcherComponent } from "./app-switcher.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, CommonUtil.getBaseUrl() + "/assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    HeaderComponent,
    AppComponent,
    AppSwitcherComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    VncLibraryModule,
    SharedModule,
    MatIconModule,
    NgxMatMomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    MetaConferenceBoardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    httpInterceptorProviders,
    TranslateService,
    AppService,
    AvatarRepository,
    GroupChatsService,
    AuthGuard,
    AuthService,
    Broadcaster,
    MiddlewareService,
    UserConfigService,
    ContactsService,
    ContactRepository,
    ConfigService,
    MCBService,
    MCBRepository,
    ToastService,
    ElectronService,
    XmppService
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

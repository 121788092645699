import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isCordovaOrElectron = environment.isElectron;
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.isCordovaOrElectron) {
            // Get the auth token from the service.
            const authToken = localStorage.getItem("token");
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = req.clone({
                headers: req.headers.set("Authorization", authToken)
            });

            // send cloned request with header to the next handler.
            return next.handle(authReq);
        } else {
            return next.handle(req);
        }
    }
}

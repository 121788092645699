import { format } from "date-fns";
import { enUS, de } from "date-fns/locale";

export class DateUtil {

  static DAYS_TS = 1000 * 60 * 60 * 24;

  static isSameDay(timestamp1: number, timestamp2 = Date.now()): boolean {
    const days1 = Math.floor(timestamp1 / this.DAYS_TS);
    const days2 = Math.floor(timestamp2 / this.DAYS_TS);

    return days1 === days2;
  }

  static ddmmyyyyString(timestamp: number): string {
    return format(timestamp, "dd.MM.yyyy");
  }

  static dateStringToTs(dateString: string): number {
    return new Date(dateString).getTime();
  }

  static tsToISOString(timestamp: number): string {
    return new Date(timestamp).toISOString();
  }

  static getDisplayTime(timestamp, lang, datetimeService, translateService): string {
    const isTodayTimestamp = this.isSameDay(+timestamp);
    const displayTimestamp = datetimeService.getDisplayTime(+timestamp);

    let displayTime = format(displayTimestamp, "HH:mm", {locale: lang === "de" ? de : enUS});
    if (lang === "de") {
      displayTime = displayTime  + " Uhr";
    }

    let at = "at";
    let dateLabel;
    translateService.get("AT").take(1).subscribe(text => at = text.toLowerCase());
    if (isTodayTimestamp) {
      translateService.get("TODAY").take(1).subscribe(text => dateLabel = text);
    } else {
      const dateLabelFormatStr = lang === "de" ? "dd.MM.yyyy" : "dd/MM/yyyy";
      dateLabel = format(displayTimestamp, dateLabelFormatStr, {locale: lang === "de" ? de : enUS});
    }
    return `${dateLabel} ${at} ${displayTime}`;
  }
}

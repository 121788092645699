import { Action } from "./index";

export class ConversationActionTypes {
  static CONVERSATION_LOAD_SUCCESS = "[CONVERSATION] Load Success";
}

export class ConversationLoadSuccess implements Action {
  readonly type = ConversationActionTypes.CONVERSATION_LOAD_SUCCESS;

  constructor(public payload: any[]) {
  }
}

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ToastService {

  constructor(private translate: TranslateService, private snackBar: MatSnackBar) {
  }

  show(translationKey: string, timeout = 2000) {
    this.translate.get(translationKey).pipe(take(1)).subscribe(msg => {
      console.log("toastService msg: ", msg);
      this.snackBar.open(msg, "", {duration: timeout});
    });
  }

  showSnackbar(translationKey: string, timeout = 6000) {
    this.translate.get(translationKey).pipe(take(1)).subscribe(msg => {
      this.snackBar.open(msg, "", {duration: timeout});
    });
  }
}

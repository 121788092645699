import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef, HostListener, ViewChild } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { ToastService } from "app/shared/services/toast.service";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MCBDialogComponent } from "../mcb-form-dialog/mcb-form-dialog.component";
import { Store } from "@ngrx/store";
import { RootState, getUserProfile, getFederatedApps } from "app/reducers";
import { MCBRepository } from "app/repositories/mcb.repository";
import { Router } from "@angular/router";
import { UntypedFormControl, Validators } from "@angular/forms";
import { startWith, map, takeUntil, take, filter } from "rxjs/operators";
import { getActiveBoard, getCMCBs } from "app/meta-conference-board/reducers";
import * as _moment from "moment";
import { default as _rollupMoment, Moment, MomentFormatSpecification, MomentInput } from "moment";
const moment = _rollupMoment || _moment;
import { ThemePalette } from "@angular/material/core";
import { SelectParticipantsComponent } from "../select-participants/select-participants.component";
import { UpdateAvatarComponent } from "../update-avatar/update-avatar.component";
import { ConstantsUtil } from "app/utils/constants.util";
import { XmppService } from "app/shared/services/xmpp.service";
import { GroupChatsService } from "app/shared/services/groupchat.service";
import { ConfigService } from "app/config.service";
import { CommonUtil } from "app/utils/common.util";
import { DateUtil } from "app/utils/date.util";
import { TranslateService } from "@ngx-translate/core";
import { ContactRepository } from "app/repositories/contact.repository";
import { AvatarRepository } from "app/repositories/avatar.repository";

@Component({
  selector: "vp-start-conference",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./start-conference.component.html"
})
export class StartConferenceComponent implements OnDestroy, OnInit {
  @ViewChild("picker") picker: any;
  public dateControl = new UntypedFormControl();
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = "primary";
  searchControl: UntypedFormControl;
  searchTagControl: UntypedFormControl;
  description: UntypedFormControl;
  meetingName: UntypedFormControl;
  password: UntypedFormControl;
  private isAlive$ = new Subject<boolean>();
  filteredOptions: any;
  mcbList = [];
  selectedMCBs = [];
  filteredTagOptions: any;
  tags = [];
  selectedTags = [];
  selectedParticipants = [];
  imageData: any;
  currentUser: any = {};
  jitsiURL: string;
  isLoading: boolean;
  passwordType = "text";
  jitsiRoomId: string;
  talkUrl: any;
  groupInfo: any;
  audiences = [];
  constructor(private toastService: ToastService,
    private avatarRepo: AvatarRepository,
    private configService: ConfigService,
    private matDialogRef: MatDialogRef<MCBDialogComponent>,
    private store: Store<RootState>,
    private mcbRepo: MCBRepository,
    private matDialog: MatDialog,
    private contactRepo: ContactRepository,
    private translate: TranslateService,
    private groupChatsService: GroupChatsService,
    private xmppService: XmppService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectionRef: ChangeDetectorRef) {
    this.searchControl = new UntypedFormControl("", []);
    this.searchTagControl = new UntypedFormControl("", []);
    this.meetingName = new UntypedFormControl(name, [Validators.required]);
    this.description = new UntypedFormControl("", []);
    this.password = new UntypedFormControl("", []);
    if (this.data.action !== "start_conference") {
      this.meetingName.patchValue(this.data.conference.name);
      this.description.patchValue(this.data.conference.description);
      this.password.patchValue(this.data.conference.password);
      this.dateControl.patchValue(this.data.conference.start_time);
      this.store.select(getActiveBoard).pipe(take(1)).subscribe(res => {
        this.selectedMCBs = [res];
      });
      this.imageData = this.buildAvatarUrl();
      this.data.members.forEach(p => {
        this.selectedParticipants.push({target: p, role: "participant", title: this.getTargetTitle(p)});
      });
      this.data.admins.forEach(p => {
         this.selectedParticipants.push({target: p, role: "moderator", title: this.getTargetTitle(p)});
      });

      console.log("[StartConferenceComponent]", this.selectedParticipants);
      this.mcbRepo.getGroupInfo(this.data.conference.jid).pipe(take(1)).subscribe(res => {
        console.log("[getGroupInfo]", res, this.data);
        if (!!res) {
          this.groupInfo = res;
          this.selectedMCBs = res.meta_conference_boards;
          if (res.affiliations_audience) {
            this.audiences = res.affiliations_audience.map(v => v.jid);
            console.log("[getGroupInfo]", this.audiences);
            this.selectedParticipants.forEach(r => {
                if (this.audiences.includes(r.target)) {
                  r.role = "audience";
                }
            });
          }
          this.changeDetectionRef.markForCheck();
        }
      });
      this.mcbRepo.getTagsByJid(this.data.conference.jid).subscribe(objects => {
        if (objects.length > 0 && !!objects[0].tags_list) {
          this.selectedTags = objects[0].tags_list.split(",");
          this.changeDetectionRef.markForCheck();
        }
      });
    }
    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(profile => {
      this.currentUser = profile.user;
      this.jitsiURL = profile.jitsiURL;
    });
    this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      const talkApp = apps.find(app => app.name === "vnctalk");
      if (talkApp) {
        this.talkUrl = talkApp.url.endsWith("/") ? talkApp.url : talkApp.url + "/";
        localStorage.setItem("talkURL", this.talkUrl);
      }
    });
  }

  isAudience(recipient) {
    return this.audiences.includes(recipient.target) || recipient.role === "audience";
  }
  imgLoadOnError() {
    this.imageData = null;
    this.changeDetectionRef.markForCheck();
  }

  private buildAvatarUrl() {
    const  avatarName = this.avatarRepo.buildTargetHash(this.data.conference.jid);
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg";
    return avUrl;
  }
  getTargetTitle(target: string): string {
    return this.contactRepo.getFullName(target);
  }

  ngOnInit(): void {
    this.store.select(getCMCBs).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      console.log("[getCMCBs]", res);
      this.mcbList = res;
      this.changeDetectionRef.markForCheck();
    });
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );

    this.mcbRepo.getTags().subscribe(tags => {
      this.tags = tags.map(v => v.name);
      console.log("[getTags]", tags);
      this.changeDetectionRef.markForCheck();
    });

    this.filteredTagOptions = this.searchTagControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filterTag(value))
    );
    this._setMinDate();
  }

  togglePassword() {
    this.passwordType = this.passwordType === "password" ? "text" : "password";
    this.changeDetectionRef.markForCheck();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.mcbList.filter(option => option.name.toLowerCase().includes(filterValue)
      && !this.selectedMCBs.find(v => v.id === option.id));
  }

  private _filterTag(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tags.filter(option => option.toLowerCase().includes(filterValue)
      && !this.selectedTags.find(v => v.toLowerCase() === filterValue));
  }

  selectMCB(mcb) {
    console.log("[selectMCB]", mcb);
    this.searchControl.patchValue("");
    if (!this.selectedMCBs.find(v => v.id === mcb.id)) {
      this.selectedMCBs.push(mcb);
      this.changeDetectionRef.markForCheck();
    }
  }

  removeParticipant(participant) {
    this.selectedParticipants = this.selectedParticipants.filter(v => v.target !== participant.target);
    this.changeDetectionRef.markForCheck();
  }

  removeMCB(mcb) {
    this.selectedMCBs = this.selectedMCBs.filter(v => v.id !== mcb.id);
    this.changeDetectionRef.markForCheck();
  }

  selectTag(option) {
    this.searchTagControl.patchValue("");
    console.log("[selectTag]", option);
    if (!this.selectedTags.find(v => v.toLowerCase() === option.toLowerCase())) {
      this.selectedTags.push(option);
      this.changeDetectionRef.markForCheck();
    }
  }

  removeTag(option) {
    this.selectedTags = this.selectedTags.filter(v => v.toLowerCase() !== option.toLowerCase());
    this.changeDetectionRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  private _setMinDate() {
    const now = moment();
    this.minDate = now.add(5, "minutes");
    this.changeDetectionRef.markForCheck();
  }

  private _setMaxDate() {
    const now = moment();
    this.maxDate = now.add(30, "days");
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.matDialogRef.close();
  }

  selectParticipants() {
    const options: any = {
      width: "540px",
      height: "580px",
    };
    this.matDialog.open(SelectParticipantsComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "select_participants",
        selectedParticipants: this.selectedParticipants
      },
      autoFocus: true
    }, options)).afterClosed().subscribe(res => {
      console.log("[UpdateAvatarComponent]", res);
      if (res) {
        this.selectedParticipants = res;
        this.changeDetectionRef.markForCheck();
      }
    });
  }

  uploadAvatar() {
    const options: any = {
      width: "340px",
      height: "410px",
    };
    this.matDialog.open(UpdateAvatarComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "update_avatar"
      },
      autoFocus: true
    }, options)).afterClosed().subscribe(res => {
      console.log("[UpdateAvatarComponent]", res);
      if (res && res.photo) {
        this.imageData = res.photo.data;
        this.changeDetectionRef.markForCheck();
      }
    });
  }

  resetStartDateTime() {
    this.dateControl.patchValue("");
  }

  createVideoMeetingGroup(): Observable<string> {
    const response = new Subject<string>();
    let groupName = this.meetingName.value;
    if (!groupName) {
      groupName = ConstantsUtil.DEFAULT_MEETING_NAME;
    }
    console.log("[createVideoMeetingGroup]", groupName);
    this.disabled = true;
    this.changeDetectionRef.markForCheck();
    this.createMeetingRoom(groupName).pipe(take(1)).subscribe(roomBare => {
      response.next(roomBare);
    });

    return response.asObservable();
  }

  startVideoMeeting() {
    const meetingName = this.meetingName.value;
    const description = this.description.value;
    const mcbList = this.selectedMCBs.map(v => v.id);
    const participants = this.selectedParticipants.map(v => v.target);
    let startTime = null;
    let endTime = null;
    if (this.dateControl.value) {
      const scheduleTime = new Date(this.dateControl.value).getTime();
      startTime = DateUtil.tsToISOString(scheduleTime);
      endTime = DateUtil.tsToISOString(scheduleTime + 3600000);
    }
    const tags = this.selectedTags;
    const avatar = this.imageData;
    this.isLoading = true;
    this.changeDetectionRef.markForCheck();
    console.log("[startVideoMeeting]", meetingName, description, mcbList, participants, startTime, tags, avatar);
    this.createVideoMeetingGroup().subscribe(roomBare => {
      console.log("[createVideoMeetingGroup] created", roomBare);
      const conferenceKey = roomBare;
      const jitsiRoomId = CommonUtil.randomId(10);
      this.mcbRepo.createJitsiRoom(conferenceKey, jitsiRoomId, this.jitsiURL).subscribe(res => {
        const attributes = {
          "jid": roomBare,
          "subject": meetingName,
          "description": description,
          "start_time": startTime,
          "end_time": endTime
        };
        const recipients = this.selectedParticipants.map(v => v.target);
        recipients.push(this.currentUser.email);
        this.jitsiRoomId = jitsiRoomId;
        const attendees = {};
        recipients.forEach((rJid, idx) => {
          attendees[`${idx}`] = {
            "jid": rJid,
            "invite_url": CommonUtil.getCallUrl(rJid, jitsiRoomId)
          };
        });
        this._joinAndConfigRoom(roomBare, () => {
          console.log("[StartConferenceComponent][_joinAndConfigRoom]", roomBare);
          setTimeout(() => {
            const data = {
              subject: meetingName,
              description: description,
              meta_conference_board_ids: mcbList,
              start_time: startTime,
              end_time: endTime,
              password: this.password.value,
              status: !!startTime ? "planned" : "active"
            };
            if (meetingName) {
              this.xmppService.setSubject(roomBare, meetingName);
            }
            if (avatar) {
              this.groupChatsService.updateGroupAvatar(roomBare, avatar.split(",")[1]).subscribe(res2 => {
                console.log("[StartConferenceComponent][startVideoMeeting] updateGroupAvatar", res2);
              });
            }
            if (tags) {
              this.mcbRepo.createOrUpdateTag(roomBare, tags).subscribe(res2 => {
                console.log("[createOrUpdateTag]", res2);
              });
            }
            this.groupChatsService.updateGroupInfo(roomBare, data)
              .subscribe(info => {
                console.log("[StartConferenceComponent][startVideoMeeting] updateGroupInfo res", info);
                if (this.dateControl.value) {
                  this.mcbRepo.scheduleMeeting(attributes, attendees).subscribe(res2 => {
                    this.toastService.show("SCHEDULED_NEW_MEETING");
                    console.log("[StartConferenceComponent][startVideoMeeting] scheduleMeeting res", res2);
                    this.matDialogRef.close();
                  });
                } else {
                  recipients.filter(v => v !== this.currentUser.email).forEach(jid => {
                    this.sendCallInvite(roomBare, jid);
                  });
                  this.toastService.show("STARTED_NEW_MEETING");
                  const url = this.generateLink(roomBare);
                  window.open(url, "_blank");
                  this.matDialogRef.close();
                }
              });
          }, 1000);
        });
      });
    });
  }

  private sendCallInvite(target: string, jid: string): any {
    console.log("[sendCallInvite]", jid);
    if (this.contactRepo.isExternalUser(jid)) {
      const callUrl = CommonUtil.getCallUrl(jid, this.jitsiRoomId);
      this.inviteCallByMail(jid, callUrl, this.password.value);
    } else {
      const invitationMessage = this.buildCallSignalMessage(target, jid);
      this.xmppService.sendMessage(jid, invitationMessage);
    }
  }

  generateLink(target) {
    const username = this.currentUser.email;
    const jwt = CommonUtil.signJWT(username, this.jitsiRoomId, target, this.selectedMCBs[0].id);
    const url = `${this.talkUrl}conference/mcb/${jwt}`;
    return url;
  }

  private inviteCallByMail(jid: string, callUrl: string, password?: string): void {
    let subject = "";
    this.translate.get("VNCTALK_VIDEO_CONFERENCE").pipe(take(1)).subscribe(text => {
      subject = text;
    });
    this.translate.get("INVITATION_MESSAGE", { link: callUrl }).pipe(take(1)).subscribe(text => {
      const body = {
        to: jid,
        body: text,
        subject: subject,
        password: password
      };
      console.error("[ConversationRepository][inviteCallByMail]", body);
      this.mcbRepo.sendEmail(body).subscribe((res: any) => {
        console.error("[ConversationRepository][inviteCallByMail]", res);
      }, err => {
        console.error("[ConversationRepository][inviteCallByMail]", err);
      });
    });
  }

  buildCallSignalMessage(target, jid) {
    const message: any = { type: "normal", body: "" };
    this.translate.get("INVITATION_MESSAGE", { link: CommonUtil.getCallUrl(jid, this.jitsiRoomId) })
    .pipe(take(1)).subscribe((body: string) => {
      message.body = body;
    });
    const vncTalkConference: any = {
      from: this.currentUser.email,
      to: jid,
      conferenceId: target,
      jitsiRoom: this.jitsiRoomId,
      jitsiURL: this.jitsiURL,
      conferenceType: "video",
      eventType: "invite"
    };
    message["vncTalkConference"] = vncTalkConference;
    message.timestamp = new Date().getTime();
    return message;
  }

  createMeetingRoom(name?: string): Observable<string> {
    console.log("[createMeetingRoom]", name);
    const response = new Subject<string>();
    this.xmppService.createMeetingRoom(name).subscribe(bare => {
      response.next(bare);
    }, error => {
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }

  configureRoom(target: string, config?: any): Observable<any> {
    const response = new Subject();
    this.xmppService.configureRoom(target, config).subscribe(res => {
      response.next(res);
    }, err => {
      response.error(err);
    });
    return response.asObservable();
  }

  setRoomAffiliation(room: string, jid: string, affiliation: string): Observable<any> {
    return this.groupChatsService.setRoomAffiliation(room, [jid], affiliation);
  }

  setRoomAffiliations(room: string, jids: string[], affiliation: string): Observable<any> {
    console.log("setRoomAffiliations", room, jids, affiliation);
    jids.forEach(jid => {
      this.xmppService.setRoomAffiliation(room, jid, affiliation).pipe(take(1)).subscribe(res => {
        console.log("[setRoomAffiliation] res", room, jid, affiliation, res);
      });
    });
    this.xmppService.getRoomMembers(room).pipe(take(1)).subscribe(res => {
      console.log("[getRoomMembers] res", res);
    });
    return this.groupChatsService.setRoomAffiliation(room, jids, affiliation);
  }

  private _joinAndConfigRoom(roomBare: string, callback?: any): void {
    this.xmppService.joinRoom(roomBare);
    this.configureRoom(roomBare, {
      persistent: 1,
      isPublic: 0,
      memberOnly: 0
    }).pipe(take(1)).subscribe(res => {
      const adminList = this.selectedParticipants.filter(v => v.role === "moderator").map(v => v.target);
      const audienceList = this.selectedParticipants.filter(v => v.role === "audience").map(v => v.target);
      if (adminList.length > 0) {
        this.setRoomAffiliations(roomBare, adminList, "admin").pipe(take(1)).subscribe(res2 => {
        });
      }
      if (audienceList.length > 0) {
        this.setRoomAffiliations(roomBare, audienceList, "audience").pipe(take(1)).subscribe(res2 => {
        });
      }
      const invitedParticipants = this.selectedParticipants.map(v => v.target);
      this.inviteToRoom(roomBare, invitedParticipants);
      if (typeof callback === "function") {
        callback();
      }
    });
  }

  inviteToRoom(target: string, newMembers: string[], reason?: string) {
    this.xmppService.invite(target, newMembers, reason);
  }

  selectTagOnEnter() {
    if (!this.searchTagControl.value) {
      this.searchTagControl.patchValue("");
      return;
    }
    if (!this.selectedTags.find(v => v.toLowerCase() === this.searchTagControl.value.toLowerCase())) {
      this.selectedTags.push(this.searchTagControl.value.toLowerCase());
      this.searchTagControl.patchValue("");
      this.changeDetectionRef.markForCheck();
    }
  }

  updateConference() {
    const meetingName = this.meetingName.value;
    const description = this.description.value;
    const mcbList = this.selectedMCBs.map(v => v.id);
    const participants = this.selectedParticipants.map(v => v.target);
    let startTime = null;
    let endTime = null;
    if (this.dateControl.value) {
      const scheduleTime = new Date(this.dateControl.value).getTime();
      startTime = DateUtil.tsToISOString(scheduleTime);
      endTime = DateUtil.tsToISOString(scheduleTime + 3600000);
    }
    const tags = this.selectedTags;
    const avatar = this.imageData;
    this.isLoading = true;
    this.changeDetectionRef.markForCheck();
    console.log("[startVideoMeeting]", meetingName, description, mcbList, participants, startTime, tags, avatar);
    const roomBare = this.data.conference.jid;
    const adminList = this.selectedParticipants.filter(v => v.role === "moderator").map(v => v.target);
    const audienceList = this.selectedParticipants.filter(v => v.role === "audience").map(v => v.target);
    const participantList = this.selectedParticipants.filter(v => v.role === "participant").map(v => v.target);
    if (adminList.length > 0) {
      this.setRoomAffiliations(roomBare, adminList, "admin").pipe(take(1)).subscribe(res2 => {
      });
    }
    if (audienceList.length > 0) {
      this.setRoomAffiliations(roomBare, audienceList, "audience").pipe(take(1)).subscribe(res2 => {
      });
    }
    if (participantList.length > 0) {
      this.setRoomAffiliations(roomBare, participantList, "member").pipe(take(1)).subscribe(res2 => {
      });
    }
    const oldMembers = [...this.data.members, ...this.data.admins];
    const deletedMembers = oldMembers.filter(v => !this.selectedParticipants.find(p => p.target === v));
    if (deletedMembers.length > 0) {
      this.setRoomAffiliations(roomBare, deletedMembers, "none").pipe(take(1)).subscribe(res2 => {
      });
    }
    const invitedParticipants = this.selectedParticipants.filter(v => !deletedMembers.includes(v.target)).map(v => v.target);
    this.inviteToRoom(roomBare, invitedParticipants);
    this.getRoomId().subscribe((jitsiRoomId: string) => {
        const attributes = {
          "jid": roomBare,
          "subject": meetingName,
          "description": description,
          "start_time": startTime,
          "end_time": endTime
        };
        const recipients = this.selectedParticipants.map(v => v.target);
        recipients.push(this.currentUser.email);
        this.jitsiRoomId = jitsiRoomId;
        const attendees = {};
        recipients.forEach((rJid, idx) => {
          attendees[`${idx}`] = {
            "jid": rJid,
            "invite_url": CommonUtil.getCallUrl(rJid, jitsiRoomId)
          };
        });
        setTimeout(() => {
          const data: any = {
            subject: meetingName,
            description: description,
            meta_conference_board_ids: mcbList,
            start_time: startTime,
            end_time: endTime,
            password: this.password.value,
            status: this.data.conference.status,
          };
          if (data.start_time && new Date(data.start_time).getTime() < new Date().getTime()) {
            delete data.start_time;
            delete data.end_time;
          }
          if (this.data && this.data.conference && this.data.conference.status !== "archived") {
            data.status = !!startTime ? "planned" : "active";
          }
          if (meetingName) {
            this.xmppService.setSubject(roomBare, meetingName);
          }
          if (avatar && !avatar.startsWith("http")) {
            this.groupChatsService.updateGroupAvatar(roomBare, avatar.split(",")[1]).subscribe(res2 => {
              console.log("[StartConferenceComponent][startVideoMeeting] updateGroupAvatar", res2);
            });
          }
          if (tags) {
            this.mcbRepo.createOrUpdateTag(roomBare, tags).subscribe(res2 => {
              console.log("[createOrUpdateTag]", res2);
            });
          }
          this.groupChatsService.updateGroupInfo(roomBare, data)
            .subscribe((info: any) => {
              console.log("[StartConferenceComponent][startVideoMeeting] updateGroupInfo res", info);
              this.matDialogRef.close(info.group_chat);
              if (info.error) {
                this.toastService.show(info.error);
                this.isLoading = false;
                this.changeDetectionRef.markForCheck();
              } else {
                this.toastService.show("UPDATED");
                if (this.dateControl.value) {
                  this.mcbRepo.scheduleMeeting(attributes, attendees).subscribe(res2 => {
                    // this.toastService.show("SCHEDULED_NEW_MEETING");
                    console.log("[StartConferenceComponent][startVideoMeeting] scheduleMeeting res", res2);
                  });
                } else {
                  // recipients.filter(v => v !== this.currentUser.email).forEach(jid => {
                  //   this.sendCallInvite(roomBare, jid);
                  // });
                  // this.toastService.show("STARTED_NEW_MEETING");
                  // const url = this.generateLink(roomBare);
                  // window.open(url, "_blank");
                }
              }
            });
        }, 100);
      });
  }

  getRoomId() {
    const subject = new Subject();
    this.mcbRepo.getJitsiRoom(this.data.conference.jid).subscribe(res => {
      const roomId = res.value;
      subject.next(roomId);
    }, (err) => {
      const jitsiRoomId = CommonUtil.randomId(10);
      const jitsiUrl = this.jitsiURL;
      this.mcbRepo.createJitsiRoom(this.data.conference.jid, jitsiRoomId, jitsiUrl).subscribe(res => {
        subject.next(jitsiRoomId);
      });
    });
    return subject.asObservable();
  }
}

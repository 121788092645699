import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { UserStatus } from "../shared/models";
import { InvitationConfig } from "app/shared/models/invitation-config";
import { ProfileResponse } from "app/shared/models/profile.response";
import { JID } from "app/shared/models/jid.model";
import { UserConfig } from "app/shared/models/user-config.model";
import { NetworkInformation } from "app/shared/models/network-information.model";
import { AppSettings } from "app/shared/models/app-settings.model";

export interface AppState {
  profile: ProfileResponse;
  loggedInUserLoading: boolean;
  loggedInUserLoaded: boolean;
  isLoggedIn: boolean;
  isXmppConnected: boolean;
  sessionId: string | null;
  jid: JID | null;
  bare: string;
  status: UserStatus;
  notifyOption: string;
  jitsiURL: string;
  landingUrl: string;
  baseApiUrl: string;
  firebaseToken?: string;
  isBootstrapped: boolean;
  isError: boolean;

  isOnline: boolean;
  isWindowFocused: boolean;
  appSettings: AppSettings;
  fontSize: number;
  globalMute: boolean;
  isDatabaseReady: boolean;
  networkInformation: NetworkInformation;
  domain: string;
  federatedApps: any[];
  selectedTab: string;
  disableLogout: boolean;
  userConfig: UserConfig;
  invitationConfig: InvitationConfig;
  behaviorTopBar: string;
  behaviorBottomBar: string;
  organizationId: number;
  activeConferences: any[];
  runningConferences: any;
  isSidebarExpanded: boolean;
}

export const initialState: AppState = {
  profile: null,
  loggedInUserLoading: false,
  loggedInUserLoaded: false,
  isXmppConnected: false,
  isLoggedIn: false,
  organizationId: null,
  sessionId: null,
  jid: null,
  status: UserStatus.ONLINE,
  bare: "",
  notifyOption: "2",
  landingUrl: "/talk",
  baseApiUrl: "",
  firebaseToken: null,
  isBootstrapped: false,
  isError: false,
  isDatabaseReady: false,
  jitsiURL: "",
  isOnline: true,
  isWindowFocused: true,
  appSettings: {
    lang: "en",
    receipts: true,
    informTyping: true,
    publicLastActivity: true,
    enableOperators: true,
    useEncryption: false,
    encryptionType: 0,
    vibration: true,
    ledNotification: true,
    enableSound: true,
    notificationSound: "0",
    silentHours: true,
    daysOptions: "EVERY_DAY",
    startTime: "00:00",
    endTime: "23:30",
    sendKey: "Enter",
    enableAutofocus: false,
    behaviorTopBar: "mouseover",
    behaviorBottomBar: "mouseover"
  },
  fontSize: null,
  globalMute: false,
  networkInformation: null,
  domain: null,
  disableLogout: false,
  federatedApps: [],
  selectedTab: "chat",
  userConfig: null,
  invitationConfig: null,
  behaviorTopBar: "mouseover",
  behaviorBottomBar: "mouseover",
  activeConferences: [],
  runningConferences: {},
  isSidebarExpanded: false,
};

export function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {
    case AppActionTypes.SET_LANDING_PAGE_URL: {
      return {
        ...state,
        landingUrl: action.payload
      };
    }
    case AppActionTypes.SET_ACTIVE_CONFERENCES: {
      return {
        ...state,
        activeConferences: action.payload
      };
    }
    case AppActionTypes.SET_AVAILABLE_APPS: {
      return {
        ...state,
        federatedApps: action.payload
      };
    }

    case AppActionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        selectedTab: action.payload
      };
    }

    case AppActionTypes.SET_DOMAIN: {
      return {
        ...state,
        domain: action.payload
      };
    }

    case AppActionTypes.UPDATE_LOGOUT_OPTION: {
      return {
        ...state,
        disableLogout: action.payload
      };
    }

    case AppActionTypes.SET_BASE_API_URL: {
      return {
        ...state,
        baseApiUrl: action.payload
      };
    }

    case AppActionTypes.APP_BOOTSTRAPPED: {
      return {
        ...state,
        isBootstrapped: true
      };
    }

    case AppActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loggedInUserLoading: true
      };
    }

    case AppActionTypes.NOTIFY_OPTION_CHANGE: {
      return {
        ...state,
        notifyOption: action.payload
      };
    }

    case AppActionTypes.SET_FIREBASE_TOKEN: {
      return {
        ...state,
        firebaseToken: action.payload
      };
    }


    case AppActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true
      };
    }

    case AppActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false
      };
    }

    case AppActionTypes.XMPP_SESSION: {
      return {
        ...state,
        jid: action.payload
      };
    }

    case AppActionTypes.SET_BARE: {
      return {
        ...state,
        bare: action.payload
      };
    }

    case AppActionTypes.STATUS_CHANGE: {
      return {
        ...state,
        status: action.payload
      };
    }

    case AppActionTypes.FETCH_ME_REQUEST: {
      return {
        ...state,
        isLoggedIn: true,
        loggedInUserLoading: true,
      };
    }

    case AppActionTypes.FETCH_ME_SUCCESS: {
      const response = action.payload as ProfileResponse;
      return {
        ...state,
        profile: response,
        isLoggedIn: true,
        jitsiURL: response.jitsiURL,
        bare: response.user.email,
        loggedInUserLoading: false,
        loggedInUserLoaded: true,
      };
    }

    case AppActionTypes.XMPP_CONNECTED: {
      const response = action.payload;
      return {
        ...state,
        isXmppConnected: action.payload
      };
    }

    case AppActionTypes.APP_ONLINE: {
      return {
        ...state,
        isOnline: action.payload
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.appState;
      return savedState ? {...state, ...savedState} : state;
    }


    case AppActionTypes.WINDOW_FOCUS_STATE: {

      if (state.isWindowFocused  ===  action.payload)
      {
        return state;
      }

      return {
        ...state,
        isWindowFocused: action.payload
      };
    }
    case AppActionTypes.SET_APP_SETTINGS: {
      return {
        ...state,
        appSettings: action.payload
      };
    }

    case AppActionTypes.SET_FONT_SIZE: {

      if (state.fontSize  ===  action.payload)
      {
        return state;
      }

      return {
        ...state,
        fontSize: action.payload
      };
    }

    case AppActionTypes.RESTORE_PROFILE_JID: {
      return {
        ...state,
        profile: action.payload.profile,
        jid: action.payload.jid,
      };
    }

    case AppActionTypes.DATABASE_READY: {
      return {
        ...state,
        isDatabaseReady: true
      };
    }

    case AppActionTypes.ENABLE_GLOBAL_MUTE: {
      return {
        ...state,
        globalMute: true
      };
    }

    case AppActionTypes.DISABLE_GLOBAL_MUTE: {
      return {
        ...state,
        globalMute: false
      };
    }

    case AppActionTypes.UPDATE_CONNECTION_INFORMATION: {
      return {
        ...state,
        networkInformation: {
          ...state.networkInformation,
          ...action.payload
        }
      };
    }

    case AppActionTypes.UPDATE_USER_CONFIG: {
      return {
        ...state,
        userConfig: action.payload
      };
    }

    case AppActionTypes.SET_INVITATION_CONFIG: {
      return {
        ...state,
        invitationConfig: action.payload
      };
    }
    case AppActionTypes.SET_ORG_ID: {
      return {
        ...state,
        organizationId: action.payload
      };
    }
    case AppActionTypes.SET_RUNNING_CONFERENCES: {
      return {
        ...state,
        runningConferences: action.payload
      };
    }
    case AppActionTypes.SET_SIDEBAR_VALUE: {
      return {
        ...state,
        isSidebarExpanded: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getSessionId = (state: AppState) => state.sessionId;
export const _getJID = (state: AppState) => state.jid;
export const _getBare = (state: AppState) => state.bare;

export const _getStatus = (state: AppState) => state.status;
export const _getNotifyOption = (state: AppState) => state.notifyOption;

export const _getUserProfile = (state: AppState) => state.profile;
export const _getIsLoggedInUserLoading = (state: AppState) => state.loggedInUserLoading;
export const _getIsLoggedInUserLoaded = (state: AppState) => state.loggedInUserLoaded;
export const _getIsLoggedIn = (state: AppState) => state.isLoggedIn;
export const _getIsConnectedXMPP = (state: AppState) => state.isXmppConnected;

export const _getLandingPageUrl = (state: AppState) => state.landingUrl;
export const _getBaseApiUrl = (state: AppState) => state.baseApiUrl;
export const _getFirebaseToken = (state: AppState) => state.firebaseToken;

export const _getIsAppBootstrapped = (state: AppState) => state.isBootstrapped;
export const _getIsAppOnline = (state: AppState) => state.isOnline;

export const _getIsWindowFocused = (state: AppState) => state.isWindowFocused;

export const _getAppSettings = (state: AppState) => state.appSettings;

export const _getFontSize = (state: AppState) => state.fontSize;
export const _getIsDatabaseReady = (state: AppState) => state.isDatabaseReady;
export const _getGlobalMute = (state: AppState) => state.globalMute;
export const _getNetworkInformation = (state: AppState) => state.networkInformation;
export const _getDomain = (state: AppState) => state.domain;
export const _getFederatedApps = (state: AppState) => state.federatedApps;
export const _getActiveTab = (state: AppState) => state.selectedTab;
export const _getLogoutOption = (state: AppState) => state.disableLogout;
export const _getUserConfig = (state: AppState) => state.userConfig;
export const _getInvitationConfig = (state: AppState) => state.invitationConfig;
export const _getOrganizationId = (state: AppState) => state.organizationId;
export const _getJitsiURL = (state: AppState) => state.jitsiURL;
export const _getActiveConferences = (state: AppState) => state.activeConferences;
export const _getRunningConferences = (state: AppState) => state.runningConferences;
export const _getIsSidebarExpanded = (state: AppState) => state.isSidebarExpanded;

import { Action } from "./index";
import { Group } from "app/shared/models/group.model";

export class GroupActionTypes {
  static GROUP_LOAD_REQUEST = "[GROUP] Load Request";
  static GROUP_LOAD_SUCCESS = "[GROUP] Load Success";

  static GROUP_ADD = "[GROUP] Add";
  static GROUP_DELETE = "[GROUP] Delete";
  static GROUP_UPDATE = "[GROUP] Update";
  static GROUP_BULK_ADD = "[GROUP] Bulk Add";
}

export class GroupLoadRequest implements Action {
  readonly type = GroupActionTypes.GROUP_LOAD_REQUEST;
}

export class GroupLoadSuccess implements Action {
  readonly type = GroupActionTypes.GROUP_LOAD_SUCCESS;

  constructor(public payload: any[]) {
  }
}

export class GroupAdd implements Action {
  readonly type = GroupActionTypes.GROUP_ADD;

  constructor(public payload: Group) {
  }
}

export class GroupUpdate implements Action {
  readonly type = GroupActionTypes.GROUP_UPDATE;

  constructor(public payload: Group) {
  }
}

export class GroupDelete implements Action {
  readonly type = GroupActionTypes.GROUP_DELETE;

  constructor(public payload: number) {
  }
}

export class GroupBulkAdd implements Action {
  readonly type = GroupActionTypes.GROUP_BULK_ADD;

  constructor(public payload: Group[]) {
  }
}

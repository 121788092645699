import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { mcbRootReducer } from "./reducers";
import { MetaConferenceBoardComponent } from "./meta-conference-board/meta-conference-board.component";
import { AuthGuard } from "app/shared/providers";
import { MetaConferenceBoardDetailComponent } from "./meta-conference-board-detail/meta-conference-board-detail.component";


const routes: Routes = [
  {
    path: "",
    component: MetaConferenceBoardComponent
  },
  {
    path: "search/:keyword",
    component: MetaConferenceBoardComponent
  },
  {
    path: "detail/:id",
    component: MetaConferenceBoardDetailComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    StoreModule.forFeature("mcb", mcbRootReducer),

  ],
  exports: [RouterModule]
})
export class MetaConferenceBoardRoutingModule { }

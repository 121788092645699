import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { RootState } from "../reducers";
import { Store } from "@ngrx/store";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store<RootState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            let errorMessage = "Unknown error!";
            if (err.error instanceof ErrorEvent) {
              // Client-side errors
              errorMessage = `Error: ${err.error.message}`;
            } else {
              // Server-side errors
              errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;
            }
            console.error("[ErrorInterceptor]", errorMessage);
            return throwError(err);
        }));
    }
}

import { Component, HostListener } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CommonUtil } from "app/utils/common.util";

@Component({
  templateUrl: "./service-desk-dialog.html"
})
export class ServiceDeskComponent {
  serviceDeskImage = CommonUtil.getFullUrl("/assets/help_desk.png");
  serviceDeskLink = "https://redmine.vnc.biz/helpdesk/incidents/new";
  constructor(
    private dialog: MatDialogRef<ServiceDeskComponent>
  ) {
  }
  @HostListener("document:keydown.esc")
  onEsc(): void {
    this.dialog.close();
  }

}

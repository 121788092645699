import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MetaConferenceBoardRoutingModule } from "./meta-conference-board-routing.module";
import { MetaConferenceBoardComponent } from "./meta-conference-board/meta-conference-board.component";
import { ConferenceTileComponent } from "./conference-tile/conference-tile.component";
import { StoreModule } from "@ngrx/store";
import { mcbRootReducer } from "./reducers";
import { ConferenceTileFilterComponent } from "./conference-tile-filter/conference-tile-filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "app/shared/shared.module";
import { MetaConferenceBoardDetailComponent } from "./meta-conference-board-detail/meta-conference-board-detail.component";
import { ConferencesListComponent } from "./conferences-list/conferences-list.component";
import { SidebarComponent } from "./sidebar/sidebar.component";

@NgModule({
  declarations: [
    ConferenceTileComponent,
    ConferenceTileFilterComponent,
    MetaConferenceBoardComponent,
    MetaConferenceBoardDetailComponent,
    ConferencesListComponent,
    ConferencesListComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    SharedModule,
    TranslateModule,
    StoreModule.forFeature("mcb", mcbRootReducer),
    MetaConferenceBoardRoutingModule
  ],
  exports: [
    MetaConferenceBoardComponent,
    MetaConferenceBoardDetailComponent
  ]
})
export class MetaConferenceBoardModule { }

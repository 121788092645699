import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    Inject
} from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppService } from "app/shared/services/app.service";
import { getUserJID, getIsAppOnline, getUserConfig, RootState, getAllConversations } from "app/reducers";
import * as _ from "lodash";
import { ToastService } from "app/shared/services/toast.service";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router, NavigationEnd } from "@angular/router";
import { ConfigService } from "app/config.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { takeUntil, debounceTime, switchMap, tap } from "rxjs/operators";
import { Conversation } from "app/meta-conference-board/models/conversation.model";
import { MCBRepository } from "app/repositories/mcb.repository";

@Component({
    selector: "vp-mcb-form-dialog",
    templateUrl: "mcb-form-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MCBDialogComponent implements OnDestroy, OnInit {
    searchControl: UntypedFormControl;
    description: UntypedFormControl;
    listName: UntypedFormControl;
    userJID: any;
    private isAlive$ = new Subject<boolean>();
    allConversations: Conversation[] = [];
    filteredConferences: Conversation[] = [];
    selectedConferences: Conversation[] = [];
    isLoading: boolean;
    constructor(
        private toastService: ToastService,
        private broadcaster: Broadcaster,
        private matDialogRef: MatDialogRef<MCBDialogComponent>,
        private store: Store<RootState>,
        private mcbRepo: MCBRepository,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectionRef: ChangeDetectorRef) {
        let name = "";
        let description = "";
        if (this.data.mcb) {
            name = this.data.mcb.name;
            description = this.data.mcb.description;
            this.selectedConferences = [...this.selectedConferences, ...this.data.mcb.conferences];
        }
        this.searchControl = new UntypedFormControl("", [Validators.required]);
        this.listName = new UntypedFormControl(name, [Validators.required]);
        this.description = new UntypedFormControl(description, []);

        this.searchControl.valueChanges.pipe(debounceTime(500), tap(v => {
            this.isLoading = true;
            this.filteredConferences = [];
            this.changeDetectionRef.markForCheck();
        }),
        switchMap(keyword => this.mcbRepo.searchGroups(keyword)), takeUntil(this.isAlive$),
        ).subscribe(res => {
            console.log(res);
            this.isLoading = false;
            const results = res ? res.map(v => {
                return {name: v.title, jid: v.muc};
            }) : [];
            this.filteredConferences = results.filter(v => !this.selectedConferences
                .find(c => c.jid === v.jid));
            this.changeDetectionRef.markForCheck();
        });

        // this.store.select(getAllConversations).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        //     this.allConversations = res.filter(v => v.jid);
        //     this.filterConferences();
        // });
        this.store.select(getUserJID).pipe(takeUntil(this.isAlive$)).subscribe(jid => {
            this.userJID = jid;
            this.changeDetectionRef.markForCheck();
        });

        setTimeout(() => {
            if (document.getElementById("listName") !== null) {
                document.getElementById("listName").focus();
            }
        }, 1000);
    }

    updateSearchResult() {
        this.filteredConferences = this.filteredConferences.filter(v => !this.selectedConferences
            .find(c => c.jid === v.jid));
        this.changeDetectionRef.markForCheck();
    }

    filterConferences(results?: any[]) {
        let filteredConferences = [];
        if (this.searchControl.value) {
            const keyword = this.searchControl.value.toLowerCase();
            filteredConferences = this.allConversations
            .filter(v => v.name && v.name.toLowerCase().includes(keyword) || v.jid.includes(keyword));
        } else {
            filteredConferences =  this.allConversations;
        }
        if (results) {
            filteredConferences = _.uniqBy(filteredConferences.concat(results), "jid");
            filteredConferences  = filteredConferences.filter(v => !this.selectedConferences
                .find(c => c.jid === v.jid));
        } else {
            this.filteredConferences = filteredConferences;
        }
        this.changeDetectionRef.markForCheck();
    }

    removeConference(conference) {
        this.selectedConferences = this.selectedConferences
            .filter(c => c.jid !== conference.jid);
        // this.filterConferences();
        this.updateSearchResult();
    }

    addConference(conference) {
        if (!this.selectedConferences
            .find(c => c.jid === conference.jid)) {
            this.selectedConferences.push(conference);
            this.updateSearchResult();
            // this.filterConferences();
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }


    @HostListener("window:resize", ["$event"])
    onResize(event) {
        window.scrollTo(0, 0);
    }

    @HostListener("document:keydown.esc")
    cancel(): void {
        this.matDialogRef.close();
    }

    create() {
        this.mcbRepo.createMCB({name: this.listName.value,
            description: this.description.value,
            conferences: this.selectedConferences.map(v => v.jid)}).subscribe((res: any) => {
                if (res.id) {
                    this.matDialogRef.close();
                } else {
                    this.toastService.show(res);
                }
            });
    }

    update() {
        const jids = this.data.mcb.conferences.map(v => v.jid);
        const selectedJids = this.selectedConferences.map(v => v.jid);
        const addConferences = selectedJids.filter(v => !jids.includes(v));
        const deleteConferences = jids.filter(v => !selectedJids.includes(v));
        this.mcbRepo.updateMCB({id: this.data.mcb.id, name: this.listName.value,
            description: this.description.value,
            deleteConferences: deleteConferences,
            addConferences: addConferences}).subscribe((res: any) => {
                if (res.id) {
                    this.matDialogRef.close();
                } else {
                    this.toastService.show(res);
                }
            });
    }
}

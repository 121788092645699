import { WindowFocusStatus, UpdateNetworkInformation } from "./../../actions/app";
import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import {
  LoginSuccess,
  LogoutSuccess,
  Logout,
  NotifyOptionChange,
  OnlineStatus,
  SetBaseApiUrl,
  SetFirebaseToken, SetFontSize
} from "../../actions/app";
import { Store } from "@ngrx/store";
import {
  getIsAppOnline,
  getIsConnectedXMPP,
  getUserJID,
  getUserProfile,
  RootState,
  getBaseApiUrl,
  getIsLoggedIn,
  IsDatabaseReady,
  getNetworkInformation
} from "../../reducers";
import { AuthService } from "./auth.service";
import "rxjs/add/observable/timer";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject, Observable, fromEvent, merge } from "rxjs";
import { ConfigService } from "app/config.service";
import { switchMap, filter, take, mapTo } from "rxjs/operators";
import { environment } from "environments/environment";
import { AppSettings } from "../models/app-settings.model";
import { CommonUtil } from "app/utils/common.util";
import { ConstantsUtil } from "app/utils/constants.util";
import { Broadcaster } from "./broadcaster.service";

@Injectable()
export class AppService {
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private _isOnline: boolean;
  private displayUnread: boolean;

  private _backButtonListener = new Subject<any>();
  isNetworkOnline: boolean;

  public get isAppOnline(): boolean {
    return this._isOnline;
  }

  constructor(
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private store: Store<RootState>,
    private router: Router,
    private configService: ConfigService) {
    this.pullServerURLFromStorage();
    this.checkInternetConnection();
    this.checkWindowFocused();

    this.store.select(getIsAppOnline).subscribe(online => this._isOnline = online);
    this.store.select(getNetworkInformation).subscribe(lastState => {
      if (!lastState) {
        return;
      }

      const isConnected = lastState.onlineState;
      console.log("[RootComponent][getNetworkInformation] isConnected", isConnected, navigator.onLine);
      // if network changed
      if (this.isNetworkOnline !== isConnected) {
        this.isNetworkOnline = isConnected;
        this.store.dispatch(new OnlineStatus(isConnected));
      }

      // edge case
      if (!isConnected && navigator.onLine) {
        this.store.dispatch(new UpdateNetworkInformation({
          onlineState: navigator.onLine,
          connectionType: (lastState && lastState.connectionType) || "unknown"
        }));
      }
  });
    // network changes
    merge(
      fromEvent(window, "online").pipe(mapTo(true)),
      fromEvent(window, "offline").pipe(mapTo(false))
    ).subscribe(this.notifyConnectionStatus.bind(this));
  }

  private notifyConnectionStatus(isConnected: boolean) {
    this.store.dispatch(new OnlineStatus(isConnected));

    let connectionType = "unknown";
    if (navigator.connection) {
      connectionType = navigator.connection.type ? navigator.connection.type : navigator.connection.effectiveType || "unknown";
    }
    console.log("[AppService][notifyConnectionStatus] isOnline: ", isConnected, connectionType, navigator.connection);
    //
    this.store.dispatch(new UpdateNetworkInformation({
      onlineState: isConnected,
      connectionType: connectionType
    }));
  }

  private checkInternetConnection() {
    this.notifyConnectionStatus(navigator.onLine);
  }

  public onLoggedIn(): Observable<boolean> {
    return this.store.select(getIsLoggedIn).map(v => !!v).filter(v => v);
  }


  changeServerUrl(url: string): Observable<boolean> {
    const response = new Subject<boolean>();

    this.authService.changeServerUrl(url).subscribe(data => {
      if (data.whiteboardURL && data.boshURL) {
        this.store.dispatch(new SetBaseApiUrl(url));
        localStorage.setItem(ConstantsUtil.KEY_SERVER_URL, url);
        response.next(true);
      } else {
        response.next(false);
      }
    }, error => {
      response.error(error);
    });


    return response.asObservable().pipe(take(1));
  }

  public sanitizeHTML(inputHTML: string): string {
    const escapedHTML = CommonUtil.escapeHTMLString(inputHTML);
    const sanitizedHTML = this.domSanitizer.sanitize(SecurityContext.HTML, escapedHTML);

    return this.parseLinkToHumanreadable(escapedHTML);
  }

  public sanitizeHTMLAndBypassSecurity(inputHTML: string): SafeHtml {
    const escapedHTML = CommonUtil.escapeHTMLString(inputHTML);
    const sanitizedHTML = this.domSanitizer.sanitize(SecurityContext.HTML, escapedHTML);

    const humanReadableHTML = this.parseLinkToHumanreadable(escapedHTML);

    return this.domSanitizer.bypassSecurityTrustHtml(humanReadableHTML);
  }

  public parseLinkToHumanreadable(text: string): string {
    text = this._addPrefixForMessageWithLink(text);
    if (text.startsWith("==foto:")) {
      this.translate.get(["PHOTO"])
        .subscribe(res => {
          text = "<div class='message-icon'> " + res.PHOTO + " </div>";
        });
    } else if (text.startsWith("==link:")) {
      text = "<div class='message-icon'> Link</div>";
    } else if (text.startsWith("==voicemessage:")) {
      this.translate.get(["VOICE_MESSAGE"])
        .subscribe(res => {
          text = "<div class='message-icon'> " + res.VOICE_MESSAGE + " </div>";
        });
    } else if (text.startsWith("==audio:")) {
      this.translate.get(["AUDIO"])
        .subscribe(res => {
          text = "<div class='message-icon'> " + res.AUDIO + " </div>";
        });
    }
    return text;
  }

  private _addPrefixForMessageWithLink(content): string {
    content = content.trim();
    const photoFormat = ["jpg", "jpeg", "png", "gif", "bmp"];
    const audioFormat = ["wav", "mp3", "wma", "ogg"];
    if (content.startsWith("http") || content.startsWith("https")) {
      const link = content.replace(/https?:\/\//ig, "").toLocaleLowerCase();
      content = "==link:" + content;
      if (link.split("/").length > 1) {
        const fileName = link.split("/").pop();
        if (fileName.split(".").length > 1) {
          const extension = fileName.split(".").pop();
          if (photoFormat.indexOf(extension) !== -1) {
            content = "==foto:" + content;
          } else if (audioFormat.indexOf(extension) !== -1) {
            if (fileName.startsWith("audio_recording_")) {
              content = "==voicemessage:" + content;
            } else {
              content = "==audio:" + content;
            }
          }
        }
      }
    }
    return content;
  }

  public login() {
    console.log("[app.service] login");
    this.store.select(getUserProfile).filter(v => !!v).pipe(take(1)).subscribe(profile => {
      console.log("[app.service] login", profile);
      this.store.dispatch(new LoginSuccess());
    });
  }

  public logout() {
    this.broadcaster.broadcast("USER_LOGGED_OUT", true);
    localStorage.removeItem(ConstantsUtil.LAST_SYNC_STAMP); // TODO: can remove it cause we call below   this.configService.clearStorage();

    // Web
    if (!this.isCordovaOrElectron) {
      this.dispatchLogoutEvents();
      this.configService.clearStorage();
      window.location.href = "/api/call-logout";
      return;
    } else {
      // Electron
      if (environment.isElectron) {
        this.configService.clearStorage();
        // Mobile
      } else {
        this.configService.clearStorage();

        // if (environment.shouldLogoutWithParam) {
        //   this.logoutWithParam();
        // }
      }

      this.removeAuthTokenFromPreferences();

      if (typeof window.FirebasePlugin !== "undefined") {
        window.FirebasePlugin.unregister();
      }

      this.redirectToMainPage();
      this.dispatchLogoutEvents();
    }
  }

  private logoutWithParam() {
    const serverURL = localStorage.getItem("serverURL");
    console.log("[AppService][logoutWithParam]", serverURL);

    const xhr = new XMLHttpRequest();
    xhr.open("GET", serverURL + "/?logout");
    xhr.withCredentials = true;
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onreadystatechange = () => {
      console.log("[AppService][logoutWithParam] resp", JSON.stringify(xhr.response), xhr.status, xhr.readyState);
    };
    xhr.send();
  }

  private redirectToMainPage() {
    console.log("[LOGOUT] redirecting to main page");
    const initialHref = environment.isCordova ? window.location.href.split("/www")[0] : window.location.href.split("/talk")[0];
    window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
  }

  private dispatchLogoutEvents() {
    this.store.dispatch(new Logout());
    this.store.dispatch(new LogoutSuccess());
  }

  private pullFirebaseTokenFromStorage() {
    const token = localStorage.getItem(ConstantsUtil.KEY_FIREBASE_TOKEN);

    if (token) {
      this.store.dispatch(new SetFirebaseToken(token));
    }
  }

  private pushFirebaseTokenToStore(token: string) {
    this.store.dispatch(new SetFirebaseToken(token));
    localStorage.setItem(ConstantsUtil.KEY_FIREBASE_TOKEN, token);
  }

  private pullServerURLFromStorage() {
    const url = localStorage.getItem(ConstantsUtil.KEY_SERVER_URL) || "";
    console.log("[app.service] pullServerURLFromStorage", url);
    this.store.dispatch(new SetBaseApiUrl(url));
  }

  public onXmppConnect(): Observable<boolean> {
    return this.store.select(getIsConnectedXMPP).filter(v => v);
  }

  public renderEmoji(input: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(wdtEmojiBundle.render(input));
  }

  public prepareConversationContent(content: string): SafeHtml {
    if (!content) {
      return "";
    }
    const repliedMessage = content.split("\n-------------\n");
    if (repliedMessage.length > 1) {
      content = repliedMessage[1];
    }
    return this.renderEmoji(this.sanitizeHTML(content.replace(/&amp;nbsp;/ig, " ").replace(/&lt;/ig, "<")
      .replace(/&gt;/ig, ">").replace(/&amp;lt;/ig, "&lt;").replace(/&amp;gt;/ig, "&gt;")
      .replace(/&quote;/g, "\"")
      .replace(/&trade;/g, "™")
      .replace(/&copy;/g, "©")
      .replace(/&amp;/ig, "&")
      .replace(/&amp;amp;quote;/g, "&quote;")
      .replace(/&amp;amp;trade;/g, "&trade;")
      .replace(/&amp;amp;copy;/g, "&copy;")
      .replace(/&amp;amp;/ig, "&amp;")
    ));
  }

  public getBackButtonListener() {
    return this._backButtonListener.asObservable();
  }


  public updateTitle() {
  }

  private checkWindowFocused() {
    merge(
      fromEvent(window, "focus").map(() => true),
      fromEvent(window, "blur").map(() => false))
      .subscribe((focused) => {
        // console.log("[AppService][checkWindowFocused] focused", focused);
        this.store.dispatch(new WindowFocusStatus(focused));
        if (focused) {
        }
      });
  }

  saveAuthTokenToPreferences(token) {
    if (environment.isCordova && !!plugins && plugins.appPreferences) {
      if (!token) {
        return;
      }
      plugins.appPreferences.store(() => {
        console.log("[AppService][saveAuthTokenToPreferences] success");
      }, () => {
        console.error("[AppService][saveAuthTokenToPreferences] failure");
      }, "auth-token", token);
    }
  }

  removeAuthTokenFromPreferences() {
    if (environment.isCordova && !!plugins && plugins.appPreferences) {
      plugins.appPreferences.remove(() => {
        console.log("[AppService][removeAuthTokenFromPreferences] success");
      }, () => {
        console.log("[AppService][removeAuthTokenFromPreferences] failure");
      }, "auth-token");
    }
  }

  saveApiUrlToPreferences() {
    if (environment.isCordova && !!plugins && plugins.appPreferences) {
      this.store.select(getBaseApiUrl).filter(url => !!url).pipe(take(1)).subscribe(base => {
        plugins.appPreferences.store(() => {
          console.log("[AppService][saveApiUrlToPreferences] success");
        }, () => {
          console.log("[AppService][saveApiUrlToPreferences] failure");
        }, "apiUrl", base + "/api");
      });
    }
  }

  removeApiUrlFromPreferences() {
    if (environment.isCordova && !!plugins && plugins.appPreferences) {
      plugins.appPreferences.remove(() => {
        console.log("[AppService][removeApiUrlFromPreferences] success");
      }, () => {
        console.log("[AppService][removeApiUrlFromPreferences] failure");
      }, "apiUrl");
    }
  }

  saveAvatarServiceUrlToPreferences() {
    if (environment.isCordova && !!plugins && plugins.appPreferences) {
      this.store.select(getBaseApiUrl).filter(url => !!url).pipe(take(1)).subscribe(base => {
        plugins.appPreferences.store(() => {
          console.log("[AppService][saveAvatarServiceUrlToPreferences] success");
        }, () => {
          console.log("[AppService][saveAvatarServiceUrlToPreferences] failure");
        }, "avatarServiceUrl", this.configService.avatarServiceUrl);
      });
    }
  }

  changeFontSize(fontSize) {
    const previousValue = localStorage.getItem(ConstantsUtil.FONT_SIZE) || "14";
    const currentValue = fontSize;

    if (previousValue === currentValue) {
      return;
    }

    localStorage.setItem(ConstantsUtil.FONT_SIZE, currentValue);
    this.store.dispatch(new SetFontSize(parseInt(currentValue)));
    document.getElementById("main-html").classList.add("font-size-" + currentValue);
    document.getElementById("main-html").classList.remove("font-size-" + previousValue);
  }

  changeTheme(theme) {
    const previousTheme = localStorage.getItem(ConstantsUtil.THEME) || environment.theme;
    const currentTheme = theme;

    if (previousTheme === currentTheme) {
      return;
    }

    localStorage.setItem(ConstantsUtil.THEME, currentTheme);
    if (environment.isCordova) {
      const initialHref = window.location.href.split("/www")[0];
      window.location.href = `${initialHref}${environment.isCordova ? "/www" : ""}/index.html`;
    } else if (environment.isElectron) {
      const initialHref = window.location.href.split("/talk")[0];
      window.location.href = `${initialHref}/index.html`;
    } else {
      self.location.reload();
    }
  }


  storePendingMarkReadRequests(requests) {
    localStorage.setItem("read_requests", JSON.stringify(requests));
  }

  storePendingMarkPadReadRequests(requests) {
    localStorage.setItem("pad_read_requests", JSON.stringify(requests));
  }

  getPendingMarkReadRequests() {
    let userJid;
    let response = {};
    this.store.select(getUserJID).subscribe(jid => userJid = jid);

    if (userJid && localStorage.getItem("read_requests")) {
      try {
        response = JSON.parse(localStorage.getItem("read_requests" || "{}"));
      }
      catch (e) {
        response = {};
      }
    }
    else {
      this.storePendingMarkReadRequests({});
    }

    return response;
  }

  getPendingMarkPadReadRequests() {
    let userJid;
    let response = {};
    this.store.select(getUserJID).subscribe(jid => userJid = jid);

    if (userJid && localStorage.getItem("pad_read_requests")) {
      try {
        response = JSON.parse(localStorage.getItem("pad_read_requests" || "{}"));
      }
      catch (e) {
        response = {};
      }
    }
    else {
      this.storePendingMarkPadReadRequests({});
    }

    return response;
  }
}

import {
    Component,
    HostListener,
    OnInit,
    Inject
} from "@angular/core";
import { Subject } from "rxjs";
import * as _ from "lodash";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-confirmation-dialog",
    templateUrl: "confirmation-dialog.component.html"
})
export class ConfirmationDialogComponent implements OnInit {
    userJID: any;
    constructor(
        private matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        window.scrollTo(0, 0);
    }

    @HostListener("document:keydown.esc")
    cancel(): void {
        this.matDialogRef.close();
    }

    ok() {
        this.matDialogRef.close({ action: this.data.action });
    }
}

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs/Observable";
import * as _ from "lodash";
import { CommonUtil } from "../utils/common.util";
import { BehaviorSubject, of, Subject, forkJoin } from "rxjs";
import { GroupLoadRequest, GroupLoadSuccess, GroupBulkAdd, GroupAdd, GroupUpdate, GroupDelete } from "app/actions/group";
import { UserStatus } from "app/shared/models";
import { JID, SearchUser } from "app/shared/models/jid.model";
import {
  RootState, getIsConnectedXMPP, getNetworkInformation, getIsContactLoading,
  getIsContactLoaded, getUserJID, getContacts, getContactPhotoById, getContactVCardById,
  getContactById, getUserProfile, getContactsByIds, getMembersByGroupId, getIsGroupLoading, getGroups
} from "app/reducers";
import { AppService } from "app/shared/services/app.service";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { GroupChatsService } from "app/shared/services/groupchat.service";
import { ConfigService } from "app/config.service";
import {
  ContactBulkUpdate, ContactLoadRequest, ContactBulkAdd, ContactStatusUpdate,
  ContactBulkStatusUpdate, ContactAddVCard, RemoveGroupFromContacts, AddGroupToContacts,
  RemoveGroupFromContact, ContactAdd
} from "app/actions/contact";
import { environment } from "environments/environment.dev";
import { ContactRest } from "app/shared/models/contact-rest.model";
import { Group } from "app/shared/models/group.model";
import { Contact } from "app/shared/models/contact.model";
import { Conversation, Recipient } from "app/meta-conference-board/models/conversation.model";
import { ContactsService } from "app/shared/services/contacts.service";
import { bufferTime, distinctUntilChanged, take, concatMap, takeWhile, reduce, switchMap } from "rxjs/operators";
import { ContactInformation } from "app/shared/models/vcard.model";
import { Photo } from "app/shared/models/photo.model";
import { ConversationUtil } from "app/utils/conversation.util";

@Injectable()
export class ContactRepository {

  private contactsLoading = false;
  private contactsLoaded = false;

  private isXmppConnected = false;
  private isNetOnline = false;

  public userJID: JID;
  public contactSubscription$ = {};
  public photoSubscription$ = {};
  public statusSubscription$ = {};
  creatingList: any = {};
  private updateContactsFromLdap$ = new Subject<any>();
  updateContactsStore$ = new Subject<any>();
  isGroupManageEnabled = false;

  needToSyncContactsAndGroups = false;
  domain = "";

  constructor(private store: Store<RootState>,
    private contactService: ContactsService,
    private appService: AppService,
    private broadcaster: Broadcaster,
    private groupChatsService: GroupChatsService,
    private configService: ConfigService) {
      this.initData();
  }

  initData() {
    this.store.select(getIsConnectedXMPP).subscribe(v => this.isXmppConnected = v);

    this.broadcaster.on<boolean>("USER_LOGGED_OUT")
      .subscribe(data => {
        console.log("[ContactRepository] USER_LOGGED_OUT");
        this.cleanLastContactsFetchTimeStamp();
      });

    this.updateContactsStore$.pipe(bufferTime(2000)).filter(v => v.length > 0).subscribe(contacts => {
      const updatedContacts = _.uniqBy(contacts, "bare");
      console.log("[ContactRepository][updateContactsStore]", updatedContacts);
      this.store.dispatch(new ContactBulkUpdate(updatedContacts));
    });

    this.configService.getLoadedConfig().subscribe(v => {
      if (this.isGroupManageEnabled !== this.configService.get("groupManagementViaDirectory")) {
        this.isGroupManageEnabled = this.configService.get("groupManagementViaDirectory");
      }
    });

    this.store.select(getNetworkInformation).pipe(distinctUntilChanged()).subscribe(information => {
      console.log("[ContactRepository][getNetworkInformation]", this.isNetOnline);

      this.isNetOnline = information && information.onlineState;
    });

    this.loadContacts();

    this.store.select(getIsContactLoading).subscribe(v => {
      this.contactsLoading = v;
      console.log("[ContactRepository][getIsContactLoading] v");
    });

    this.store.select(getIsContactLoaded).subscribe(v => {
      this.contactsLoaded = v;
      console.log("[ContactRepository][getIsContactLoaded] v");
    });

    this.store.select(getUserProfile).filter(v => !!v).subscribe(v => {
      this.userJID = v.user.email;
      this.domain = v.domain;
    });

    document.addEventListener("resume", () => {
      console.log("[ContactRepository][resume]");

      if (this.needToSyncContactsAndGroups) {
        setTimeout(() => {
          this.loadGroups();
          this.loadContacts();
        }, 5000);

        this.needToSyncContactsAndGroups = false;
      }
    });

    // for web: retrieve last activity of all contacts every 5 mins
    if (!environment.isCordova) {
      Observable.timer(0, this.configService.LAST_ACTIVITY_BATCH_INTERVAL).subscribe(() => {
        this.getContacts()[0].pipe(take(1)).subscribe(contacts => {
          console.log("[ContactRepository][constructor] contacts", contacts.length);
          this.syncLastActivityOfAllContacts(contacts);
        });
      });
    }
  }

  isExternalUser(target: string): boolean {
    return target.indexOf(`@${this.domain}`) === -1;
  }

  private loadContacts() {
    console.log("[ContactRepository][loadContacts]");
    this.store.dispatch(new ContactLoadRequest());
    this.loadUpdatedContacts().subscribe(contacts => {
      contacts = _.sortBy(contacts, ["name"], ["asc"]);
      console.log("[ContactRepository][loadAllContacts] contacts", contacts.length, contacts);
      this.store.dispatch(new ContactBulkAdd(contacts));
    });
  }

  private loadGroups() {
    console.log("[ContactRepository][loadGroups]");
    this.store.dispatch(new GroupLoadRequest());
    this.loadAllGroups().subscribe(groups => {
      groups = _.sortBy(groups, ["name"], ["asc"]);
      console.log("[ContactRepository][loadGroups] groups", groups);
      this.store.dispatch(new GroupLoadSuccess(groups));
      this.store.dispatch(new GroupBulkAdd(groups));
    });
  }

  private loadUpdatedContacts(): Observable<any[]> {
    // console.log("[ContactRepository][loadUpdatedContacts]");

    const trigger = new BehaviorSubject<number>(0);
    return trigger.asObservable().pipe(concatMap(offset => {

      // updated_after logic (only for mobile)
      const lastContactsFetchTimeStamp = this.lastContactsFetchTimeStamp();
      const params = { offset };
      params["limit"] = this.configService.CONTACTS_REST_PER_PAGE;
      if (lastContactsFetchTimeStamp) {
        params["updated_after"] = lastContactsFetchTimeStamp;
      }

      console.log("[ContactRepository][loadUpdatedContacts] params", JSON.stringify(params));

      return this.getContactsFromAPI(params)
        .map(res => {
          console.log("[loadCOntactsFromAPI] res: ", res);
          const contacts = res.contacts.map(v => this.processAPIContact(v));

          offset = offset + this.configService.CONTACTS_REST_PER_PAGE;
          if (res.total_count > offset) {
            trigger.next(offset);
          } else {
            // save updated_after
            this.setLastContactsFetchTimeStamp();

            trigger.complete();
          }
          return contacts;
        });
    }
    ), takeWhile(contacts => contacts.length > 0)
      , reduce((accumulator, contacts) => {
        return [...accumulator, ...contacts];
      }, []));
  }

  private lastContactsFetchTimeStamp() {
    const date = localStorage.getItem("lastContactsFetchTimeStamp");
    // console.log("[ContactRepository][lastContactsFetchTimeStamp]", date);
    return date;
  }

  private setLastContactsFetchTimeStamp() {
    const date = new Date().toISOString();
    // console.log("[ContactRepository][setLastContactsFetchTimeStamp]", date);
    localStorage.setItem("lastContactsFetchTimeStamp", date);
  }

  private cleanLastContactsFetchTimeStamp() {
    // console.log("[ContactRepository][cleanLastContactsFetchTimeStamp]");
    localStorage.removeItem("lastContactsFetchTimeStamp");
  }

  private loadAllGroups(): Observable<Group[]> {
    const trigger = new BehaviorSubject<number>(0);
    return trigger.asObservable().pipe(concatMap(offset =>
      this.contactService.getGroups({ offset })
        .map(res => {
          const groups = res.contact_groups.map(v => v as Group);
          if (res.total_count > 25) {
            offset = offset + 25;
            trigger.next(offset);
          }
          else {
            trigger.complete();
          }
          return groups;
        })
    ), takeWhile(groups => groups.length > 0)
      , reduce((accumulator, groups) => {
        return [...accumulator, ...groups];
      }, []));
  }

  public getLastActivity(bare: string): Observable<any> {
    const response = new Subject<any>();
    return response.asObservable().pipe(take(1));
  }

  private getLastActivityBatch(bareJids: string[]): Observable<any> {
    console.log("[ContactRepository][getLastActivityBatch]", bareJids.length);

    const response = new Subject<any>();
    return response.asObservable().pipe(take(1));
  }

  syncLastActivityOfAllContacts(contacts) {
    console.log("[ContactRepository][syncLastActivityOfAllContacts] " + contacts.length);

    if (contacts.length === 0) {
      return;
    }

    this.appService.onXmppConnect().pipe(take(1)).subscribe(() => {
      const contactsBareJids: string[] = contacts.map(c => c.bare);
      this.getLastActivityBatch(contactsBareJids);
    });
  }

  updateContactStatus(jid: string, timestamp: number) {
    const lastActivitySeconds = Math.abs(new Date().getTime() - timestamp) / 1000;
    // console.log("[updateContactStatus]", jid, timestamp, lastActivitySeconds);
    this.store.dispatch(new ContactBulkStatusUpdate([this.contactStatusUpdateData(jid, lastActivitySeconds)]));
  }

  private contactStatusUpdateData(jid: string, lastActivitySeconds: number) {
    return {
      jid: jid,
      status: (lastActivitySeconds > -1 && lastActivitySeconds < this.configService.LAST_ACTIVITY_ONLINE_THRESHHOLD)
        ? UserStatus.ONLINE : UserStatus.OFFLINE
    };
  }

  public getContacts(): [Observable<Contact[]>, Observable<boolean>] {
    const loading$ = this.store.select(getIsContactLoading);

    return [
      this.store.select(getContacts),
      loading$
    ];
  }

  public getGroups(): [Observable<Group[]>, Observable<boolean>] {
    const loading$ = this.store.select(getIsGroupLoading);
    return [
      this.store.select(getGroups),
      loading$
    ];
  }

  public getTargetPhoto(bareId: string): Observable<Photo> {
    return this.store.select(state => getContactPhotoById(state, bareId));
  }

  public getContactVCard(bareId: string): Observable<ContactInformation> {
    return this.store.select(state => getContactVCardById(state, bareId));
  }

  public getContactCompany(bareId: string): Observable<string> {
    return this.store.select(state => getContactVCardById(state, bareId)).map(res => {
      if (res && res.organization) {
        return res.organization.name;
      }
      return "";
    });
  }

  public searchUsers(keyword: string): Observable<SearchUser[]> {

    // search locally first
    const rosterContacts$ = this.store.select(getContacts)
      .map(contacts => {
        if (!keyword) {
          return [];
        }

        return contacts
          .filter(contact => (contact.name && contact.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
            || contact.bare.toLowerCase().startsWith(keyword.toLowerCase()))
          .map(contact => {
            const name = contact.name || contact.local;
            return { name: name, email: contact.bare, ldapData: {} };
          });
      }).map(contacts => {
        return contacts;
      }).pipe(take(1));


    if (this.isNetOnline) {
      return forkJoin([rosterContacts$, this.searchLDAPUsers(keyword)]).map(contacts => {
        let rosterContacts = contacts[0];
        console.log("[rosterContacts]", rosterContacts);
        const ldapContacts = contacts[1];
        const ldapEmail = ldapContacts.map(c => c.email);
        rosterContacts = rosterContacts.filter(c => ldapEmail.indexOf(c.email) === -1);
        const allContacts = _.uniqBy([...rosterContacts, ...ldapContacts], user => user.email);
        return allContacts;
      });
    } else {
      return rosterContacts$;
    }
  }

  public searchLDAPUsers(keyword: string): Observable<SearchUser[]> {
    return this.contactService.searchLDAPUsers(keyword).pipe(take(1));
  }

  public retrieveLDAPUsers(jids: string[]): Observable<SearchUser[]> {
    return this.contactService.retrieveLDAPUsers(jids).pipe(take(1));
  }

  public getContactById(bare: string): Observable<Contact> {
    return this.store.select(state => getContactById(state, bare));
  }

  public renderMentionUsers(content: string, type: string = "html", references?: any[],
    highlightedBare?: string, addHightLight?: boolean): string {
    let mentions = CommonUtil.parseMentions(content);
    if (!!references) {
      const members = references.filter(v => !!v).map(u => {
        if (u.uri) {
          return u.uri.replace(/xmpp:+/ig, "");
        }
        return u.replace(/xmpp:+/ig, "");
      });
      mentions = mentions.filter(v => members.includes(v));
    }

    if (mentions.length > 0) {
      mentions.forEach(mention => {
        const query = new RegExp(`(@${mention})\s*`, "gim");
        const isHighlighted = highlightedBare && highlightedBare === mention;
        if (this.userJID && this.userJID.bare === mention) {
          this.store.select(getUserProfile).filter(p => !!p).pipe(take(1)).subscribe(profile => {
            const name = profile.user.fullName;
            content = CommonUtil.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
          });

        } else {
          this.getContactById(mention).pipe(take(1)).subscribe(contact => {
            if (!!contact) {
              const name = contact.name || contact.local;
              content = CommonUtil.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
            } else {
              const name = mention.split("@")[0];
              content = CommonUtil.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
            }
          });
        }
      });
    }
    return content;
  }

  public getContactsByIds(bare: string[]): Observable<Contact[]> {
    return this.store.select(state => getContactsByIds(state, bare));
  }

  public getPrettyUsername(bare: string) {
    if (!bare) {
      return null;
    }
    let username = bare.split("@")[0]; // get hid local part
    username = username.replace(".", " "); // replace dot with space
    username = username.replace(/\b\w/g, word => word.toUpperCase()); // capitalise each word

    return username;
  }

  public getFullName(bare: string, returnYouForLoggedInUser: boolean = false) {
    if (!bare) {
      return "";
    }

    let loggedInJID: JID;
    this.store.select(getUserJID).pipe(take(1)).subscribe(j => loggedInJID = j);

    if (loggedInJID && (bare === loggedInJID.bare) && returnYouForLoggedInUser) {
      return "You";
    }

    let contact: any;
    this.getContactById(bare).pipe(take(1)).subscribe(c => {
      contact = c;
    });

    if (contact) {
      return contact.name || contact.local;
    }

    return bare.split("@")[0];
  }

  public uploadGroupAvatar(target: string, photo: any): Observable<any> {
    console.log("[ContactRepository][uploadGroupAvatar] target", target);
    return this.groupChatsService.updateGroupAvatar(target, photo).map(res => {
      return res;
    });
  }

  public removeGroupFromContacts(groupId: number, contacts: Contact[]): void {
    // contacts =  contacts.map((contact) => {
    //   contact.groups =  contact.groups.filter(group => group.id !== groupId);
    //   return contact;
    // });
    // this.store.dispatch(new RemoveGroupFromContacts({contacts, groupId}));
  }

  public addGroupToContact(group: Group, contact: Contact): void {
    this.store.select(state => getMembersByGroupId(state, group.id)).pipe(take(1)).subscribe(contacts => {
      const ids = contacts.map(c => c.id);
      ids.push(contact.id);
      this.contactService.updateGroupContacts(group.id, ids).subscribe(res => {
        this.store.dispatch(new AddGroupToContacts({ contacts: [contact], group: group }));
      });
    });
  }

  public updateGroupContacts(groupId: number, ids: number[]): Observable<any> {
    return this.contactService.updateGroupContacts(groupId, ids);
  }

  private processAPIContact(rawContact: any): ContactRest {
    const contact: ContactRest = {
      id: rawContact.id,
      created_at: new Date(rawContact.created_at),
      updated_at: new Date(rawContact.updated_at),
      is_company: rawContact.is_company,
      first_name: rawContact.first_name,
      middle_name: rawContact.middle_name,
      last_name: rawContact.last_name,
      company: rawContact.company,
      job_title: rawContact.job_title,
      bare: "",
      domain: "",
      full: "",
    };

    const name = [];
    if (rawContact.first_name) {
      name.push(rawContact.first_name);
    }
    if (rawContact.deleted_at) {
      name.push(new Date(rawContact.deleted_at));
    }
    if (rawContact.middle_name) {
      name.push(rawContact.middle_name);
    }
    if (rawContact.last_name) {
      name.push(rawContact.last_name);
    }

    contact.name = name.join(" ");

    if (rawContact.phones) {
      contact.phones = rawContact.phones;
    }
    if (rawContact.jid) {
      const emails = [];
      emails.push(rawContact.jid);
      contact.bare = rawContact.jid;
      contact.full = rawContact.jid;
      contact.domain = rawContact.jid.split("@")[1];
      if (rawContact.emails) {
        contact.emails = emails.concat(rawContact.emails);
      } else {
        contact.emails = emails;
      }
    } else {
      if (rawContact.emails) {
        contact.emails = rawContact.emails;
        contact.bare = rawContact.emails[0].email;
        contact.full = rawContact.emails[0].email;
        contact.domain = rawContact.emails[0].email.split("@")[1];
      }
    }
    if (rawContact.addresses) {
      contact.addresses = rawContact.addresses;
    }

    if (contact.bare && contact.name.trim() === contact.bare.split("@")[0]) {
      contact.name = CommonUtil.beautifyName(contact.name.trim());
    }

    let oldGroups = rawContact.groups || [];
    let isGeneralAdded = false;

    if (!oldGroups || oldGroups.length === 0) {
      oldGroups = [{ id: 0 }];
      isGeneralAdded = true;
    }

    return {
      ...contact,
      groups: oldGroups,
      isGeneralAdded: isGeneralAdded
    };
  }

  public getUserProfile() {
    return this.store.select(getUserProfile);
  }

  private getContactsFromAPI(params: any): Observable<any> {
    return this.contactService.getContacts(params);
  }

  removeContactFromGroup(contact: Contact, groupId: number) {
    this.store.select(state => getMembersByGroupId(state, groupId)).pipe(take(1)).subscribe(contacts => {
      const ids = contacts.filter(c => c.id !== contact.id).map(c => c.id);
      this.contactService.updateGroupContacts(groupId, ids).subscribe(res => {
        console.log("[updateGroupContacts]", groupId, res);
        this.store.dispatch(new RemoveGroupFromContact({ contact, groupId }));
      });
    });
  }

  renameGroup(groupId: number, groupName: string): Observable<any> {
    return this.contactService.updateGroupName(groupId, groupName).map(res => {
      console.log("[updateGroupName]", groupId, res);
      this.store.dispatch(new GroupUpdate(res.contact_group as Group));
      return res;
    });
  }

  public isContactFavorite(bareId: string): Observable<boolean> {
    return this.getContactById(bareId).pipe(take(1)).map((contact) => {
      //  return contact && contact.groups.filter(g => g.name === ConstantsUtil.FAVOURITE_LIST_NAME).length > 0;
      // console.log("ContactRepository isContactFavorite processing: ", contact);
      return false;
    });
  }

  public searchContacts(value: string): Observable<Recipient[]> {
    let userJID;
    this.store.select(getUserJID).filter(v => !!v).subscribe(jid => {
      userJID = jid;
    });
    return this.searchUsers(value).pipe(take(1)).map(users => {
      const contacts = users.filter(u => !userJID || userJID && u.email !== userJID.bare).map(u => {
        const recipient: Recipient = {
          target: u.email, title: u.name, type: "user", ldapData: u.ldapData,
          isExternalUser: this.isExternalUser(u.email)
        };
        if (environment.theme === "hin") {
          if (u.ldapData && Object.keys(u.ldapData).length > 0) {
            const additionalInfo = [];
            const title = [];
            if (u.ldapData.company) {
              additionalInfo.push(u.ldapData.company[0]);
            }
            if (u.ldapData.l) {
              additionalInfo.push(u.ldapData.l[0]);
            }
            if (u.ldapData.title) {
              title.push(u.ldapData.title[0]);
            }
            if (u.ldapData.givenName) {
              title.push(u.ldapData.givenName[0]);
            }
            if (u.ldapData.sn) {
              title.push(u.ldapData.sn[0]);
            }
            if (additionalInfo.length > 0) {
              recipient.additionalInfo = additionalInfo.join(", ");
            }
            if (title.length > 0) {
              recipient.title = title.join(" ");
            }
            console.log("[contact-list.component] searchUsers hin receipient", recipient);
          } else {
            this.getContactCompany(u.email).pipe(take(1)).subscribe(companyName => {
              console.log("[contact-list.component] searchUsers hin", companyName);
              if (companyName) {
                recipient.additionalInfo = companyName;
              }
            });
          }
        } else if (recipient.isExternalUser) {
          if (u.ldapData && !!u.ldapData.company) {
            recipient.companyName = u.ldapData.company[0];
          } else {
            this.getContactCompany(u.email).pipe(take(1)).subscribe(companyName => {
              recipient.companyName = companyName;
            });
          }
        }
        recipient.title = CommonUtil.highlightSearch(recipient.title, value);
        return recipient;
      });
      return contacts;
    });
  }

  deleteAvatar(target: string) {
    return this.contactService.deleteAvatar(target);
  }

}

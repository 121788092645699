import { Action } from "../../actions/index";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Conference, MetaConferenceBoard } from "../models/mcb.model";
import { MCBActionTypes } from "../actions/mcb";

export interface MetaConferenceBoardState extends EntityState<MetaConferenceBoard> {
  isLoading: boolean;
  isLoaded: boolean;
  activeBoard: MetaConferenceBoard;
  activeConference: Conference;
}

export const mcbAdapter: EntityAdapter<MetaConferenceBoard> = createEntityAdapter<MetaConferenceBoard>({
  selectId: (metaConferenceBoard: MetaConferenceBoard) => metaConferenceBoard.id,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(board1: MetaConferenceBoard, board2: MetaConferenceBoard): number {
  return board2.id - board1.id;
}
const initialState: MetaConferenceBoardState = mcbAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  activeBoard: null,
  activeConference: null
});

export function mcbReducer(state: MetaConferenceBoardState = initialState, action: Action): MetaConferenceBoardState {
  switch (action.type) {
    case MCBActionTypes.MCB_ADD: {
      const newState = mcbAdapter.addOne(action.payload, state);
      return mcbAdapter.updateOne({ id: action.payload.id, changes: action.payload }, newState);
    }
    case MCBActionTypes.MCB_UPDATE: {
      return {
        ...mcbAdapter.updateOne({
          id: action.payload.id, changes: action.payload.changes
        }, state),
      };
    }
    case MCBActionTypes.MCB_DELETE: {
      return mcbAdapter.removeOne(action.payload, state);
    }
    case MCBActionTypes.SET_ACTIVE_MCB: {
      return {
        ...state,
        activeBoard: action.payload
      };
    }
    case MCBActionTypes.MULTI_MCB_ADD: {
      const data = action.payload;
      let localState = {...state};
      data.forEach(item => {
        localState = mcbAdapter.addOne(item, localState);
        localState = mcbAdapter.updateOne({ id: item.id, changes: item }, localState);
      });
      return localState;
    }
    default:
      return state;
  }
}

export const _getActiveBoard = (state: MetaConferenceBoardState) => state.activeBoard;
export const _getActiveBoardConversations = (state: MetaConferenceBoardState, activeBoard: MetaConferenceBoard) => {
  return activeBoard ? activeBoard.conferences : [];
};

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, NgZone, ElementRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs/Subject";
import { CommonUtil } from "../../../utils/common.util";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { ContactUpdatePhotoLastUpdate } from "app/actions/contact";
import { filter, take, distinctUntilChanged } from "rxjs/operators";
import { environment } from "environments/environment";
import { getContactStatusById, getLastPhotoUpdate } from "app/reducers";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { ContactRepository } from "app/repositories/contact.repository";
import { AvatarRepository } from "app/repositories/avatar.repository";

@Component({
  selector: "vp-avatar",
  templateUrl: "./avatar.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnDestroy, OnInit {
  avatarUrl: any;
  status: string;
  isHinTheme = environment.theme === "hin";
  @Input() size: string;
  @Input() icon: string;
  @Input() background: string;
  @Input() disableTooltip: string;
  @Output() bgcolor: string;
  @Output() text: string;
  @Input() hidestatus: boolean;
  @Input() hideBlockedIcon: boolean;
  @Input() isGroupChat: boolean;
  @Input() showGroupAvatar = true;
  isBroadcast: boolean;
  className: string;
  private isAlive$ = new Subject<boolean>();
  bare: string;
  isBlocked: boolean;
  translationKey = "HEADER_OFFLINE";
  @Input() showDismiss = false;
  @Input() showName = false;
  @Input() isInvited = false;
  @Input() atComponent: string;
  name;
  isExternalUser: boolean;

  statusSubscription$;
  contactSubscription$;
  blockedListSubscription$;
  photoLastUpdateSubscription$;
  photoLastUpdate;
  @ViewChild("imgElement", { static: false }) imgElement: ElementRef;
  isVideoMeeting: boolean;
  loadedAvatar: boolean;

  @Input()
  set jid(value) {
    // console.log("[AvatarComponent][jid]", value, this.atComponent);

    if (!value) {
      return;
    }

    if (this.bare !== value) {
      this.isGroupChat = value.indexOf("@conference") !== -1;
      this.isVideoMeeting = CommonUtil.isVideoMeeting(value);
      if (CommonUtil.isBroadcast(value)) {
        this.isBroadcast = true;
      } else {
        this.isBroadcast = false;
      }
    }

    this.bare = value;

    // this.store.select(getDomain).filter(v => !!v).take(1).subscribe(domain => {
    //   if (this.isGroupChat) {
    //     this.isExternalUser = value.indexOf(`@conference.${domain}`) === -1;
    //   } else {
    //     this.isExternalUser = value.indexOf(`@${domain}`) === -1;
    //   }
    // });

    if (this.bare) {
      this.bare = this.bare.toLowerCase();
      const _text = CommonUtil.getAvatarText(this.bare);
      this.text = _text;
      this.bgcolor = CommonUtil.getAvatarBackground(_text);
      this.background = this.bgcolor;
    }

    this.name = this.contactRepo.getFullName(this.bare);
    this.text = CommonUtil.getAvatarText(this.name);
    this.className = this.bare.replace(/@/g, "-at-").replace(/\./g, "-");

    this.removeSubscribers();
    this.createSubscribers();
  }

  imgLoaded() {
    this.loadedAvatar = true;
    this.changeDetectionRef.markForCheck();
  }

  @Input()
  set type(value) {
    this.isGroupChat = value === "groupchat";
  }

  constructor(private store: Store<MCBRootState>,
    private contactRepo: ContactRepository,
    private avatarRepo: AvatarRepository,
    private configService: ConfigService,
    private changeDetectionRef: ChangeDetectorRef,
    private electronService: ElectronService) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();

    this.removeSubscribers();
  }

  private removeSubscribers() {
    if (this.statusSubscription$) {
      this.statusSubscription$.unsubscribe();
    }

    if (this.contactSubscription$) {
      this.contactSubscription$.unsubscribe();
    }

    if (this.blockedListSubscription$) {
      this.blockedListSubscription$.unsubscribe();
    }

    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
  }

  private createSubscribers() {
    if (!this.isBroadcast) {
      this.photoLastUpdateSubscription$ = this.store.select(state => getLastPhotoUpdate(state, this.bare))
      .pipe(distinctUntilChanged()).subscribe(photoLastUpdate => {
        // console.log("[AvatarComponent][photoLastUpdateSubscription]", this.photoLastUpdate, photoLastUpdate, this.bare);

        this.photoLastUpdate = photoLastUpdate;
        this.avatarUrl = this.buildAvatarUrl();
        this.changeDetectionRef.markForCheck();
        this.detectAvatarChange();
      });
    }
  }

  detectAvatarChange(): void {
    if (!this.isBroadcast) {
      this.avatarUrl = this.buildAvatarUrl();
      this.changeDetectionRef.markForCheck();
    } else {
      this.avatarUrl = null;
      this.changeDetectionRef.markForCheck();
    }
    // console.log("[AvatarComponent][detectAvatarChange]", this.avatarUrl, this.configService.avatarServiceUrl);
  }

  private buildAvatarUrl() {
    if (this.photoLastUpdate === -1) {
      // no avatar
      return null;
    }

    const avatarVersion = this.avatarVersion();
    let avatarName = this.avatarRepo.buildTargetHash(this.bare);
    if (this.size === "tile-view") {
      avatarName = `${avatarName}-420`;
    }
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg" + avatarVersion;
    return avUrl;
  }

  private avatarVersion() {
    return this.photoLastUpdate ? `?ver=${Math.abs(this.photoLastUpdate)}` : "";
  }

  imgLoadOnError(event) {
    this.avatarUrl = null;
    this.loadedAvatar = false;
    this.changeDetectionRef.markForCheck();
    this.changeDetectionRef.markForCheck();
  }
}

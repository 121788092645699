import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RootState } from "./reducers";
import { Store } from "@ngrx/store";
import { ConstantsUtil } from "./utils/constants.util";
import { take } from "rxjs/operators";

@Injectable()
export class ConfigService {
  CONTACTS_REST_PER_PAGE = 20;
  LAST_ACTIVITY_BATCH_INTERVAL = 5000 * 60;
  private loginIFrameHandled = false;
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private lang = ["en", "de"];

  private config = {
    "appsEnabled": [],
    "lang": "en"
  };
  GOOGLE_STATIC_MAP_API_KEY = "AIzaSyDuvK3ofJGLlmeOaQ1WPApAumWbVENDgd0";
  AVATAR_SYNC_INTERVAL = 5 * 60 * 1000; // 5 mins
  appInBackground = false;
  selectedServer: any;
  API_URL: string;
  URLS: any = {};
  currentLanguage = new BehaviorSubject<string>("en");
  private config$ = new BehaviorSubject<any>(this.config);
  language = "en";
  hideProfile = false;
  isAnonymous = false;
  avatarServiceUrl: string;
  LAST_ACTIVITY_ONLINE_THRESHHOLD: number;
  conferenceDomain: string;
  streamServer: any;
  constructor(private http: HttpClient,
    private translate: TranslateService,
    private store: Store<RootState>) {
    console.log("[ConfigService] constructor");
    this.API_URL = "";
    this.setLanguage();
    this.loadConfig();
  }

  getAllConfig() {
    return this.config$.asObservable();
  }

  getLoadedConfig() {
    return this.config$.asObservable();
  }

  redirectToLoginScreen() {
    if (!this.isCordovaOrElectron) {
      window.location.href = this.API_URL + "/api/login";
    } else {
      this.loginIframe();
    }
  }

  callLogout() {
    if (!this.isCordovaOrElectron) {
      window.location.href = this.API_URL + "/api/call-logout";
    } else {
      this.loginIframe();
    }
  }

  setCurrentLanguage(selectedLanguage: string) {
    this.language = selectedLanguage;
    // this.store.dispatch(new SetLanguage(selectedLanguage));
  }

  setLanguage() {
    let selectedLanguage = null;
    let browserLang = this.translate.getBrowserLang();
    const localLang = localStorage.getItem("portalLanguage");
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      browserLang = localLang;
    }
    selectedLanguage = browserLang.match(/en|de/) ? browserLang : "en";
    this.updateLanguage(selectedLanguage);
  }

  updateLanguage(selectedLanguage: any) {
    this.language = selectedLanguage;
    this.currentLanguage.next(selectedLanguage);
    this.language = selectedLanguage;
    this.translate.use(selectedLanguage);
  }

  loadConfig(): void {
    if (!!localStorage.getItem("URLS")) {
      this.URLS = JSON.parse(localStorage.getItem("URLS"));
    }
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    if (!isCordovaOrElectron) {
      this.setAPIURL("");
      this.selectedServer = true;
    } else {
      const serverURL = localStorage.getItem("serverURL");
      if (serverURL) {
        localStorage.setItem("serverURL", serverURL);
        console.log("[loadConfig] ", serverURL);
        this.selectedServer = true;
        this.setAPIURL(serverURL.trim());
      }
    }
  }

  updateConfig(config: any): void {
    console.log("updateConfig", config);
    this.config = { ...this.config, ...config };
  }

  set(key: string, value: any): any {
    const oldValue = this.config[key];
    this.config[key] = value;
    this.config$.next(this.config);
    return this.config;
  }

  get(key): any {
    const result = this.config[key];
    return result;
  }

  getOrThrow(key): any {
    if (!this.config.hasOwnProperty(key)) {
      throw new Error("Missing '" + key + "' in config");
    }
    return this.config[key];
  }

  setAPIURL(url: string): void {
    this.API_URL = url;
  }

  showError(msg) {
    console.log(`Invalid configuration: ${msg}`);
  }

  getSupportedLangs() {
    return this.lang.slice();
  }

  getDefaultLang() {
    return this.lang[0];
  }

  getConfiguredLang() {
    const lang = this.get("lang");
    if (this.lang.indexOf(lang) >= 0) {
      return lang;
    }
    return this.getDefaultLang();
  }

  isAppEnabled(appTitle: string) {
    const apps = this.config["appsEnabled"] || [];
    const app = apps.filter(a => a.title === appTitle)[0];
    return app && app.enabled;
  }

  getConfig() {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(this.API_URL + "/api/config", { headers });
  }

  loginIframe() {
    console.log("[config.ts] inside loginIframe()");
    let initialHref = environment.isCordova ? window.location.href.split("/www/")[0] : window.location.href.split("/mail")[0];
    initialHref = environment.isCordova ? initialHref.split("/mail")[0] : initialHref.split("/index.html")[0];
    const loginUrl = `${initialHref}${environment.isCordova ? "/www" : ""}/assets/login.html`;
    console.log("[config.service] launching login iframe with url", loginUrl);
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();
    }
    if (!this.selectedServer) {
      console.log("[config.ts] return");
      return;
    }

    const iframe = document.createElement("iframe");

    if (iframe) {
      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      iframe.style.border = "none";

      iframe.onload = () => {
        iframe.contentWindow.document.querySelector("html").style.height = "100%";
        console.log("[loginIframe] in onload");
      };

      if (document.querySelector("vp-root") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("vp-root").appendChild(iframe);
      } else {
        if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
          document.querySelector("body").appendChild(iframe);
        }
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }

  logoutIframe() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    const iframe = document.createElement("iframe");
    if (iframe) {
      iframe.id = "logoutIframe";
      iframe.setAttribute("src", this.API_URL + "/api/call-logout");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      if (document.querySelector("body") !== null && document.querySelector("#logoutIframe") === null) {
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        document.querySelector("body").appendChild(iframe);
      }
      if (document.querySelector("vp-commander") !== null) {
        const commander = <HTMLElement> document.querySelector("vp-commander");
        commander.style.display = "none";
      }
    }
  }

  @HostListener("window:resize", ["$event"])
  handleLoginResize() {
    console.log("[loginIframe] in window resize");
    // Added this because of the following reasons:
    // 1. When adding iFrame with position: fixed, scrolling doesn't work...
    // 1. When adding iFrame with position: absolute, white bar shows on startup and disappears on opening keyboard...
    if (!this.loginIFrameHandled) {
      this.loginIFrameHandled = true;
      document.getElementById("loginIframe").style.position = "absolute";
    }
  }

  private resetAndRedirectToLoginScreen() {
    if (!this.isCordovaOrElectron) {
      window.location.href = this.API_URL + "/api/login";
    } else {
      this.loginIframe();
    }
  }

  clearStorage() {
    console.log("[ConfigService][clearStorage]");
    // clear storage but save a server url & user login
    const serverURL = localStorage.getItem(ConstantsUtil.KEY_SERVER_URL);
    const username = localStorage.getItem(ConstantsUtil.KEY_USERNAME);
    localStorage.clear();
    localStorage.setItem(ConstantsUtil.KEY_SERVER_URL, serverURL);
    localStorage.setItem(ConstantsUtil.KEY_USERNAME, username);
  }

  getJitsiConfig(jitsiUrl: string): Observable<any> {
    console.log("[ConfigService][loadJitsiConfig]", jitsiUrl);
    const response = new Subject<any>();

    if (document.querySelector("#jitsiConfig") !== null) {
      document.querySelector("#jitsiConfig").remove();
    }

    let script = document.createElement("script");
    script.id = "jitsiConfig";
    script.src = `${jitsiUrl}config.js`;
    script.onload = () => {

      const jitsiConfig = window.config;

      if (!jitsiConfig.bosh.startsWith("https")) {
        jitsiConfig.bosh = jitsiConfig.bosh.replace("//", "https://");
      }

      if (!jitsiConfig.applicationName) {
        jitsiConfig.applicationName = "VNCtalk";
      }
      response.next(jitsiConfig);
    };
    script.onerror = (error: any) => {
      console.error("[ConfigService][loadJitsiConfig] error", error);
      response.error(error);
    };
    document.querySelector("head").appendChild(script);
    script = null;

    return response.asObservable().pipe(take(1));
  }
}

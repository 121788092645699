import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input,
  SimpleChanges, OnChanges, OnDestroy, EventEmitter, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { MCBRootState } from "../reducers";
import { MCBRepository } from "app/repositories/mcb.repository";
import { Conference } from "../models/mcb.model";
import { UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { getActiveConferences } from "app/reducers";
import { StartConferenceComponent } from "app/shared/components/start-conference/start-conference.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-conferences-list",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./conferences-list.component.html"
})
export class ConferencesListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() conferences: Conference[] = [];
  @Output() closeList = new EventEmitter();
  searchControl: UntypedFormControl;
  private isAlive$ = new Subject<boolean>();
  scheduledConferences: Conference[] = [];
  liveConferences: Conference[] = [];
  liveConferenceIds: any[] = [];
  constructor(
    private store: Store<MCBRootState>,
    private matDialog: MatDialog,
    private mcbRepo: MCBRepository,
    private changeDetectionRef: ChangeDetectorRef
  ) { }

  filterConferences() {
    let keyword = "";
    if (this.searchControl) {
      keyword = this.searchControl.value.toLowerCase();
    }
    this.liveConferences = this.conferences.filter(v => this.liveConferenceIds.includes(v.jid)
      && (v.name.includes(keyword) || v.jid.includes(keyword)));
    this.scheduledConferences = this.conferences.filter(v => !this.liveConferences.map(v2 => v2.jid).includes(v.jid)
    &&  v.start_time && (new Date(v.start_time)).getTime() > new Date().getTime()
      && (v.name.includes(keyword) || v.jid.includes(keyword)));
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit(): void {
    this.store.select(getActiveConferences).pipe(takeUntil(this.isAlive$)).subscribe(conferences => {
      this.liveConferenceIds = conferences.map(v => v.jid);
      this.filterConferences();
    });
    this.searchControl = new UntypedFormControl("", []);
    this.searchControl.valueChanges.pipe(debounceTime(500), takeUntil(this.isAlive$),
    ).subscribe(val => {
      this.filterConferences();
    });

    setTimeout(() => {
      if (document.getElementById("conferenceSearchControl") !== null) {
        document.getElementById("conferenceSearchControl").focus();
      }
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterConferences();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  startConference() {
    this.closeList.emit();
    const options: any = {
      width: "540px",
      height: "680px",
    };
    this.matDialog.open(StartConferenceComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "start_conference"
      },
      autoFocus: true
    }, options));
  }
}

import { Action } from "../../actions";
import { JitsiParticipant, JitsiOption } from "../models/jitsi-participant.model";
import { Recipient } from "../models/conversation.model";
import { JID } from "app/shared/models/jid.model";

export class ConferenceActionTypes {

  static UPDATE_JITSI_CONFIG = "[CONFERENCE] Update Jitsi Options";

  static CONFERENCE_START = "[CONFERENCE] Start Request";
  static RESET_CONFERENCE = "[CONFERENCE] Start Reset";
  static CONFERENCE_LEAVE = "[CONFERENCE] Leave Request";
  static CONFERENCE_START_SUCCESS = "[CONFERENCE] Start Request Success";
  static CONFERENCE_LEAVE_SUCCESS = "[CONFERENCE] Leave Request Success";

  static CONFERENCE_ADD_PARTICIPANT = "[CONFERENCE] Add Participant ";
  // sct
  static JITSI_CONFERENCE_ADD_PARTICIPANT = "[CONFERENCE] Jitsi Add Participant";
  static CONFERENCE_REMOVE_PARTICIPANT = "[CONFERENCE] Remove Participant ";
  // sct
  static JITSI_CONFERENCE_REMOVE_PARTICIPANT = "[CONFERENCE] Jitsi Remove Participant ";
  static WHITEBOARD_START = "[CONFERENCE] Whiteboard Start";

  static MUTE_AUDIO = "[CONFERENCE] Mute Audio ";
  static UNMUTE_AUDIO = "[CONFERENCE] Unmute Audio ";
  static MUTE_VIDEO = "[CONFERENCE] Mute Video ";
  static UNMUTE_VIDEO = "[CONFERENCE] Unmute Video ";
  static SHARE_SCREEN = "[CONFERENCE] Share Screen ";
  static UNSHARE_SCREEN = "[CONFERENCE] UnShare Screen ";
  static SET_FULL_SCREEN_PARTICIPANT = "[CONFERENCE] Set Full Screen participant ";
  static SET_SELECTED_PARTICIPANT = "[CONFERENCE] Select participant";
  // sct
  static SET_AUDIO_STATUS = "[CONFERENCE] set audio status";
  static SET_VIDEO_STATUS = "[CONFERENCE] set video status";

  static SET_MICROPHONE_STATUS = "[CONFERENCE] set microphone status";
  static SET_SPEAKER_STATUS = "[CONFERENCE] set speaker status ";
  static SET_WEBCAM_STATUS = "[CONFERENCE] set webcam ";

  static UPDATE_FRONT_CAMERA_ID = "[CONFERENCE] update front camera id";
  static UPDATE_BACK_CAMERA_ID = "[CONFERENCE] update back camera id ";
  static SET_JITSI_ROOM = "[CONFERENCE] set jitsi room";
  static SET_CONFERENCE_ID = "[CONFERENCE] set conference id";
  static SET_STREAM_ID = "[CONFERENCE] set stream id";
  static SET_CONVERSATION_TARGET = "[CONFERENCE] set conversation target";
  static CONFERENCE_SHOW_ACTIVE_CALL = "[CONFERENCE] show active call";
  static CONFERENCE_HIDE_ACTIVE_CALL = "[CONFERENCE] hide active call";

  static CONFERENCE_SET_EXISTING_CALL = "[CONFERENCE] set active call";
  static CONFERENCE_RESET_EXISTING_CALL = "[CONFERENCE] reset active call";
  static ANONYMOUS_CONFERENCE = "[CONFERENCE] Anonymous conference";
  static CONFERENCE_CURRENT_VIEW = "[CONFERENCE] set Current View";
  static SET_SPEAKING_PARTICIPANT = "[CONFERENCE] Set speaking participant";
  static CONFERENCE_MUTE_PARTICIPANT = "[CONFERENCE] Mute for me";
  static CONFERENCE_UNMUTE_PARTICIPANT = "[CONFERENCE] Unmute for me";
  static UPDATE_RECENT_PARTICIPANTS = "[CONFERENCE] Update participants";

}



export class UpdateJitsiConfig implements Action {
  readonly type = ConferenceActionTypes.UPDATE_JITSI_CONFIG;

  constructor(public payload: any) {

  }
}
export class ConferenceStartRequest implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_START;
  constructor(public payload: { conversationTarget: string, conferenceType: string,
    startFromInvitation?: boolean, invitedParticipants?: JID[], externalParticipants?: Recipient[]  }) {
  }
}

export class AnonymousConference implements Action {
  readonly type = ConferenceActionTypes.ANONYMOUS_CONFERENCE;
  constructor(public payload: { participantEmail: string, jitsiRoom: any}) {
  }
}

export class ResetConferenceStartRequest implements Action {
  readonly type = ConferenceActionTypes.RESET_CONFERENCE;
  constructor() {
  }
}

export class ConferenceStartSuccess implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_START_SUCCESS;

  constructor(public payload: { conferenceId: string, participants: JitsiParticipant[] }) {

  }
}

export class SetCurrentView implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_CURRENT_VIEW;

  constructor(public payload: string) {

  }
}

export class UpdateRecentParticipants implements Action {
  readonly type = ConferenceActionTypes.UPDATE_RECENT_PARTICIPANTS;

  constructor(public payload: string[]) {

  }
}

export class ConferenceSetActiveWhiteboard implements Action {
  readonly type = ConferenceActionTypes.WHITEBOARD_START;

  constructor(public payload: { conversationTarget: string }) {

  }
}

export class ConferenceLeaveRequest implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_LEAVE;
}

export class ConferenceLeaveSuccess implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_LEAVE_SUCCESS;
}


export class ConferenceAddParticipant implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_ADD_PARTICIPANT;

  constructor(public payload: JitsiParticipant) {

  }
}

// sct
export class JitsiConferenceAddParticipant implements Action {
  readonly type = ConferenceActionTypes.JITSI_CONFERENCE_ADD_PARTICIPANT;

  constructor(public payload: JitsiParticipant) {

  }
}


export class ConferenceRemoveParticipant implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_REMOVE_PARTICIPANT;

  constructor(public payload: string) {

  }
}

// sct
export class JitsiConferenceRemoveParticipant implements Action {
  readonly type = ConferenceActionTypes.JITSI_CONFERENCE_REMOVE_PARTICIPANT;

  constructor(public payload: string) {

  }
}

export class ConferenceMuteAudio implements Action {
  readonly type = ConferenceActionTypes.MUTE_AUDIO;
}




export class ConferenceUnMuteAudio implements Action {
  readonly type = ConferenceActionTypes.UNMUTE_AUDIO;
}



export class ConferenceMuteVideo implements Action {
  readonly type = ConferenceActionTypes.MUTE_VIDEO;
}



export class ConferenceUnMuteVideo implements Action {
  readonly type = ConferenceActionTypes.UNMUTE_VIDEO;
}



export class ConferenceShareScreen implements Action {
  readonly type = ConferenceActionTypes.SHARE_SCREEN;
}


export class ConferenceUnShareScreen implements Action {
  readonly type = ConferenceActionTypes.UNSHARE_SCREEN;
}

export class ConferenceSetFullScreenParticipant implements Action {
  readonly type = ConferenceActionTypes.SET_FULL_SCREEN_PARTICIPANT;

  constructor(public payload: string) {

  }
}

export class ConferenceSelectParticipant implements Action {
  readonly type = ConferenceActionTypes.SET_SELECTED_PARTICIPANT;

  constructor(public payload: string) {

  }
}


export class SetWebcamStatus implements Action {
  readonly type = ConferenceActionTypes.SET_WEBCAM_STATUS;

  constructor(public payload: boolean) {

  }
}

export class SetMicrophoneStatus implements Action {
  readonly type = ConferenceActionTypes.SET_MICROPHONE_STATUS;

  constructor(public payload: boolean) {

  }
}

export class SetSpeakerStatus implements Action {
  readonly type = ConferenceActionTypes.SET_SPEAKER_STATUS;

  constructor(public payload: boolean) {

  }
}

export class SetSpeakingParticipant implements Action {
  readonly type = ConferenceActionTypes.SET_SPEAKING_PARTICIPANT;
  constructor(public payload: string) {

  }
}

export class UpdateFrontCameraId implements Action {
  readonly type = ConferenceActionTypes.UPDATE_FRONT_CAMERA_ID;

  constructor(public payload: string) {

  }
}

export class UpdateBackCameraId implements Action {
  readonly type = ConferenceActionTypes.UPDATE_BACK_CAMERA_ID;

  constructor(public payload: string) {

  }
}

export class SetJitsiRoomId implements Action {
  readonly type = ConferenceActionTypes.SET_JITSI_ROOM;

  constructor(public payload: JitsiOption) {

  }
}

export class SetConferenceId implements Action {
  readonly type = ConferenceActionTypes.SET_CONFERENCE_ID;

  constructor(public payload: string) {

  }
}

export class SetStreamId implements Action {
  readonly type = ConferenceActionTypes.SET_STREAM_ID;

  constructor(public payload: string) {

  }
}

export class SetConversationTarget implements Action {
  readonly type = ConferenceActionTypes.SET_CONVERSATION_TARGET;

  constructor(public payload: string) {
  }
}

export class ShowActiveCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_SHOW_ACTIVE_CALL;
}

export class HideActiveCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_HIDE_ACTIVE_CALL;
}

export class SetExistingCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_SET_EXISTING_CALL;

  constructor(public payload: { conversationTarget: string, conferenceType: string}) {
  }
}

export class MuteForMe implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_MUTE_PARTICIPANT;

  constructor(public payload: string) {
  }
}

export class UnMuteForMe implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_UNMUTE_PARTICIPANT;

  constructor(public payload: string) {
  }
}

export class ResetExistingCall implements Action {
  readonly type = ConferenceActionTypes.CONFERENCE_RESET_EXISTING_CALL;

  constructor(public payload: { conversationTarget: string}) {
  }
}


// sct
export class SetAudioStatus implements Action {
  readonly type = ConferenceActionTypes.SET_AUDIO_STATUS;
  constructor(public payload: JitsiParticipant) {

  }
}

// sct
export class SetVideoStatus implements Action {
  readonly type = ConferenceActionTypes.SET_VIDEO_STATUS;
  constructor(public payload: JitsiParticipant) {

  }
}

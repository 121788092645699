import { Action } from "../../actions";
import { MetaConferenceBoard } from "../models/mcb.model";

export class MCBActionTypes {
  static MCB_ADD = "[MCB] Add";
  static MCB_UPDATE = "[MCB] Update";
  static MCB_DELETE = "[MCB] Delete";
  static MULTI_MCB_ADD = "[MCB] Multi MCB Add";
  static SET_ACTIVE_MCB = "[MCB] Set Active MCB";

}

export class MCBAdd implements Action {
  readonly type = MCBActionTypes.MCB_ADD;

  constructor(public payload: MetaConferenceBoard) {
  }
}

export class MCBDelete implements Action {
  readonly type = MCBActionTypes.MCB_DELETE;

  constructor(public payload: number) {
  }
}

export class MCBUpdate implements Action {
  readonly type = MCBActionTypes.MCB_UPDATE;

  constructor(public payload: {id: number, changes: any}) {
  }
}

export class MultipleMCBAdd implements Action {
  readonly type = MCBActionTypes.MULTI_MCB_ADD;

  constructor(public payload: MetaConferenceBoard[]) {
  }
}

export class SetActiveBoard implements Action {
  readonly type = MCBActionTypes.SET_ACTIVE_MCB;
  constructor(public payload: MetaConferenceBoard) {
  }
}

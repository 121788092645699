import { Component, OnInit, Inject, HostListener, ViewChild, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { RootState, getIsAppOnline } from "app/reducers";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CropperSettings, ImageCropperComponent } from "ngx-img-cropper";

@Component({
  selector: "vp-update-avatar",
  templateUrl: "./update-avatar.component.html"
})
export class UpdateAvatarComponent implements OnInit, OnDestroy {
  cropperSettings: CropperSettings;
  oldAvatarUrl: string;
  showDefaultAvatar: boolean;
  isAppOnline: boolean;
  @ViewChild("cropper", { static: false })
  cropper: ImageCropperComponent;
  imageData: any = {};
  private isAlive$ = new Subject<boolean>();
  constructor(
    private matDialogRef: MatDialogRef<UpdateAvatarComponent>,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 94;
    this.cropperSettings.height = 94;
    this.cropperSettings.keepAspect = true;
    this.cropperSettings.croppedWidth = 94;
    this.cropperSettings.croppedHeight = 94;
    this.cropperSettings.canvasWidth = 300;
    this.cropperSettings.canvasHeight = 300;
    this.cropperSettings.minWidth = 94;
    this.cropperSettings.minHeight = 94;
    this.cropperSettings.rounded = true;
    this.cropperSettings.minWithRelativeToResolution = false;
    this.cropperSettings.cropperDrawSettings.strokeColor = "#eaeaea";
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings.noFileInput = true;

    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe(v => this.isAppOnline = v);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  fileChangeListener($event) {
    const image: HTMLImageElement = new Image();
    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();
    this.cropper.settings = this.cropperSettings;
    this.showDefaultAvatar = false;
    myReader.onloadend = (loadEvent: any) => {
      image.src = loadEvent.target.result;
    };

    image.onload = () => {
      if (this.cropper) {
        this.cropper.setImage(image);
      }
    };

    myReader.readAsDataURL(file);
  }
  @HostListener("document:keydown.esc")
  cancel(): void {
    this.matDialogRef.close();
  }

  imgLoadOnError() {
    this.showDefaultAvatar = false;
  }

  updateAvatar() {
    if (this.imageData && this.imageData.image) {
      const b64Data = this.imageData.image;
      const photo: any = {
        type: "image/png",
        data: b64Data
      };
      console.log("[UpdateAvatarComponent][updateAvatar]", photo);
      this.matDialogRef.close({ photo: photo });
    } else {
      this.matDialogRef.close({ photo: {} });
    }
  }

}

import { Action } from "./index";
import { AppState } from "app/reducers/app";
import { UserConfig } from "app/shared/models/user-config.model";
import { InvitationConfig } from "app/shared/models/invitation-config";
import { ProfileResponse } from "app/shared/models/profile.response";
import { UserStatus } from "app/shared/models";
import { JID } from "app/shared/models/jid.model";

export class AppActionTypes {
  static SET_LANDING_PAGE_URL = "[App] set landing page url";
  static SET_AVAILABLE_APPS = "[App] set available apps";
  static SET_ACTIVE_TAB = "[App] Set Active Tab";
  static SET_BASE_API_URL = "[App] set base api url";
  static SET_FIREBASE_TOKEN = "[App] set firebase token";
  static ENABLE_GLOBAL_MUTE = "[App] enable global mute";
  static DISABLE_GLOBAL_MUTE = "[App] disable global mute";



  static APP_BOOTSTRAPPED = "[App] Bootstrap success";

  static LOGIN_REQUEST = "[App] Login Request";
  static LOGIN_SUCCESS = "[App] Login Success";
  static LOGOUT_SUCCESS = "[App] Logout Success";

  static XMPP_SESSION = "[App] Xmpp Session";
  static SET_BARE = "[App] Set bare";

  static STATUS_CHANGE = "[APP] Status Change";
  static NOTIFY_OPTION_CHANGE = "[APP] Notify Option Change";

  static FETCH_ME_REQUEST = "[App] Fetch Me Request";
  static FETCH_ME_SUCCESS = "[App] Fetch Me Success";
  static LOGOUT = "[App] logout";


  static XMPP_CONNECTED = "[App] XMPP Connected";
  static APP_ONLINE = "[App] App Online";
  static RESTORE_SAVED_STATE = "[App] Restore Saved State";
  static WINDOW_FOCUS_STATE = "[App] Window Focus State";
  static SET_APP_SETTINGS = "[App] App Settings";
  static SET_FONT_SIZE = "[App] Font Size";
  static UPDATE_CONNECTION_INFORMATION = "[App] Update Network Information";


  static RESTORE_PROFILE_JID = "[App] Restore Profile and Jid";
  static DATABASE_READY = "[App] Database Ready";
  static SET_DOMAIN = "[App] Set Domain";
  static UPDATE_LOGOUT_OPTION = "[App] Update logout option";
  static UPDATE_USER_CONFIG = "[App] Update user config";
  static SET_INVITATION_CONFIG = "[App] Invitation config";
  static SET_ORG_ID = "[App] Set ORG ID";
  static SET_ACTIVE_CONFERENCES = "[App] Set Active Conferences";
  static SET_RUNNING_CONFERENCES = "[App] Set Running Conferences";
  static SET_SIDEBAR_VALUE = "[App] Set Sidebar Is Collapsed";

}

export class SetLandingPageUrl implements Action {
  readonly type = AppActionTypes.SET_LANDING_PAGE_URL;

  constructor(public payload: string) {
  }
}

export class SetActiveTab implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_TAB;

  constructor(public payload: string) {
  }
}

export class SetDomain implements Action {
  readonly type = AppActionTypes.SET_DOMAIN;

  constructor(public payload: string) {
  }
}

export class UpdateLogoutOption implements Action {
  readonly type = AppActionTypes.UPDATE_LOGOUT_OPTION;

  constructor(public payload: boolean) {
  }
}

export class SetBaseApiUrl implements Action {
  readonly type = AppActionTypes.SET_BASE_API_URL;

  constructor(public payload: string) {
  }
}

export class SetAvailableApps implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_APPS;

  constructor(public payload: any[]) {
  }
}

export class SetFirebaseToken implements Action {
  readonly type = AppActionTypes.SET_FIREBASE_TOKEN;

  constructor(public payload: string) {
  }
}

export class AppBootstrapped implements Action {
  readonly type = AppActionTypes.APP_BOOTSTRAPPED;
}


export class LoginRequest implements Action {
  readonly type = AppActionTypes.LOGIN_REQUEST;
}


export class LoginSuccess implements Action {
  readonly type = AppActionTypes.LOGIN_SUCCESS;

  constructor() {
  }
}

export class LogoutSuccess implements Action {
  readonly type = AppActionTypes.LOGOUT_SUCCESS;

  constructor() {
  }
}


export class XmppSession implements Action {
  readonly type = AppActionTypes.XMPP_SESSION;

  constructor(public payload: JID) {
  }
}

export class SetXmppConnection implements Action {
  readonly type = AppActionTypes.XMPP_CONNECTED;

  constructor(public payload: boolean) {
  }
}

export class UpdateNetworkInformation implements Action {
  readonly type = AppActionTypes.UPDATE_CONNECTION_INFORMATION;

  constructor(public payload: any) {
  }
}


export class StatusChange implements Action {
  readonly type = AppActionTypes.STATUS_CHANGE;

  constructor(public payload: UserStatus) {
  }
}

export class NotifyOptionChange implements Action {
  readonly type = AppActionTypes.NOTIFY_OPTION_CHANGE;

  constructor(public payload: string) {
  }
}

export class FetchMeRequest implements Action {
  readonly type = AppActionTypes.FETCH_ME_REQUEST;
}

export class FetchMeSuccess implements Action {
  readonly type = AppActionTypes.FETCH_ME_SUCCESS;

  constructor(public payload: ProfileResponse) {
  }
}

export class Logout implements Action {
  readonly type = AppActionTypes.LOGOUT;
}

export class OnlineStatus implements Action {
  readonly type = AppActionTypes.APP_ONLINE;

  constructor(public payload: boolean) {
  }
}

export class RestoreSavedState implements Action {
  readonly type = AppActionTypes.RESTORE_SAVED_STATE;

  constructor(public payload: {
    appState: AppState
  }) {
  }
}
export class WindowFocusStatus implements Action {
  readonly type = AppActionTypes.WINDOW_FOCUS_STATE;
  constructor(public payload: boolean) {}
}

export class SetFontSize implements Action {
  readonly type = AppActionTypes.SET_FONT_SIZE;
  constructor(public payload: number) {}
}

export class RestoreProfileJid implements Action {
  readonly type = AppActionTypes.RESTORE_PROFILE_JID;
  constructor(public payload) {}
}

export class SetBare implements Action {
  readonly type = AppActionTypes.SET_BARE;
  constructor(public payload: string) {}
}

export class DatabaseReady implements Action {
  readonly type = AppActionTypes.DATABASE_READY;
  constructor() {}
}

export class EnableGlobalMute implements Action {
  readonly type = AppActionTypes.ENABLE_GLOBAL_MUTE;
  constructor() {}
}

export class DisableGlobalMute implements Action {
  readonly type = AppActionTypes.DISABLE_GLOBAL_MUTE;
  constructor() {}
}

export class SetUserConfig implements Action {
  readonly type = AppActionTypes.UPDATE_USER_CONFIG;
  constructor(public payload: UserConfig) {}
}

export class SetInvitationConfig implements Action {
  readonly type = AppActionTypes.SET_INVITATION_CONFIG;
  constructor(public payload: InvitationConfig) {}
}

export class SetOrganizationId implements Action {
  readonly type = AppActionTypes.SET_ORG_ID;
  constructor(public payload: number) {}
}

export class SetActiveConferences implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_CONFERENCES;
  constructor(public payload: any[]) {}
}

export class SetRunningConferences implements Action {
  readonly type = AppActionTypes.SET_RUNNING_CONFERENCES;
  constructor(public payload: any) {}
}

export class SetSidebarIsExpanded implements Action {
  readonly type = AppActionTypes.SET_SIDEBAR_VALUE;
  constructor(public payload: any) {}
}

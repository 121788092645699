import { Component, OnInit, Inject, ChangeDetectorRef, HostListener, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { RootState } from "app/reducers";
import { MCBRepository } from "app/repositories/mcb.repository";
import { Subject } from "rxjs";
import { ContactRepository } from "app/repositories/contact.repository";
import * as _ from "lodash";
import { take } from "rxjs/operators";

@Component({
  selector: "vp-open-participants",
  templateUrl: "./open-participants.component.html"
})
export class OpenParticipantsComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  selectedParticipants = [];
  conference: any;
  audiences: any;
  constructor(
    private matDialogRef: MatDialogRef<OpenParticipantsComponent>,
    private store: Store<RootState>,
    private contactRepo: ContactRepository,
    private mcbRepo: MCBRepository,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectionRef: ChangeDetectorRef) {
      this.conference = this.data.conference;
      this.data.members.forEach(p => {
        this.selectedParticipants.push({target: p, role: "participant", title: this.getTargetTitle(p)});
      });
      this.data.admins.forEach(p => {
         this.selectedParticipants.unshift({target: p, role: "moderator", title: this.getTargetTitle(p)});
      });
      this.mcbRepo.getGroupInfo(this.data.conference.jid).pipe(take(1)).subscribe(res => {
        console.log("[getGroupInfo]", res, this.data);
        if (!!res) {
          if (res.affiliations_owner) {
            res.affiliations_owner.forEach(r => {
              if (!_.find(this.selectedParticipants, v => v.target === r.jid)) {
                this.selectedParticipants.unshift({target: r.jid, role: "moderator", title: this.getTargetTitle(r.jid)});
              }
            });
          }
          if (res.affiliations_audience) {
            this.audiences = res.affiliations_audience.map(v => v.jid);
            console.log("[getGroupInfo]", this.audiences);
            this.selectedParticipants.forEach(r => {
                if (this.audiences.includes(r.target)) {
                  r.role = "audience";
                }
            });
          }
          this.changeDetectionRef.markForCheck();
        }
      });
    }

  ngOnInit(): void {
}

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.matDialogRef.close();
  }

  start(): void {
    this.matDialogRef.close({start: true});
  }

  getTargetTitle(target: string): string {
    return this.contactRepo.getFullName(target);
  }
}

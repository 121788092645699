import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    Inject
} from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { RootState } from "app/reducers";
import * as _ from "lodash";
import { ToastService } from "app/shared/services/toast.service";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormControl, Validators } from "@angular/forms";
import { takeUntil, debounceTime, switchMap } from "rxjs/operators";
import { Conversation } from "app/meta-conference-board/models/conversation.model";
import { MCBRepository } from "app/repositories/mcb.repository";
import { getAllMCB } from "app/meta-conference-board/reducers";
import { MetaConferenceBoard } from "app/meta-conference-board/models/mcb.model";

@Component({
    selector: "vp-change-mcb-list",
    templateUrl: "change-mcb-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeMCBListDialogComponent implements OnDestroy, OnInit {
    searchControl: UntypedFormControl;
    description: UntypedFormControl;
    listName: UntypedFormControl;
    userJID: any;
    private isAlive$ = new Subject<boolean>();
    mcbList: MetaConferenceBoard[] = [];
    currentMCBList: MetaConferenceBoard[] = [];
    constructor(private toastService: ToastService,
        private matDialogRef: MatDialogRef<ChangeMCBListDialogComponent>,
        private store: Store<RootState>,
        private mcbRepo: MCBRepository,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectionRef: ChangeDetectorRef) {
            this.currentMCBList = [... this.currentMCBList, ...(this.data.conference.meta_conference_boards || [])];
            console.log("[ChangeMCBListDialogComponent]", this.data, this.currentMCBList);
    }

    ngOnInit(): void {
        this.store.select(getAllMCB).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.mcbList = res;
            this.changeDetectionRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    @HostListener("document:keydown.esc")
    cancel(): void {
        this.matDialogRef.close();
    }

    update() {
        this.mcbRepo.changeGroupMCBs(this.data.conference.jid, this.currentMCBList.map(v => v.id)).subscribe(v => {
            this.toastService.showSnackbar("MCB_LIST_UPDATED");
            this.matDialogRef.close();
        });
    }

    removeMCB(mcb) {
        this.currentMCBList = this.currentMCBList.filter(v => v.id !== mcb.id);
        this.changeDetectionRef.markForCheck();
    }

    addMCB(mcb) {
        if (!this.currentMCBList.find(v => v.id === mcb.id)) {
            this.currentMCBList.push(mcb);
            this.changeDetectionRef.markForCheck();
        }
    }
}

import { Injectable } from "@angular/core";
import { MiddlewareService } from "./middleware.service";
import { Observable } from "rxjs/Observable";
import { JID, SearchUser, LDAPData } from "../models/jid.model";
import { getUserJID, getContactById } from "../../reducers/index";
import { Store } from "@ngrx/store";
import { ContactUpdate } from "app/actions/contact";
import { take } from "rxjs/operators";
import { ContactRest } from "../models/contact-rest.model";
import { MCBRootState } from "app/meta-conference-board/reducers";

@Injectable()
export class ContactsService {

  constructor(private middlewareService: MiddlewareService,
    private store: Store<MCBRootState>) {
  }

  searchLDAPUsers(keyword: string): Observable<SearchUser[]> {
    return this.getLDAPUsers(keyword, null);
  }

  retrieveLDAPUsers(jids: string[]): Observable<SearchUser[]> {
    return this.getLDAPUsers(null, jids);
  }

  private getLDAPUsers(keyword: string, jids: string[]): Observable<SearchUser[]> {
    let loggedInuser;
    this.store.select(getUserJID).pipe(take(1)).subscribe(res => loggedInuser = res);

    const queryParams = keyword ? { "keyword": keyword } : { "jids": jids };

    return this.middlewareService.post<LDAPContact[]>("/api/search-ldap", true, queryParams).map(res => {
      if (res === null) {
        return [];
      }

      return res.map(user => {
        const email = user.mail[0];

        const displayName = this.getDisplayNameFromLDAPEntry(user);

        this.store.select(state => getContactById(state, email)).pipe(take(1)).subscribe(contact => {
          if (contact && contact.name === email.split("@")[0]) {
            this.store.dispatch(new ContactUpdate({ id: email, changes: { name: displayName } }));
          }
        });

        return {
          name: displayName,
          email,
          ldapData: user as LDAPData
        };
      });
    });
  }
  
  updateGroupContacts(groupId: number, contact_ids: number[]): Observable<any> {
    const data = {
      contact_group: {
        contact_ids: contact_ids || []
      }
    };
    return this.middlewareService.put(`/api/contact_groups/${groupId}`, true, data);
  }

  updateGroupName(groupId: number, groupName: string): Observable<any> {
    const data = {
      contact_group: {
        name: groupName
      }
    };
    return this.middlewareService.put(`/api/contact_groups/${groupId}`, true, data);
  }

  deleteAvatar(target: string): Observable<any> {
    return this.middlewareService.delete(`/api/delete_avatar/${target}`, true);
  }
  getContacts(queryParams: any): Observable<any> {
    let data = {};
    if (queryParams) {
      data = queryParams;
    }
    return this.middlewareService.get<ContactRest[]>(`/api/contacts.json`, true, data);
  }

  getGroups(queryParams: any): Observable<any> {
    let data = {};
    if (queryParams) {
      data = queryParams;
    }
    return this.middlewareService.get<ContactRest[]>(`/api/contact_groups.json`, true, data);
  }

  getContact(contactId: number): Observable<ContactRest> {
    return this.middlewareService.get<ContactRest>(`/api/contacts/${contactId}`, true);
  }

  deleteContact(contactId: number): Observable<null> {
    return this.middlewareService.delete(`/api/contacts/${contactId}`, true);
  }

  private mapModelToMidlewareParams(contact: ContactRest): any {
    const contactParams = {};

    if (contact.is_company) {
      contactParams["is_company"] = contact.is_company;
    }
    if (contact.first_name) {
      contactParams["first_name"] = contact.first_name;
    }
    if (contact.middle_name) {
      contactParams["middle_name"] = contact.middle_name;
    }
    if (contact.last_name) {
      contactParams["last_name"] = contact.last_name;
    }
    if (contact.company) {
      contactParams["company"] = contact.company;
    }
    if (contact.job_title) {
      contactParams["job_title"] = contact.job_title;
    }
    if (contact.phones && contact.phones.length > 0) {
      const phones_attributes = {};
      for (let i = 0; i < contact.phones.length; ++i) {
        phones_attributes[i.toString()] = contact.phones[i];
      }
      contactParams["phones_attributes"] = phones_attributes;
    }
    if (contact.emails && contact.emails.length > 0) {
      const emails_attributes = {};
      for (let i = 0; i < contact.emails.length; ++i) {
        emails_attributes[i.toString()] = contact.emails[i];
      }
      contactParams["emails_attributes"] = emails_attributes;
    }
    if (contact.addresses && contact.addresses.length > 0) {
      const addresses_attributes = {};
      for (let i = 0; i < contact.addresses.length; ++i) {
        addresses_attributes[i.toString()] = contact.addresses[i];
      }
      contactParams["addresses_attributes"] = addresses_attributes;
    }

    return { "contact": contactParams };
  }

  private getDisplayNameFromLDAPEntry(user: LDAPContact) {
    if (user.displayName && user.displayName.length > 0) {
      return user.displayName[0];
    }

    if (user.givenName && user.givenName.length > 0) {
      if (user.sn && user.sn.length > 0) {
        return user.givenName[0] + " " + user.sn[0];
      }

      return user.givenName[0];
    }

    if (user.sn && user.sn.length > 0) {
      return user.sn[0];
    }

    return "";
  }
}

interface LDAPContact {
  givenName: string[];
  sn: string[];
  mail: string[];
  uid: string[];
  displayName: string[];
}

import { RootState } from "../../reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import {
  _getActiveConference,
  _getActiveWhiteboard,
  _getBackCameraId,
  _getConferenceId,
  _getConferenceType,
  _getConversationTarget,
  _getFrontCameraId,
  _getFullScreenParticipantId,
  _getHasActiveCall,
  _getHasMicrophone,
  _getHasSpeaker,
  _getHasWebcam,
  _getInvitationStatus,
  _getInvitedParticipants,
  _getIsConferenceAudioMuted,
  _getIsConferenceScreenSharing,
  _getIsConferenceVideoMuted,
  _getJitsiConfig,
  _getJitsiRoom,
  _getParticipants,
  _getConferenceParticipants,
  _getSelectedParticipantId,
  _getStreamId,
  conferenceReducer,
  ConferenceState,
  _getExternalParticipants,
  _getExistingConferences,
  _getParticipantEmail,
  _getCurrentView,
  _getSpeakingParticipant,
  _getMutedForMe,
  _getRecentParticipants
} from "./conference";
import { MetaConferenceBoardState, mcbAdapter, mcbReducer, _getActiveBoard, _getActiveBoardConversations } from "./mcb";
import { MetaConferenceBoard } from "../models/mcb.model";

export interface MCBRootState extends RootState {
  mcb: State;
}

export const getMCBRootState = createFeatureSelector<State>("mcb");

export interface State {
  conference: ConferenceState;
  mcb: MetaConferenceBoardState;
}

export const mcbRootReducer = {
  conference: conferenceReducer,
  mcb: mcbReducer
};


export const getConferenceState = createSelector(
  getMCBRootState,
  state => state.conference
);

export const getConferenceId = createSelector(
  getConferenceState,
  _getConferenceId
);

export const getConferenceParticipants = createSelector(
  getConferenceState,
  _getParticipants
);

// sct
export const getJitsiConferenceParticipants = createSelector(
  getConferenceState,
  _getConferenceParticipants,
);

export const getFullScreenParticipantId = createSelector(
  getConferenceState,
  _getFullScreenParticipantId
);

export const getSelectedParticipantId = createSelector(
  getConferenceState,
  _getSelectedParticipantId
);

export const getCurrentView = createSelector(
  getConferenceState,
  _getCurrentView
);

export const getSpeakingParticipant = createSelector(
  getConferenceState,
  _getSpeakingParticipant
);

export const getMutedForMe = createSelector(
  getConferenceState,
  _getMutedForMe
);

export const getIsConferenceAudioMuted = createSelector(
  getConferenceState,
  _getIsConferenceAudioMuted
);

export const getIsConferenceVideoMuted = createSelector(
  getConferenceState,
  _getIsConferenceVideoMuted
);

export const getIsConferenceScreenSharingOn = createSelector(
  getConferenceState,
  _getIsConferenceScreenSharing
);


export const getJitsiConfig = createSelector(
  getConferenceState,
  _getJitsiConfig
);

export const getHasMicrophone = createSelector(
  getConferenceState,
  _getHasMicrophone
);

export const getExistingConferences = createSelector(
  getConferenceState,
  _getExistingConferences
);

export const getHasSpeaker = createSelector(
  getConferenceState,
  _getHasSpeaker
);

export const getHasWebcam = createSelector(
  getConferenceState,
  _getHasWebcam
);

export const getFrontCameraId = createSelector(
  getConferenceState,
  _getFrontCameraId
);


export const getBackCameraId = createSelector(
  getConferenceState,
  _getBackCameraId
);

export const getActiveConference = createSelector(
  getConferenceState,
  _getActiveConference
);

export const getActiveWhiteboard = createSelector(
  getConferenceState,
  _getActiveWhiteboard
);

export const getConversationTarget = createSelector(
  getConferenceState,
  _getConversationTarget
);

export const getJitsiRoom = createSelector(
  getConferenceState,
  _getJitsiRoom
);

export const getParticipantEmail = createSelector(
  getConferenceState,
  _getParticipantEmail
);

export const getConferenceType = createSelector(
  getConferenceState,
  _getConferenceType
);

export const getInvitationStatus = createSelector(
  getConferenceState,
  _getInvitationStatus
);

export const getStreamId = createSelector(
  getConferenceState,
  _getStreamId
);

export const getHasActiveCall = createSelector(
  getConferenceState,
  _getHasActiveCall
);

export const getRecentParticipants = createSelector(
  getConferenceState,
  _getRecentParticipants
);

export const getInvitedParticipants = createSelector(
  getConferenceState,
  _getInvitedParticipants
);

export const getExternalParticipants = createSelector(
  getConferenceState,
  _getExternalParticipants
);

export const getMCBState = createSelector(
  getMCBRootState,
  state => state.mcb
);

export const {
  selectIds: getMCBIds,
  selectEntities: getMCBEntities,
  selectAll: getAllMCB,
  selectTotal: getTotalMCB, // returns total count of conversation
} = mcbAdapter.getSelectors(getMCBState);

export const getCMCBs = getAllMCB;
export const getTotalMCBs = getTotalMCB;

export const getActiveBoard = createSelector(
  getMCBState,
  _getActiveBoard
);

export const getActiveBoardConversations = createSelector(
  getMCBState,
  _getActiveBoard,
  _getActiveBoardConversations
);

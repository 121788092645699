import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { LoggingInterceptor } from "./logging-interceptor";
import { AuthInterceptor } from "./auth-interceptor";
import { ErrorInterceptor } from "./error.interceptor";

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

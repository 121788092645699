import { Injectable } from "@angular/core";
import { CanLoad, Route, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";
import { UserConfigService } from "../services/userConfig.service";
import { ConfigService } from "app/config.service";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanLoad {

  constructor(private configService: ConfigService, private userConfig: UserConfigService, private authService: AuthService) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.userConfig.getUserConfig()
      .pipe(map(user => user ? user.can_manage_mcbs : false),
      tap(v => {
        console.log("[getUserConfig] can_manage_mcbs", v, this.userConfig.user);
        if (!v) {
          if (this.userConfig.user) {
            this.configService.clearStorage();
            this.configService.callLogout();
          } else {
            this.resetAndRedirectToLoginScreen();
          }
        } else {
          this.authService.getProfile().subscribe(profile => {
              console.log("[getProfile]", profile);
          }, err => {
            console.log("[getProfile] err", err);
            this.configService.callLogout();
          });
        }
      }));
  }

  private resetAndRedirectToLoginScreen() {
    console.log("[AppModule][resetAndRedirectToLoginScreen]");
    this.configService.clearStorage();
    this.configService.redirectToLoginScreen();
  }
}

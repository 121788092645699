import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";
import * as _ from "lodash";
import { Conversation } from "app/meta-conference-board/models/conversation.model";
import { ConversationActionTypes } from "app/actions/conversation";

export interface ConversationState extends EntityState<Conversation> {
  isLoading: boolean;
  isLoaded: boolean;
}

export const conversationAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
  selectId: (conversation: Conversation) => conversation.jid,
  sortComparer: null
});

export const initialState: ConversationState = conversationAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});

export function conversationReducer(state: ConversationState = initialState, action: Action): ConversationState {
  switch (action.type) {

    case ConversationActionTypes.CONVERSATION_LOAD_SUCCESS: {
      return conversationAdapter.addMany(action.payload, state);
    }

    default: {
      return state;
    }
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "./shared/providers";
import { AppComponent } from "./app.component";


const routes: Routes = [
  {
    path: "",
    canLoad: [AuthGuard],
    loadChildren: () => import("./meta-conference-board/meta-conference-board.module")
    .then(m => m.MetaConferenceBoardModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Tell the router to use the HashLocationStrategy.
    useHash: false,
    enableTracing: false,
    // This will tell Angular to preload the lazy-loaded routes after the
    // application has been bootstrapped. This will extend to both top-level
    // and nested lazy-loaded modules.
    preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from "@angular/core";
import { MCBRootState, getActiveBoard } from "../reducers";
import { Store } from "@ngrx/store";
import { MetaConferenceBoard, Conference } from "../models/mcb.model";
import { Subject, timer } from "rxjs";
import { SetActiveBoard, MCBUpdate } from "../actions/mcb";
import { getUserProfile, getActiveConferences, getIsSidebarExpanded } from "app/reducers";
import { take, filter, takeUntil, switchMap } from "rxjs/operators";
import { MCBRepository } from "app/repositories/mcb.repository";
import { ActivatedRoute, Router } from "@angular/router";
import { StartConferenceComponent } from "app/shared/components/start-conference/start-conference.component";
import { MatDialog } from "@angular/material/dialog";
import { SetSidebarIsExpanded } from "app/actions/app";
import * as _moment from "moment";
import { default as _rollupMoment } from "moment";
import { ToastService } from "app/shared/services/toast.service";
const moment = _rollupMoment || _moment;

const TOTAL_PER_PAGE = 7;
const POLLING_INTERVAL = 10000;
@Component({
  selector: "vp-meta-conference-board-detail",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./meta-conference-board-detail.component.html"
})
export class MetaConferenceBoardDetailComponent implements OnInit, OnDestroy {
  domain = "";
  cols = 3;
  private isAlive$ = new Subject<boolean>();
  activeBoard: MetaConferenceBoard;
  conferences: Conference[] = [];
  allConferences: Conference[] = [];
  totalPage = 1;
  currentPage = 1;
  currentId: any;
  showList = false;
  totalLive = 0;
  isExpanded: boolean;
  liveConferenceIds: any[];
  fullscreenConference: any;
  owner: string;
  admins: any[];
  members: any[];
  constructor(
    private store: Store<MCBRootState>,
    private mcbRepo: MCBRepository,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private toaster: ToastService,
    private changeDetectionRef: ChangeDetectorRef) {
      this.calculateColumns();
  }

  ngOnInit(): void {
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(profile => {
      this.domain = profile.domain;
    });
    this.activatedRoute.paramMap.pipe(takeUntil(this.isAlive$)).subscribe(data => {
      this.currentId = data.get("id");
      this.startMCBPolling();
    });
    this.store.select(getActiveBoard).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res) {
        if (!this.activeBoard) {
          this.activeBoard = res;
          this.changeDetectionRef.markForCheck();
        } else {
          this.activeBoard = res;
        }
        this.allConferences = res.conferences || [];
        const _conferences = this.filterConferences(res ? res.conferences || [] : []);
        if (this.checkDifferent(_conferences)) {
          this.conferences = _conferences;
          this.changeDetectionRef.markForCheck();
        }
      }
    });
    this.store.select(getActiveConferences).pipe(takeUntil(this.isAlive$)).subscribe(conferences => {
      console.log("[getActiveConferences]", this.conferences);
      const conferenceIds = conferences.map(v => v.jid);
      const totalLive = this.conferences.filter(v => conferenceIds.includes(v.jid)).length;
      if (this.totalLive !== totalLive) {
        this.totalLive = totalLive;
        this.changeDetectionRef.markForCheck();
      }
    });

    this.store.select(getIsSidebarExpanded).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.isExpanded = v;
      this.calculateColumns();
    });
  }

  filterConferences(conferences) {
    this.store.select(getActiveConferences).pipe(take(1)).subscribe(res => {
      const conferenceIds = res.map(v => v.jid);
      conferences = conferences.filter(v => conferenceIds.includes(v.jid)
      || (v.start_time && moment(v.start_time).isSame(moment(), "day")));
      conferences.forEach(v => {
        console.log("filterConferences", v, v.start_time, moment(v.start_time).isSame(moment(), "day"));
      });
    });
    return conferences;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calculateColumns();
  }

  calculateColumns() {
    let isExpanded = false;
    this.store.select(getIsSidebarExpanded).pipe(take(1)).subscribe(v => isExpanded = v);
    const width = isExpanded ? 300 : 0;
    let cols = Math.floor((document.body.clientWidth - width) / 450);
    if (cols > 4) {
      cols = 4;
    }
    this.cols = cols;
    this.changeDetectionRef.markForCheck();
  }

  startMCBPolling() {
    console.log("[startMCBPolling]");
    timer(0, POLLING_INTERVAL).pipe(takeUntil(this.isAlive$), switchMap(v => this.mcbRepo.getMCB(this.currentId))).subscribe(mcb => {
      if (!!mcb) {
        this.store.select(getActiveBoard).pipe(take(1)).subscribe(res => {
          if (!res) {
            this.store.dispatch(new SetActiveBoard(mcb));
          }
        });
        this.allConferences = mcb.conferences || [];
        this.updateConference(mcb.conferences || []);
        if (this.activeBoard.name !== mcb.name || this.activeBoard.description !== mcb.description) {
          this.store.dispatch(new MCBUpdate({id: mcb.id, changes: mcb}));
        }
      } else {
        this.returnList();
      }
    });
  }

  updateConference(conferences) {
    conferences = this.filterConferences(conferences);
    if (this.checkDifferent(conferences)) {
      console.log("[updateConference]", conferences);
      this.conferences = conferences;
      this.store.dispatch(new MCBUpdate({id: this.activeBoard.id, changes: {conferences: conferences}}));
      this.changeDetectionRef.markForCheck();
    }
  }

  checkDifferent(conferences) {
    let flag = false;
    if (this.conferences.length === conferences.length) {
      for (let i = 0; i < this.conferences.length; i++) {
        if (this.conferences[i].jid !== conferences[i].jid
          || this.conferences[i].updated_at !== conferences[i].updated_at
          || this.conferences[i].status !== conferences[i].status
          || this.conferences[i].name !== conferences[i].name
          || this.conferences[i].start_time !== conferences[i].start_time
          || this.conferences[i].stream_url !== conferences[i].stream_url) {
            flag = true;
            break;
        }
      }
    } else {
      flag = true;
    }
    console.log("[checkDifferent]", flag);
    return flag;
  }

  returnList() {
    if (this.fullscreenConference) {
      this.fullscreenConference = null;
      this.changeDetectionRef.markForCheck();
    } else {
      this.router.navigateByUrl("/");
    }
  }

  nextPage() {
    if (this.currentPage + 1 <= this.totalPage) {
      this.currentPage = this.currentPage + 1;
    }
    this.processConferences();
  }

  prevPage() {
    if (this.currentPage - 1 > 0) {
      this.currentPage = this.currentPage - 1;
    }
    this.processConferences();
  }

  processConferences() {
    // const filteredConference = this.allConferences;
    // this.totalPage = Math.ceil(filteredConference.length / TOTAL_PER_PAGE) || 1;
    // this.conferences = filteredConference.slice((this.currentPage - 1) * TOTAL_PER_PAGE, TOTAL_PER_PAGE * this.currentPage);
    // this.cols = this.conferences.length + 1;
    // if (this.cols > 4) {
    //   this.cols = 4;
    // }
    // this.conferences = this.allConferences;
    // this.changeDetectionRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.store.dispatch(new SetSidebarIsExpanded(false));
  }

  toggleConferencesList() {
    this.showList = !this.showList;
    this.changeDetectionRef.markForCheck();
  }

  startConference() {
    const options: any = {
      width: "540px",
      height: "680px",
    };
    this.matDialog.open(StartConferenceComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "start_conference"
      },
      autoFocus: true
    }, options)).afterClosed().subscribe(data => {
      this.mcbRepo.getMCB(this.currentId).subscribe(mcb => {
        if (!!mcb) {
          this.updateConference(mcb.conferences || []);
        }
      });
    });
  }

  getMembers(target) {
    this.owner = "";
    this.admins = [];
    this.members = [];
    return this.mcbRepo.getGroupMembers(target);
  }

  conferenceInfo(isEdit?: boolean) {
    const options: any = {
      width: "540px",
      height: "680px",
    };
    this.getMembers(this.fullscreenConference.jid).subscribe((v: any) => {
      const result = v[0];
      if (result) {
        this.owner = result.owner;
        this.admins = result.admins;
        this.members = result.members;
      }
      this.matDialog.open(StartConferenceComponent, Object.assign({
        backdropClass: "mcb-form-backdrop",
        panelClass: "mcb-form-panel",
        disableClose: true,
        data: {
          action: isEdit ? "edit_conference" : "preview_conference",
          members: this.members || [],
          admins: this.admins || [],
          conference: this.fullscreenConference
        },
        autoFocus: true
      }, options)).afterClosed().subscribe(data => {
        if (!!data) {
          this.fullscreenConference = data;
          this.changeDetectionRef.markForCheck();
        }
      });
    }, err => {
      this.toaster.showSnackbar("CANNOT_GET_INFO");
    });
  }

  editConferenceInfo() {
    this.conferenceInfo(true);
  }

  setFullScreenConference(conference) {
    this.fullscreenConference = conference;
    this.changeDetectionRef.markForCheck();
  }

}

import { Environment } from "./environment.interface";
import { theme } from "./default.environment.theme";

export const environment: Environment = {
  production: false,
  disabledExternalShare: false,
  pollingTimeInterval: 5,
  xmppTimeout: 90,
  meetingWaitingTime: 6000,
  callRequestTimeout: 2000,
  ...theme,
};

import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { Conference, MetaConferenceBoard } from "../models/mcb.model";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { MCBRootState, getActiveBoardConversations, getActiveBoard } from "../reducers";
import { take, filter, takeUntil } from "rxjs/operators";
import { getIsSidebarExpanded, getActiveConferences, getFederatedApps, getJitsiURL, getBare } from "app/reducers";
import { Subject } from "rxjs";
import { EditConferenceDialogComponent } from "app/shared/components/edit-conference-dialog/edit-conference-dialog.component";
import { CommonUtil } from "app/utils/common.util";
import { ToastService } from "app/shared/services/toast.service";
import { MCBRepository } from "app/repositories/mcb.repository";
import { MatDialog } from "@angular/material/dialog";
import { ChangeMCBListDialogComponent } from "app/shared/components/change-mcb-list/change-mcb-list.component";
import * as _ from "lodash";
import { StartConferenceComponent } from "app/shared/components/start-conference/start-conference.component";
import { GroupChatsService } from "app/shared/services/groupchat.service";
import { MCBUpdate } from "../actions/mcb";
import { ConfirmationDialogComponent } from "app/shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "vp-conference-tile-filter",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./conference-tile-filter.component.html"
})
export class ConferenceTileFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() conferences: Conference[] = [];
  activeFilter = "planned";
  totalScheduled = [];
  totalArchived = [];
  totalEnded = [];
  isExpanded: boolean;
  totalRunning = [];
  runningConferences = [];
  private isAlive$ = new Subject<boolean>();
  talkUrl: any;
  jid: string;
  jitsiURL: string;
  federatedApps: any[];
  activeBoard: MetaConferenceBoard;
  owner: any;
  admins: any;
  members: any;
  constructor(
    private store: Store<MCBRootState>,
    private broadcaster: Broadcaster,
    private translate: TranslateService,
    private groupChatsService: GroupChatsService,
    private matDialog: MatDialog,
    private toaster: ToastService,
    private mcbRepository: MCBRepository,
    private changeDetectionRef: ChangeDetectorRef) {
    this.store.select(getIsSidebarExpanded).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.isExpanded = v;
      this.changeDetectionRef.markForCheck();
    });
    this.store.select(getActiveConferences).pipe(takeUntil(this.isAlive$)).subscribe(conferences => {
      if (!_.isEqual(this.runningConferences, conferences)) {
        this.runningConferences = conferences;
        this.changeDetectionRef.markForCheck();
      }
    });

    this.store.select(getFederatedApps).pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.federatedApps = apps;
      const talkApp = apps.find(app => app.name === "vnctalk");
      if (talkApp) {
        this.talkUrl = talkApp.url.endsWith("/") ? talkApp.url : talkApp.url + "/";
      }
      console.log(apps);
    });
    this.store.select(getBare).pipe(takeUntil(this.isAlive$)).subscribe(jid => {
      this.jid = jid;
    });
    this.store.select(getJitsiURL).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.jitsiURL = v;
    });
    this.store.select(getActiveBoard).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.activeBoard = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterConferences();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  filterConferences() {
    const allRunningJids = this.runningConferences ? this.runningConferences.map(v2 => v2.jid) : [];
    const totalRunning = this.conferences.filter(v => allRunningJids.includes(v.jid) );
    const totalArchived = this.conferences.filter(v => v.status === "archived");
    const runningJids = this.totalRunning.map(v2 => v2.jid);
    const totalScheduled = this.conferences.filter(v => !_.find(totalArchived, {jid: v.jid})
    &&  v.start_time && (new Date(v.start_time)).getTime() > new Date().getTime()
    && !runningJids.includes(v.jid));
    const scheduledJids = this.totalScheduled.map(v2 => v2.jid);
    const totalArchivedJids = this.totalArchived.map(v2 => v2.jid);
    const newList = [...scheduledJids, ...runningJids, ...totalArchivedJids];
    const totalEnded = this.conferences.filter(v => !newList.includes(v.jid)
    && (v.status === "closed" || !v.start_time || (v.start_time
      && (new Date(v.start_time)).getTime() < new Date().getTime())));
    if (!_.isEqual(this.totalRunning, totalRunning)
    || !_.isEqual(this.totalScheduled, totalScheduled)
    || !_.isEqual(this.totalArchived, totalArchived)) {
      this.totalRunning = totalRunning;
      this.totalScheduled = totalScheduled;
      this.totalArchived = totalArchived;
      this.totalEnded = totalEnded;
      this.changeDetectionRef.markForCheck();
    }
  }

  setActiveFilter(name) {
    this.activeFilter = name;
    this.filterConferences();
  }

  get filteredConferences() {
    let conferences = [];
    switch (this.activeFilter) {
      case "running": conferences = this.totalRunning; break;
      case "planned": conferences = this.totalScheduled; break;
      case "archived": conferences = this.totalArchived; break;
      case "closed": conferences = this.totalEnded; break;
    }
    return conferences;
  }

  openConference(conference) {
    this.getRoomId(conference).subscribe(roomId => {
      this.openVC(conference, roomId);
    });
  }

  getRoomId(conference) {
    const subject = new Subject();
    this.mcbRepository.getJitsiRoom(conference.jid).subscribe(res => {
      const roomId = res.value;
      subject.next(roomId);
    }, (err) => {
      const jitsiRoomId = CommonUtil.randomId(10);
      const jitsiUrl = this.jitsiURL;
      this.mcbRepository.createJitsiRoom(conference.jid, jitsiRoomId, jitsiUrl).subscribe(res => {
        subject.next(jitsiRoomId);
      });
    });
    return subject.asObservable();
  }

  openVC(conference, roomId) {
    const url = this.generateLink(conference, roomId);
    console.log("[openVC]", url);
    window.open(url, "_blank");
  }

  generateLink(conference, roomId, isShared?: boolean) {
    let username = this.jid;
    if (isShared) {
      username = "Anonymous";
    }
    const jwt = CommonUtil.signJWT(username, roomId, conference.jid, this.activeBoard.id);
    let url = `${this.talkUrl}conference/mcb/${jwt}`;
    if (isShared) {
      url = `${this.talkUrl}vncmeet/join/${jwt}`;
    }
    return url;
  }

  changeMCBList(conference) {
    this.mcbRepository.getGroupInfo(conference.jid).subscribe(v => {
      if (!!v) {
        const options: any = {
          width: "420px",
          minHeight: "310px",
        };
        this.matDialog.open(ChangeMCBListDialogComponent, Object.assign({
          backdropClass: "mcb-form-backdrop",
          panelClass: "mcb-form-panel",
          disableClose: true,
          data: {
            action: "edit_conference",
            conference: v
          },
          autoFocus: true
        }, options));
      }
    });
  }

  openParticipants() {
    this.toaster.show("UNDER_DEVELOPMENT");
  }

  getMembers(target) {
    this.owner = "";
    this.admins = [];
    this.members = [];
    return this.mcbRepository.getGroupMembers(target);
  }

  conferenceInfo(conference, isEdit?: boolean) {
    const options: any = {
      width: "540px",
      height: "680px",
    };
    this.getMembers(conference.jid).subscribe((v: any) => {
      const result = v[0];
      if (result) {
        this.owner = result.owner;
        this.admins = result.admins;
        this.members = result.members;
      }
      this.matDialog.open(StartConferenceComponent, Object.assign({
        backdropClass: "mcb-form-backdrop",
        panelClass: "mcb-form-panel",
        disableClose: true,
        data: {
          action: isEdit ? "edit_conference" : "preview_conference",
          members: this.members || [],
          admins: this.admins || [],
          conference: conference
        },
        autoFocus: true
      }, options)).afterClosed().subscribe(data => {
        console.log("[conferenceInfo]", data);
        if (!!data) {
          this.updateConference(conference, data);
          this.changeDetectionRef.markForCheck();
        }
      });
    }, err => {
      this.toaster.showSnackbar("CANNOT_GET_INFO");
    });
  }

  editConferenceInfo(conference) {
    this.conferenceInfo(conference, true);
  }

  archiveConference(conference) {
    const options: any = {
      width: "460px",
      height: "160px",
    };
    this.matDialog.open(ConfirmationDialogComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        actionKey: "ARCHIVED",
        confirmationKey: "ARCHIVE_VIDEO_CONFERENCE_MSG",
        headingKey: "ARCHIVE_VIDEO_CONFERENCE",
        action: "archived",
        noBorder: true
      },
      autoFocus: false
    }, options)).afterClosed().pipe(take(1)).subscribe(data => {
      if (data && data.action) {
        this.groupChatsService.updateGroupInfo(conference.jid, {status: "archived"})
        .subscribe((info: any) => {
          console.log("[updateGroupInfo]", info);
          if (info.group_chat) {
            this.toaster.show("ARCHIVED");
            this.updateConference(conference, info.group_chat);
            this.changeDetectionRef.markForCheck();
          } else if (info.error) {
            this.toaster.show(info.error);
          }
        }, err => {
          this.toaster.show("Error for server");
        });
      }
    });

  }

  unarchiveConference(conference) {
    this.groupChatsService.updateGroupInfo(conference.jid, {status: "active"})
    .subscribe((info: any) => {
      this.toaster.show("UNARCHIVED");
      this.updateConference(conference, info.group_chat);
    });
  }

  updateConference(oldConference, newConference) {
    this.conferences = this.conferences.filter(v => v.jid !== oldConference.jid);
    this.conferences.push(newConference);
    this.filterConferences();
  }

  deleteConference(conference) {
    this.groupChatsService.changeGroupMCBs(conference.jid, [])
    .subscribe((info: any) => {
      this.toaster.show("DELETED");
      this.conferences = this.conferences.filter(v => v.jid !== conference.jid);
      this.filterConferences();
      this.store.dispatch(new MCBUpdate({id: this.activeBoard.id, changes: {conferences: this.conferences}}));
    });
  }

  conferenceStats() {
    this.toaster.show("UNDER_DEVELOPMENT");
  }

}

import { environment } from "../../environments/environment";
import * as moment from "moment";
import * as html from "html-escaper";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { InjectionToken } from "@angular/core";
import { Observable, Observer, Subject, of } from "rxjs";
import { Md5 } from "ts-md5/dist/md5";
import * as entities from "entities";
import * as jwt from "jsonwebtoken";
import { ConstantsUtil } from "./constants.util";
import { take } from "rxjs/operators";

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
export const TEST_VALUE = new InjectionToken("test_value");

const NON_PRINTABLE_UNICODE_CHARACTERS = /[\x1B]/g;
export class CommonUtil {
  static imagesExtensions = ["jpeg", "jpg", "gif", "png", "bmp", "tiff"];
  static audioExtensions = ["mp3", "wav", "wma", "ogg", "aac", "flac", "aiff", "amr"];
  static videoExtensions = ["mp4", "mov", "wmv", "webm", "wma", "flv", "mov", "mpg", "avi", "3gp", "mkv"];
  static supportedVideoExtensions = ["mp4", "webm", "mov"];
  static DOWNLOAD_SIZE_LIMIT = 1000 * 1024 * 2;
  static USER_PALETTE = {
    A: "#FE5C25",
    B: "#51B255",
    C: "#FFC208",
    D: "#FE5C25",
    E: "#51B255",
    F: "#FFC208",
    G: "#FE5C25",
    H: "#51B255",
    I: "#FFC208",
    J: "#FE5C25",
    K: "#51B255",
    L: "#FFC208",
    M: "#FE5C25",
    N: "#51B255",
    O: "#FFC208",
    P: "#FE5C25",
    Q: "#51B255",
    R: "#FFC208",
    S: "#FE5C25",
    T: "#51B255",
    U: "#FFC208",
    V: "#FE5C25",
    W: "#51B255",
    X: "#FFC208",
    Y: "#FE5C25",
    Z: "#FFC208"
  };

  static decimalNcr = {
    ä: "&#228;",
    ö: "&#246;",
    ü: "&#252;",
    Ä: "&#196;",
    Ö: "&#214;",
    Ü: "&#220;",
    ß: "&#223;",
    ẞ: "&#7838;"
  };

  static langDict = {
    "de": {
      photo: "Foto",
      audio: "Sprachnachricht",
      video: "Video",
      link: "Link"
    },
    "en": {
      photo: "Photo",
      video: "Video",
      audio: "Voice Message",
      link: "Link"
    },
  };

  static getAvatarBackground(text: string): string {
    return this.USER_PALETTE[text.charAt(1)];
  }

  static getAvatarText(text: string): string {
    if (!text) {
      return "";
    }
    text = text.split("@")[0].replace(/\./g, " ").replace(/\W-_+/g, "");
    const str = text.split(/\s+/);
    if (str.length > 1) {
      return str[0].substr(0, 1).toUpperCase() + str[1].substr(0, 1).toUpperCase();
    }
    return text.substr(0, 2).toUpperCase();
  }

  static randomId(length: number = 7, containNumbers: boolean = true) {
    let randomId = "";
    let dictionary = "abcdefghijklmnopqrstuvwxyz";

    if (containNumbers) {
      dictionary += "0123456789";
    }

    const dictionaryLength = dictionary.length;

    for (let i = 0; i < length; i++) {
      randomId += dictionary.charAt(Math.floor(Math.random() * dictionaryLength));
    }

    return randomId;
  }

  static getRandomNumbers() {
    const array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return array.join("");
  }

  static escapeHTMLString(unescapedString: string): string {
    if (!unescapedString) {
      return "";
    }
    const escaped = html.escape(unescapedString);
    // console.log("[HTML][common.util][escapeHTMLString] escaped: ", escaped);
    return escaped;
  }

  static unEscapeHTMLString(str: string): string {
    // console.log("[unEscapeHTMLString]", typeof str);
    if (!str) {
      return "";
    }
    str = str.toString();
    const unescaped = html.unescape(str);
    // console.log("[HTML][common.util][unEscapeHTMLString] unescaped: ", unescaped);
    return unescaped;
  }

  static copyToClipboard(stringArray: string[]) {
    const str = stringArray.join("\n");
    if (environment.isCordova) {
      cordova.plugins.clipboard.copy(str);
    } else {
      // const event = (e: ClipboardEvent) => {
      //   e.clipboardData.setData("text/plain", str);
      //   e.preventDefault();
      //   document.removeEventListener("copy", event);
      // };
      // document.addEventListener("copy", event);
      // document.execCommand("copy");
      window.Clipboard = (function(window, document, navigator) {
        let textArea,
          copy;

        function isOS() {
          return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
          textArea = document.createElement("textArea");
          textArea.value = text;
          document.body.appendChild(textArea);
        }

        function selectText() {
          let range,
            selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        }

        function copyToClipboard() {
          document.execCommand("copy");
          document.body.removeChild(textArea);
        }

        copy = function(text) {
          createTextArea(text);
          selectText();
          copyToClipboard();
          textArea = null;
        };

        return {
          copy: copy
        };
      })(window, document, navigator);
      window.Clipboard.copy(str);
    }
  }

  static escapeCarriagesAndNewline(unescapedString: string): string {
    return unescapedString.replace(/\r?\n/g, " ");
  }


  static generateCachedBody(messageBody) {
    if (!messageBody) {
      console.warn("[CommonUtil][generateCachedBody] empty message body, ignore1");
      return messageBody;
    }
    messageBody = CommonUtil.processHTMLBody(messageBody);
    messageBody = CommonUtil.linkify(messageBody).replace(/\r?\n/g, "\\n").replace(/\\n/g, "<br />");
    messageBody = CommonUtil.processEmoji(messageBody.replace(/<\/p>/ig, " </p>").replace(/<p>:/ig, "<p> :"));
    // messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody);

    // save cached content
    return messageBody;
  }

  static processHTMLBody(htmlBody) {
    return htmlBody.replace("<body xmlns=\"http://www.w3.org/1999/xhtml\">", "")
      .replace("</body>", "")
      .replace(/&amp;nbsp;/ig, " ")
      .replace(/&lt;/ig, "<")
      .replace(/&gt;/ig, ">")
      .replace(/&quot;/ig, "\"")
      .replace(/&amp;lt;/ig, "&lt;")
      .replace(/&amp;gt;/ig, "&gt;")
      .replace(/&amp;amp;quote;/g, "&quote;")
      .replace(/&amp;amp;trade;/g, "&trade;")
      .replace(/&amp;amp;copy;/g, "&copy;")
      .replace(/&amp;amp;/ig, "&amp;");
  }

  static processSpecialChars(messageBody) {
    return messageBody
      .replace(/&amp;amp/g, "&amp;amp;amp")
      .replace(/&amp;lt/g, "&amp;amp;lt")
      .replace(/&amp;copy/g, "&amp;amp;copy")
      .replace(/&amp;trade/g, "&amp;amp;trade")
      .replace(/&amp;quote/g, "&amp;amp;quote")
      .replace(/&amp;gt/g, "&amp;amp;gt")
      .replace(/&amp;/g, "&");
  }

  static escapeEntities(text: string): string {
    return entities.escape(text);
  }

  static encodeHTML(text: string): string {
    return entities.encodeHTML(text);
  }

  static decodeHTML(text: string): string {
    return entities.decodeHTML(text);
  }

  static processEmoji(messageBody) {
    return wdtEmojiBundle.render(messageBody);
  }

  static beautifyName(text: string): string {
    return text.split(".").map(v => v.replace(/^\w/, c => c.toUpperCase())).join(" ");
  }

  static linkify(inputText: string): string {
    if (!inputText) {
      return "";
    }

    const results = [];
    for (let text of inputText.split(" ")) {
      if (!text.trim().startsWith("@") && ((text.indexOf("http://") !== -1 || text.indexOf("https://") !== -1))) {
        if (text.includes(`href="`)) {
          text = text + ` class="open-new-window"`;
        } else {
          text = this.linkifyText2Html(text);
        }
      }
      results.push(text);
    }
    return results.join(" ");
  }

  static linkifyText2Html(inputText: string) {
    // console.log("[CommonUtil][linkifyText2Html]", inputText);

    const isConfJoinLink = this.isOnIOS() && inputText.includes(ConstantsUtil.CONFERENCE_LINK_PART);
    // const isConfJoinLink = inputText.includes(ConstantsUtil.CONFERENCE_LINK_PART);

    const link = linkifyHtml(inputText, {
      defaultProtocol: "http",
      className: "open-new-window",
      target: {
        url: isConfJoinLink ? "_self" : "_blank",
        email: null
      },
      attributes: (href, type) => {
        if (type === "url") {
          const attrs = { rel: "noopener" };
          if (isConfJoinLink) {
            // https://stackoverflow.com/a/20548330/574475
            attrs["onclick"] = `const evv = new CustomEvent('${ConstantsUtil.CONFERENCE_LINK_CLICK_EVENT}', { 'detail': '${href}' }); document.dispatchEvent(evv);`;
          }
          return attrs;
        }
        return {};
      },
      formatHref: (href, type) => {
        if (type !== "email" && !isConfJoinLink) {
          return href;
        }
        return "javascript:void(0)";
      },
      tagName: (href, type) => {
        if (type !== "email") {
          return "a";
        }
        return "span";
      }
    });

    // console.log("[linkify]", inputText, link)

    return link;
  }


  ////

  static replaceName(type, content, query, name, isHighlighted?: boolean, keyword?: string): string {
    if (type === "plain") {
      content = content.replace(query, `@${name}`);
    } else {
      if (keyword) {
        name = CommonUtil.highlightSearch(name, keyword);
      }
      content = content.replace(query, `<span class="mentioned-user">@${name}</span>`);

      if (isHighlighted) {
        this.highlightUser(content);
      }
    }
    return content;
  }

  static highlightUser(content) {
    return content.replace(content, `<span class="mentioned-user">`, `<span class="mentioned-user highlighted">`);
  }

  static highlightSearch(text, keyword) {
    if (!keyword) {
      return text;
    }
    text = text.replace(/&#34;/g, "\"");
    if (keyword.includes("ä" || "ö" || "ü" || "ß" || "Ä" || "Ö" || "Ü" || "ẞ")) {
      keyword = this.convertToNcr(keyword);
    }
    let newText = text;
    const query = new RegExp(this.toUnicode(keyword), "gim");
    if (text.indexOf("href=") !== -1) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/html");
      for (const a of Array.from(doc.querySelectorAll("a"))) {
        let innerText = a.innerText;
        innerText = innerText.replace(/(<span>|<\/span>)/igm, "").replace(query, "<span class=\"highlight\">$&</span>");
        a.innerHTML = innerText;
      }
      newText = doc.querySelector("body").innerHTML;
    } else {
      const q = new RegExp(this.toUnicode(keyword), "gim");
      newText = newText.replace(q, "<span class=\"highlight\">$&</span>");
    }
    return newText;
  }

  static toUnicode(searchKeyword) {
    let unicodeString = "";
    for (let i = 0; i < searchKeyword.length; i++) {
      let theUnicode = searchKeyword.charCodeAt(i).toString(16).toUpperCase();
      while (theUnicode.length < 4) {
        theUnicode = "0" + theUnicode;
      }
      theUnicode = "\\u" + theUnicode;
      unicodeString += theUnicode;
    }
    return unicodeString;
  }

  static convertToNcr(searchKeyword) {
    let ncrKeyword = "";
    let singleChar;
    for (let i = 0; i < searchKeyword.length; i++) {
      singleChar = searchKeyword.charAt(i);
      if (this.decimalNcr[singleChar]) {
        ncrKeyword += this.decimalNcr[singleChar];
      }
      else {
        ncrKeyword += singleChar;
      }
    }
    return ncrKeyword;
  }


  ////


  static validateEmail(email) {
    // RFC822 version
    const sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
    const sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
    const sAtom = "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
    const sQuotedPair = "\\x5c[\\x00-\\x7f]";
    const sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
    const sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
    const sDomain_ref = sAtom;
    const sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
    const sWord = "(" + sAtom + "|" + sQuotedString + ")";
    const sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
    const sLocalPart = sWord + "(\\x2e" + sWord + ")*";
    const sAddrSpec = sLocalPart + "\\x40" + sDomain;
    const sValidEmail = "^" + sAddrSpec + "$";

    const reValidEmail = new RegExp(sValidEmail);

    return reValidEmail.test(email);
  }

  static logger(data: any) {
    console.log("Prashant Logger", data);
  }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isMobileSize() {
    return window.outerWidth < 768;
  }

  static isOnIOSMobile() {
    return /iPhone|iPad|iPod/i
      .test(navigator.userAgent);
  }

  static isOnIpad() {
    return /iPad/i
      .test(navigator.userAgent);
  }

  static isOnChrome() {
    return /chrome/.test(navigator.userAgent.toLowerCase()) || environment.isElectron;
  }

  static isOnSafari() {
    return /safari/.test(navigator.userAgent.toLowerCase()) && !CommonUtil.isOnChrome();
  }

  static isOnSafariMobile() {
    return this.isOnSafari() && this.isOnIOSMobile();
  }

  static isOnFirefox() {
    return /firefox/.test(navigator.userAgent.toLowerCase());
  }

  static isOnIOS() {
    return typeof device !== "undefined" && !!device.platform && device.platform.toUpperCase() === "IOS";
  }

  static isOnIOSXFamily() {
    // https://www.theiphonewiki.com/wiki/Models#iPhone
    const isXFamily = ["iPhone10,3", "iPhone10,6", "iPhone11,8", "iPhone11,2", "iPhone11,6"].includes(device.model);
    console.log("[CommonUtil][isOnIOSXFamily]", device.model, isXFamily);
    return CommonUtil.isOnIOS() && isXFamily;
  }

  static isOnAndroid() {
    return typeof device !== "undefined" && !!device.platform && device.platform.toUpperCase() === "ANDROID";
  }

  static isOnNativeMobileDevice() {
    return CommonUtil.isOnIOS() || CommonUtil.isOnAndroid();
  }

  static isSQLSupported() {
    // TODO: temporary disabled SQlite for Electron due to some perf issues.
    //
    // return this.isOnNativeMobileDevice() || environment.isElectron;
    return this.isOnNativeMobileDevice();
  }

  static isInsideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static getBaseUrl() {
    if (environment.isCordova) {
      return window.location.href.split("/www")[0] + "/www";
    } else if (environment.isElectron) {
      return window.location.href.split("/talk")[0].replace(/index.html/gi, "");
    } else {
      return "";
    }
  }

  static getFullUrl(url: string) {
    return CommonUtil.getBaseUrl() + url;
  }

  static trimString(subject: string, length: number, suffix: string = "...") {
    if (subject.length <= length) {
      return subject;
    }

    const suffixLength = suffix.length;
    const trimLength = length - suffixLength;

    return subject.slice(0, trimLength - 1) + suffix;
  }

  static getMediaType(body: string): string {
    if (!body) {
      return MediaType.ATTACHMENTS;
    }
    if ((body.lastIndexOf("aac") === body.length - 3) ||
      (body.lastIndexOf("amr") === body.length - 3) ||
      (body.lastIndexOf("aiff") === body.length - 4) ||
      (body.lastIndexOf("flac") === body.length - 4) ||
      (body.lastIndexOf("mp3") === body.length - 3) ||
      (body.lastIndexOf("oog") === body.length - 3) ||
      (body.lastIndexOf("wma") === body.length - 3) ||
      (body.lastIndexOf("wav") === body.length - 3)) {
      return MediaType.VOICE_MESSAGE;
    }

    if ((body.lastIndexOf("jpeg") === body.length - 4) ||
      (body.lastIndexOf("jpg") === body.length - 3) ||
      (body.lastIndexOf("gif") === body.length - 3) ||
      (body.lastIndexOf("png") === body.length - 3) ||
      (body.lastIndexOf("bmp") === body.length - 3) ||
      (body.lastIndexOf("svg") === body.length - 3) ||
      (body.lastIndexOf("tiff") === body.length - 4) ||
      (body.lastIndexOf("ico") === body.length - 3) ||
      (body.lastIndexOf("odi") === body.length - 3)) {
      return MediaType.IMAGE;
    }

    if ((body.lastIndexOf("3gp") === body.length - 3) ||
      (body.lastIndexOf("avi") === body.length - 3) ||
      (body.lastIndexOf("flv") === body.length - 3) ||
      (body.lastIndexOf("m4v") === body.length - 3) ||
      (body.lastIndexOf("mkv") === body.length - 3) ||
      (body.lastIndexOf("mov") === body.length - 3) ||
      (body.lastIndexOf("mp4") === body.length - 3) ||
      (body.lastIndexOf("mpeg") === body.length - 4) ||
      (body.lastIndexOf("mpg") === body.length - 3) ||
      (body.lastIndexOf("mts") === body.length - 3) ||
      (body.lastIndexOf("rm") === body.length - 2) ||
      (body.lastIndexOf("vob") === body.length - 3) ||
      (body.lastIndexOf("wmv") === body.length - 3)) {
      return MediaType.VIDEOS;
    }

    if (body.lastIndexOf("pdf") === body.length - 3) {
      return MediaType.PDF;
    }

    if ((body.lastIndexOf("txt") === body.length - 3) ||
      (body.lastIndexOf("rtf") === body.length - 3) ||
      (body.lastIndexOf("dat") === body.length - 3)) {
      return MediaType.TEXT;
    }

    if ((body.lastIndexOf("doc") === body.length - 3) ||
      (body.lastIndexOf("docx") === body.length - 4) ||
      (body.lastIndexOf("docm") === body.length - 4) ||
      (body.lastIndexOf("odt") === body.length - 3)) {
      return MediaType.DOC;
    }

    if ((body.lastIndexOf("xls") === body.length - 3) ||
      (body.lastIndexOf("xlr") === body.length - 3) ||
      (body.lastIndexOf("xlsx") === body.length - 4) ||
      (body.lastIndexOf("xlsm") === body.length - 3) ||
      (body.lastIndexOf("ods") === body.length - 3) ||
      (body.lastIndexOf("csv") === body.length - 3) ||
      (body.lastIndexOf("tsv") === body.length - 3)) {
      return MediaType.EXCEL;
    }

    if ((body.lastIndexOf("ppt") === body.length - 3) ||
      (body.lastIndexOf("pptx") === body.length - 4) ||
      (body.lastIndexOf("pps") === body.length - 3) ||
      (body.lastIndexOf("odp") === body.length - 3)) {
      return MediaType.PPT;
    }

    if ((body.lastIndexOf("rar") === body.length - 3) ||
      (body.lastIndexOf("tar") === body.length - 3)) {
      return MediaType.RAR;
    }

    if ((body.lastIndexOf("zip") === body.length - 3) ||
      (body.lastIndexOf("7z") === body.length - 2)) {
      return MediaType.ZIP;
    }

    return MediaType.ATTACHMENTS;
  }

  static isAudio(fileType: string): boolean {
    if (!fileType) {
      return false;
    }
    return this.audioExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isSupportedVideo(fileType: string): boolean {
    return this.supportedVideoExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isImage(fileType: string): boolean {
    return this.imagesExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isVideo(fileType: string): boolean {
    return this.videoExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isValidEmail(email: string): boolean {
    return EMAIL_REGEXP.test(email);
  }

  static getMediaUrl(type: string): string {
    let url = "";

    if (type === MediaType.VOICE_MESSAGE) {
      url = "/assets/media-icons/file-document-mic.svg";
    }
    if (type === MediaType.VIDEOS) {
      url = "/assets/media-icons/file-document-video.svg";
    }
    if (type === MediaType.PDF) {
      url = "/assets/media-icons/file-pdf-box.svg";
    }
    if (type === MediaType.DOC) {
      url = "/assets/media-icons/icons8-microsoft-word.svg";
    }
    if (type === MediaType.RAR || type === MediaType.ZIP) {
      url = "/assets/media-icons/zip-box.svg";
    }
    if (type === MediaType.EXCEL) {
      url = "/assets/media-icons/icons8-microsoft-excel.svg";
    }
    if (type === MediaType.TEXT) {
      url = "/assets/media-icons/file-document-box.svg";
    }
    if (type === MediaType.PPT) {
      url = "/assets/media-icons/icons8-microsoft-powerpoint.svg";
    }
    if (type === MediaType.ATTACHMENTS) {
      url = "/assets/media-icons/file-document-box.svg";
    }

    return this.getFullUrl(url);
  }

  static hideBackdrop() {
    // mdl-backdrop-overlay
    if (document.querySelector("mdl-backdrop-overlay") !== null) {
      (<HTMLElement> document.querySelector("mdl-backdrop-overlay")).style.display = "none";
    }
    if (CommonUtil.isOnMobileDevice() && document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement> document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  static showBackdrop() {
    // mdl-backdrop-overlay
    if (document.querySelector("mdl-backdrop-overlay") !== null) {
      (<HTMLElement> document.querySelector("mdl-backdrop-overlay")).style.display = "block";
    }
    if (CommonUtil.isOnMobileDevice() && document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement> document.querySelector(".dialog-backdrop")).style.display = "block";
    }
  }

  static isToday(momentDate) {
    const yesterday = moment().clone().startOf("day");
    return momentDate.isSame(yesterday, "d");
  }

  static isYesterday(momentDate) {
    const yesterday = moment().clone().subtract(1, "days").startOf("day");
    return momentDate.isSame(yesterday, "d");
  }

  static lastActivity(seconds: number): { key: string, value: number | string } {
    const lastTime = moment().subtract(seconds, "seconds");
    return this.timeAgo(lastTime);
  }

  static getLastTime(seconds: number, prefix: string): { key: string, value: number | string } {
    const lastTime = moment().subtract(seconds, "seconds");
    return this.timeJoinedAgo(lastTime, prefix);
  }

  static timeAgo(timestamp, DWMY_timeAgo = true) { // DWMY_timeAgo = [Days,Weeks,Months,Years] ago
    return CommonUtil.timeAgoPrefix(timestamp, DWMY_timeAgo, "LAST_SEEN");
  }

  static timeJoinedAgo(timestamp, prefix = "ONLINE") { // DWMY_timeAgo = [Days,Weeks,Months,Years] ago
    return CommonUtil.timeAgoPrefix(timestamp, true, prefix);
  }

  static timeAgoPrefix(timestamp, DWMY_timeAgo = true, prefix) { // DWMY_timeAgo = [Days,Weeks,Months,Years] ago
    const momentDate = moment(timestamp); // Getting date and time with unix timestamp
    const dateTime = {
      seconds: moment().diff(momentDate, "seconds"),
      minutes: moment().diff(momentDate, "minutes"),
      hours: moment().diff(momentDate, "hours"),
      days: moment().diff(momentDate, "days"),
      weeks: moment().diff(momentDate, "weeks"),
      months: moment().diff(momentDate, "months"),
      years: moment().diff(momentDate, "years"),
      today: this.isToday(momentDate),
      yesterday: this.isYesterday(momentDate),
      dayName: momentDate.format("dddd"),
      fullDateTime: momentDate.format("LLLL"),
      date: momentDate.format("LL"),
      time: momentDate.format("LT"),
      calendar: momentDate.calendar()
    };
    const datetime = dateTime.date + " " + dateTime.time;
    let outputTime: {
      key: string,
      value: number | string
    };


    if (dateTime.seconds > 0) {
      outputTime = { key: prefix + "_SECOND", value: dateTime.seconds };
    }
    if (dateTime.seconds > 1) {
      outputTime = { key: prefix + "_SECOND", value: dateTime.seconds };
    }

    if (dateTime.minutes === 1) {
      outputTime = { key: prefix + "_MINUTE", value: dateTime.minutes };
    }
    if (dateTime.minutes > 1) {
      outputTime = { key: prefix + "_MINUTES", value: dateTime.minutes };
    }

    if (dateTime.hours === 1) {
      outputTime = { key: prefix + "_HOUR", value: dateTime.hours };
    }
    if (dateTime.hours > 1) {
      outputTime = { key: prefix + "_HOURS", value: dateTime.hours };
    }

    if (dateTime.days === 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_DAY", value: dateTime.days };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }
    if (dateTime.days > 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_DAYS", value: dateTime.days };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }

    if (dateTime.weeks === 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_WEEK", value: dateTime.weeks };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }
    if (dateTime.weeks > 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_WEEKS", value: dateTime.weeks };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }

    if (dateTime.months === 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_MONTH", value: dateTime.months };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }
    if (dateTime.months > 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_MONTHS", value: dateTime.months };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }

    if (dateTime.years === 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_YEAR", value: dateTime.years };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }
    if (dateTime.years > 1) {
      if (DWMY_timeAgo) {
        outputTime = { key: prefix + "_YEARS", value: dateTime.years };
      } else {
        outputTime = { key: prefix + "_DEFAULT", value: datetime };
      }
    }

    if (dateTime.yesterday) {
      outputTime = { key: prefix + "_DAY", value: 1 };
    }

    return outputTime;
  }

  static renderEmoji(text: string): string {
    wdtEmojiBundle.emoji.replace_mode = "unified";
    wdtEmojiBundle.emoji.allow_native = true;
    const result = wdtEmojiBundle.emoji.replace_emoticons(text);
    return result;
  }

  static renderBodyWithEmoji(content: string, translateService: TranslateService): string {
    content = this.renderEmoji(content);
    if (content.trim().startsWith("http") || content.trim().startsWith("https")) {
      const link = content.trim().toLocaleLowerCase();
      content = this.renderEmoji(":link: ") + translateService.instant("LINK");
      const extension = link.split(".").pop();
      if (extension && this.imagesExtensions.indexOf(extension) !== -1) {
        content = this.renderEmoji(":camera: ") + translateService.instant("PHOTO");
      } else if (extension && this.audioExtensions.indexOf(extension) !== -1) {
        content = this.renderEmoji(":studio_microphone: ") + translateService.instant("AUDIO");
      } else if (extension && this.videoExtensions.indexOf(extension) !== -1) {
        content = this.renderEmoji(":film_frames: ") + translateService.instant("VIDEO");
      }
    }

    return content;
  }

  static getDefaultLang(): string {
    let localLang = localStorage.getItem("portalLanguage");
    if (localLang === null) {
      localLang = navigator.language.split("-")[0];
    }
    const lang = localLang.match(/en|de|fr/) ? localLang : "en";
    localStorage.setItem("portalLanguage", lang);
    return lang;
  }

  static isBroadcast(target: string): boolean {
    return target.startsWith("broadcast@") || target.startsWith("broadcast-");
  }

  static removeNonprintableUnicodeCharacters(originString: string): string {
    return originString.replace(NON_PRINTABLE_UNICODE_CHARACTERS, "");
  }

  static destroyQRScanCode(): void {
    if (environment.isCordova) {
      document.getElementById("hideCamera").setAttribute("style", "display: none");
      document.querySelector("body").classList.remove("show-camera");
      document.getElementsByTagName("vp-app")[0].removeAttribute("style");
      localStorage.removeItem("scanQRCode");
      QRScanner.destroy();
    }
  }

  static parseMentions(text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp("@([a-zA-Z0-9\_\.\-]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
      matches = matches.map((match) => {
        return match.slice(1);
      });
      return _.uniq(matches.filter(v => CommonUtil.isValidEmail(v)));
    } else {
      return [];
    }
  }

  static getMembers(text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp("@([a-zA-Z0-9\_\.]+@[a-zA-Z0-9\_\.\-]+)", "gim");
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
      matches = matches.map((match) => {
        return match.slice(1);
      });
      return _.uniq(matches);
    } else {
      return [];
    }
  }

  static getMemberNames(text: string): string[] {
    return text.match(/([@][a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+)/g);
  }

  static getIcon(appName: string): string {
    let icon = "";
    const name = appName.replace("vnc", "VNC");
    switch (appName.toLowerCase()) {
      case "vncmail": icon = CommonUtil.getFullUrl("/assets/icon/VNCmail.svg"); break;
      case "vnctask": icon = CommonUtil.getFullUrl("/assets/icon/VNCtask.svg"); break;
      case "vncmcb": icon = CommonUtil.getFullUrl("/assets/icon/VNCmcb.svg"); break;
      case "vnccontacts": icon = CommonUtil.getFullUrl("/assets/icon/VNCcontacts.svg"); break;
      case "vnctalk": icon = CommonUtil.getFullUrl("/assets/icon/VNCtalk.svg"); break;
      case "vnccalendar": icon = CommonUtil.getFullUrl("/assets/icon/VNCcalendar.svg"); break;
      case "vncproject": icon = CommonUtil.getFullUrl("/assets/icon/VNCproject.svg"); break;
      default: icon = CommonUtil.getFullUrl("/assets/icon/" + name + ".svg"); break;
    }
    return icon;
  }

  static getPackageName(appName: string): string {
    const packageName = `biz.vnc.${appName.toLowerCase()}`;
    console.log("getPackageName", appName, packageName);
    return packageName;
  }

  static getAppUrl(appName: string): string {
    const availableiOSApps = {
      "vncmail": "",
      "vnctask": "itms-apps://itunes.apple.com/app/id1423089930",
      "vnccontacts": ""
    };
    const availableAndroidApps = {
      "vncmail": "",
      "vnctask": "market://details?id=biz.vnc.vnctask",
      "vnccontacts": ""
    };
    if (!CommonUtil.isOnAndroid()) {
      return availableiOSApps[appName.toLowerCase()];
    }
    return availableAndroidApps[appName.toLowerCase()];
  }

  static getMediaDevices(): Observable<any> {
    console.log("[CommonUtils][getMediaDevices]");
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      return new Observable((observer: Observer<any>) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          console.log("[CommonUtils][getMediaDevices]", devices);
          observer.next(devices);
          observer.complete();
        });
      });
    } else if (!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) && window.MediaStreamTrack
      && window.MediaStreamTrack.getSources) {
      const enumerateDevices = window.MediaStreamTrack.getSources
        .bind(window.MediaStreamTrack);
      return new Observable((observer: Observer<any>) => {
        enumerateDevices((devices) => {
          console.log("[CommonUtils][getMediaDevices]", devices);
          observer.next(devices);
          observer.complete();
        });
      });
    }

    return of([]);
  }

  static removeMessageFromPending(mId: string): any {
    const pendingMessages = localStorage.getItem("pendingMessages");
    let msgs = [];
    if (pendingMessages) {
      msgs = JSON.parse(pendingMessages);
      msgs = msgs.filter(message => message.id !== mId);
    }
    localStorage.setItem("pendingMessages", JSON.stringify(msgs));
    return msgs;
  }

  static textNodesUnder(root) {
    let walk = document.createTreeWalker(root, NodeFilter.SHOW_TEXT, null), node;
    const text = [];
    while (node = walk.nextNode()) {
      text.push(node);
    }
    walk = null;
    return text;
  }

  static isGroupTarged(target) {
    return target.indexOf("@conference") !== -1;
  }

  static highlightWords(n, word) {
    if (!!n) {
      let after = document.createElement("span");
      for (let i; (i = n.nodeValue.indexOf(word, i)) > -1; n = after) {
        after = n.splitText(i + word.length);
        const highlighted = n.splitText(i);
        let span = document.createElement("span");
        span.className = "highlight";
        span.appendChild(highlighted);
        after.parentNode.insertBefore(span, after);
        after = null;
        span = null;
      }
    }
  }

  static highlightWord(text, word): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const nodes = CommonUtil.textNodesUnder(doc);
    nodes.forEach(node => {
      CommonUtil.highlightWords(node, word);
    });
    return doc.querySelector("body").innerHTML;
  }

  static getTargetForOneToOne(ownTarget: string, currentTarget: string): string {
    const participants: string[] = [ownTarget, currentTarget];
    participants.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      return -1;
    });
    const target = participants.join("#").toLowerCase();

    return target;
  }

  static md5(text: string) {
    const md5 = new Md5();
    return md5.appendStr(text).end().toString();
  }

  static getMobileDeviceLabel() {
    if ((typeof device !== "undefined") && ((device.platform.toUpperCase() === "ANDROID") || (device.platform.toUpperCase() === "IOS"))) {
      return device.manufacturer + " " + device.model;
    } else {
      return "";
    }
  }

  static HTMLToPlainText(str: string): string {
    if (str !== undefined && str !== null) {
      return str.replace(/<\/div>/ig, "\n").replace(/<\/p>/ig, "\n").replace(/<[^>]+>/ig, "");
    } else {
      return "";
    }
  }

  static html2text(val) {
    const tag = document.createElement("div");
    tag.innerHTML = val;
    return tag.innerText;
  }

  static getInnerText(el) {
    let sel, range, innerText = "";
    if (typeof document.selection !== "undefined" && typeof document.body.createTextRange !== "undefined") {
      range = document.body.createTextRange();
      range.moveToElementText(el);
      innerText = range.text;
    } else if (typeof window.getSelection !== "undefined" && typeof document.createRange !== "undefined") {
      sel = window.getSelection();
      sel.selectAllChildren(el);
      innerText = "" + sel;
      sel.removeAllRanges();
    }
    return innerText;
  }

  static addInvitedEmail(email: string) {
    let mailList = [];
    if (!!localStorage.getItem("invitedMail")) {
      mailList = JSON.parse(localStorage.getItem("invitedMail"));
    }
    if (mailList.indexOf(email) === -1) {
      mailList.push(email);
    }
    localStorage.setItem("invitedMail", JSON.stringify(mailList));
  }

  static removeInvitedEmail(email: string) {
    let mailList = [];
    if (!!localStorage.getItem("invitedMail")) {
      mailList = JSON.parse(localStorage.getItem("invitedMail"));
      mailList = mailList.filter(v => v !== email);
      localStorage.setItem("invitedMail", JSON.stringify(mailList));
    }
  }

  static isInvitedEmail(email: string) {
    let mailList = [];
    if (!!localStorage.getItem("invitedMail")) {
      mailList = JSON.parse(localStorage.getItem("invitedMail"));
    }
    return mailList.indexOf(email) !== -1;
  }

  static convertDisplayNameToEmail(target) {
    if (!target) {
      return null;
    }
    return target.replace("_at_", "@").replace(/_dot_/g, ".").toLowerCase();
  }

  static isVideoMeeting(target: string): boolean {
    return target && target.indexOf("_meeting@conference.") !== -1 && target.indexOf("__meeting@conference.") === -1;
  }

  static isTemporaryGroup(target: string): boolean {
    return target && target.indexOf("_temporary_group@conference.") !== -1;
  }

  static signJWT(email: string, roomId: string, target: string, mcbId: number) {
    const jwtObject = {
      email,
      roomId,
      target,
      mcbId
    };
    return jwt.sign(jwtObject, "none");
  }

  static signJWTExternal(email: string, roomId: string) {
    const jwtObject = {
      email,
      roomId
    };
    return jwt.sign( jwtObject, "none");
  }

  static verifyJWT(token: string): any {
    return jwt.verify(token, "none");
  }

  static getConnectionQuality(connectionStrength): string {
    if (connectionStrength === null || connectionStrength === undefined) {
      return CommonUtil.getDefaultConnectionQuality();
    }

    // On mobile application, we are not able to access bandwidthRange from config, so putting it here.
    const bandwidthRange = {
      good: { from: 80, to: 100 },   // 24 - 30
      average: { from: 50, to: 79 }, // 15 - 24
      poor: { from: 0, to: 49 }      // 0 - 15
    };

    if (bandwidthRange.good.from <= connectionStrength) {
      return ConstantsUtil.USER_BANDWIDTH_QUALITY.good;
    } else if (bandwidthRange.average.from <= connectionStrength) {
      return ConstantsUtil.USER_BANDWIDTH_QUALITY.average;
    } else {
      return ConstantsUtil.USER_BANDWIDTH_QUALITY.poor;
    }
  }

  static getDefaultConnectionQuality(): string {
    return ConstantsUtil.USER_BANDWIDTH_QUALITY.good;
  }

  static getAppSettings(documents: any) {
  }

  static getCallUrl(email: string, roomId: string): string {
    const jwt2 = CommonUtil.signJWTExternal(email, roomId);
    let serverURL = localStorage.getItem("talkURL");
    if (environment.isCordova || environment.isElectron) {
        serverURL = localStorage.getItem("serverURL");
    }
    const callUrl = `${serverURL}${ConstantsUtil.CONFERENCE_LINK_PART}${jwt2}`;
    return callUrl;
  }

  static uniqBy(arr, predicate){
    const cb = typeof predicate === "function" ? predicate : (o) => o[predicate];
    if (typeof arr === "undefined") {
      return [];
    }
    return [...arr.filter(v => !!v).reduce((map, item) => {
      const key = (item === null || item === undefined) ?
        item : cb(item);

      map.has(key) || map.set(key, item);

      return map;
    }, new Map()).values()];
  }

  static sortBy(key) {
    return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
  }
}


export enum MediaType {
  IMAGE = "image",
  VOICE_MESSAGE = "voice-messages",
  ATTACHMENTS = "attachments",
  VIDEOS = "videos",
  ALL = "all",
  PDF = "pdf",
  DOC = "doc",
  TEXT = "txt",
  EXCEL = "excel",
  PPT = "ppt",
  RAR = "rar",
  ZIP = "zip"
}

export enum MediaLongPressAction {
  VIEW = "view",
  SHARE = "share",
  DOWNLOAD = "download",
  DELETE = "delete"
}

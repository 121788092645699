export class ConstantsUtil {
  static CONFERENCE_LINK_PART = "/vncmeet/join/";
  static CONFERENCE_LINK_CLICK_EVENT = "vncmeet-join-link-click";

  static NO_SESSION_ID_ERROR = "VNC_ERROR_NO_SESSION_ID";
  static LAST_SYNC_STAMP = "last_sync_stamp";
  static LAST_SORT_ID_SYNC = "last_sort_id_sync";
  static LAST_SYNC_FAILED = "last_sync_failed";
  static LAST_SYNC_FAILED_BEFORE = "last_sync_failed_before";
  static LIMIT_MESSAGES = 1000;
  static RETRY_PLAY_STREAM = 10000;
  // PIYUSH_COMMENT these keys are used in local storage
  static KEY_FIREBASE_TOKEN = "firebaseToken";
  static KEY_SERVER_URL = "serverURL";
  static KEY_USERNAME = "username";

  static TITLE_GENERAL_GROUP = "General";

  static POST_MESSAGE = "VNC_PORTAL_POST_MESSAGE";
  static TFA_OTP_VERIFICATION = "TFA_OTP_VERIFICATION";
  static LOGIN_USERNAME_CLICKED = "VNC_PORTAL_LOGIN_INPUT_CLICKED";
  static SHOW_FORGOT_PASSWORD_FORM = "SHOW_FORGOT_PASSWORD_FORM";
  static SHOW_REGISTRATION_FORM = "SHOW_REGISTRATION_FORM";
  static SHOW_LOGIN_FORM = "SHOW_LOGIN_FORM";


  static ERROR_APP_OFFLINE = "ERROR_APP_OFFLINE";
  static ERROR_XMPP_NOT_CONNECTED = "ERROR_XMPP_NOT_CONNECTED";


  static ROUTE_CONVERSATION_SELECT_TARGET = "route_select_conversation_target";
  static CLOSE_SIDEBAR = "close Sidebar";
  static TOGGLE_SIDEBAR = "Toggle Sidebar";
  static HIDE_SEARCH_HEADER = "Hide Search Header";

  static HIDE_GLOBAL_SEARCH_SCREEN = "hideGlobalSearchScreen";
  static HIDE_PRIVATE_SEARCH_SCREEN = "hidePrivateSearchScreen";
  static HIDE_USER_MENU = "hideUserMenu";
  static FAVOURITE_LIST_NAME = "__favourite";

  static LOCAL_STORAGE_NOTIFICATION_MAP = "notification_mapping";
  static THEME = "theme";
  static FONT_SIZE = "font_size";

  static CONV_MAPPING_FILE_NAME = "notificationMapping.json";

  static REPLY_MESSAGE_LINE_BREAK = "\n-------------\n";

  static SCREEN_SHARE_STARTED_COMMAND_DATA = "started";
  static SCREEN_SHARE_STOPPED_COMMAND_DATA = "stopped";

  static USER_IS_ALREADY = "user is already";
  static ALREADY_BEEN_TAKEN = "already been taken";

  static CHAT_BUBBLE_COLORS = [
    "#f12923",
    "#e4004f",
    "#8a00a3",
    "#531aab",
    "#2f36a9",
    "#0095f5",
    "#00afcc",
    "#018675",
    "#3ca53b",
    "#79bc32",
    "#ff8600",
    "#ff3e0c",
    "#664337",
    "#4d6a79"
  ];

  /**
   * Maps the names of the commands expected by the API with the name of the
   * commands expected by jitsi-meet
   */
  static COMMANDS = {
    avatarUrl: "avatar-url",
    displayName: "display-name",
    email: "email",
    hangup: "video-hangup",
    submitFeedback: "submit-feedback",
    toggleAudio: "toggle-audio",
    toggleChat: "toggle-chat",
    toggleFilmStrip: "toggle-film-strip",
    toggleShareScreen: "toggle-share-screen",
    toggleVideo: "toggle-video",
    raiseMyHand: "raise-hand",
    wakeup: "wakeup",
  };

  static CONFERENCE_USER_ROLE = {
    moderator: "moderator",
    none: "none",
  };

  static CONFERENCE_MEDIA_STATUS = {
    mutedByModerator: "muted-by-moderator",
    muted: "muted",
    unmuted: "unmuted",
  };

  static CONFERENCE_MEDIA_TYPE = {
    audio: "audio",
    video: "video",
    screen: "screen",
    none: "none",
  };

  static CONFERENCE_ERRORS = {
    CONNECTION_ERROR: JitsiMeetJS.errors.conference.CONNECTION_ERROR,
    SETUP_FAILED: JitsiMeetJS.errors.conference.SETUP_FAILED,
    AUTHENTICATION_REQUIRED: JitsiMeetJS.errors.conference.AUTHENTICATION_REQUIRED,
    PASSWORD_REQUIRED: JitsiMeetJS.errors.conference.PASSWORD_REQUIRED,
    PASSWORD_NOT_SUPPORTED: JitsiMeetJS.errors.conference.PASSWORD_NOT_SUPPORTED,
    VIDEOBRIDGE_NOT_AVAILABLE: JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE,
    RESERVATION_ERROR: JitsiMeetJS.errors.conference.RESERVATION_ERROR,
    CONFERENCE_FAILED: JitsiMeetJS.errors.conference.CONFERENCE_FAILED,
    CONFERENCE_DESTROYED: JitsiMeetJS.errors.conference.CONFERENCE_DESTROYED,
    FOCUS_DISCONNECTED: JitsiMeetJS.errors.conference.FOCUS_DISCONNECTED,
    CONFERENCE_MAX_USERS: JitsiMeetJS.errors.conference.CONFERENCE_MAX_USERS,
    ICE_FAILED: JitsiMeetJS.errors.conference.ICE_FAILED,
  };

  static CONNECTION_ERRORS = {
    CONNECTION_DROPPED_ERROR: JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR,
    SERVER_ERROR: JitsiMeetJS.errors.connection.SERVER_ERROR,
    OTHER_ERROR: JitsiMeetJS.errors.connection.OTHER_ERROR,
  };

  static CONFERENCE_GUM_ERRORS = {
    CHROME_EXTENSION_INSTALLATION_ERROR: "gum.chrome_extension_installation_error",
    CHROME_EXTENSION_USER_CANCELED: "gum.chrome_extension_user_canceled",
    CONSTRAINT_FAILED: "gum.constraint_failed",
    FIREFOX_EXTENSION_NEEDED: "gum.firefox_extension_needed",
    NOT_FOUND: "gum.not_found",
    PERMISSION_DENIED: "gum.permission_denied",
    UNSUPPORTED_RESOLUTION: "gum.unsupported_resolution",
    GENERAL_MEDIA_ERROR: "gum.general"
  };

  static MEDIA_DEVICE_KIND = {
    videoinput: "videoinput",
    audioinput: "audioinput",
    audiooutput: "audiooutput"
  };

  static USER_BANDWIDTH_QUALITY = {
    good: "good",
    average: "average",
    poor: "poor",
    good_background: "#00e600",
    average_background: "#ff9c00",
    poor_background: "#e02b00",
  };

  static JitsiRecordingMode = {
    FILE : "file",
  };

  static JitsiRecordingStatus = {
    ON : "on",
    PENDING: "pending",
    OFF: "off",
    NOT_STARTED: "not-started",
    START_IN_PROGRESS: "start-in-progress",
  };

  static USER_JOINED_TIMEOUT = 5000;

  static DEFAULT_MEETING_NAME = "VNCmeet";
  static DEFAULT_STREAM_SERVER = "https://stream.videobridges.vnclagoon.com";
  static HIDE_AUTOCOMPLETE = "HIDE_AUTOCOMPLETE";
}

export enum BroadcastKeys {
  hideSearchComponent = "hideSearchComponent",
  closeSearchScreen = "closeSearchScreen",
  hideArchiveScreen = "hideArchiveScreen",
  hideGlobalSearchSingleScreenMob = "hideGlobalSearchSingleScreenMob",
  HIDE_CHAT_MEDIA_COMPONENT = "hide_chat_media_component",
  HIDE_CHAT_MEDIA_MENU = "hide_chat_media_menu",
  HIDE_EMAIL_POPUP = "hide_email_popup",
  CLOSE_ALL_DIALOG = "close_all_dialog",
  HIDE_PREVIEW_IMAGE = "hidePreviewImage",
  DEACTIVATE_PROFILE_CARD = "deactivate_profile_card",
  HIDE_INVITATION = "hide_invitation",
  CALL_REQUEST_TIMED_OUT = "call_request_timed_out",
  CALL_REQUEST_REJECTED = "call_request_rejected",
  REMOVED_FROM_ROOM = "REMOVED_FROM_ROOM",
  CALL_LAST_REMOTE_USER_LEFT = "CALL_LAST_REMOTE_USER_LEFT",
  CALL_USER_CANCELED_SHARE_SCREEN_CALL = "CALL_USER_CANCELED_SHARE_SCREEN_CALL",
  LOST_CONNECTION_WHILE_ACTIVE_CALL = "LOST_CONNECTION_WHILE_ACTIVE_CALL",
  CALL_USER_CANCELED_SHARE_SCREEN_SWITCH = "CALL_USER_CANCELED_SHARE_SCREEN_SWITCH",
  CALL_CONNECTION_FAILED = "CALL_CONNECTION_FAILED",
  CALL_REJOIN = "CALL_REJOIN",
  CALL_ON_USER_LEFT = "onUserLeft",
  CALL_ON_USER_JOINED = "onUserJoined",
  INITIATOR_LEFT = "initiator_left",
  CALL_SECOND_DEVICE_JOINED = "CALL_2ND_DEVICE_JOINED",
  ALL_PARTICIPANTS_LEFT = "all_participants_left",
  FOCUS_TEXT_INPUT = "focus_text_input",
  RUN_DETECT_CHANGES = "detect_changes",
  LONG_PRESS_END = "long_press_end",
  DETECT_CHANGES_IN_CHAT_WINDOW = "detect_changes_in_chat_widow",
}
